<template>
  <div>
    <div v-for="(cat, id) in comboCategories" :key="id" class="w-full">
      <div class="spark-new-input-group !px-0">
        <select
          v-model="cat.uid"
          class="spark-new-select-input !ring-0 hover:border-primary-500 focus:border-primary-500 mx-0 w-full"
          disabled
        >
          <option :value="null" />
          <option v-for="(item, itemid) in cat.items" :key="itemid" :value="itemid">
            {{ item }}
          </option>
        </select>
        <label class="spark-new-select-label crop" v-text="cat.category" />
      </div>
    </div>

    <div v-for="(textcat, id) in textCategories" :key="id" class="flex w-full">
      <div class="spark-new-input-group w-full !px-0">
        <input
          v-model="textcat.value"
          type="text"
          name="text_category"
          class="spark-new-input-clean !ring-0 hover:border-primary-500 focus:border-primary-500 border-gray-100 rounded-4 text-13 mx-0 w-full"
          disabled
        />
        <label class="spark-new-input-label crop" v-text="textcat.category" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderCategorySummary',

  props: {
    comboCategories: { type: Object, default: () => {} },
    textCategories: { type: Object, default: () => {} },
  },
};
</script>
