<template>
  <Field
    :id="uid"
    v-model="inputField"
    class="peer absolute left-0 top-0 h-0 w-0 appearance-none opacity-0"
    :disabled="disabled"
    :name="name"
    :type="type"
    :value="value"
    :unchecked-value="uncheckedValue"
  />
  <label :for="uid" v-bind="$attrs">
    <slot />
  </label>
</template>

<script>
import { Field } from 'vee-validate';
import { computed } from 'vue';

export default {
  name: 'SparkHiddenInput',

  components: {
    Field,
  },

  inheritAttrs: false,

  props: {
    name: { type: String, required: true },
    type: { type: String, required: true },
    modelValue: { type: [Boolean, null, Number, String, Array], required: true },
    value: { type: [Boolean, null, Number, String], default: false },
    uncheckedValue: { type: [Boolean, null, Number, String], required: true },
    disabled: { type: Boolean },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const inputField = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value),
    });

    const uid = computed(() => `input-${Math.random().toString(36).substring(2, 9)}`);

    return { inputField, uid };
  },
};
</script>
