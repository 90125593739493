import Settings from '../../views/Settings/Settings.vue';
import OrganizationSettings from '../../views/Settings/views/organization/OrganizationSettings.vue';
import PRPSettings from '../../views/Settings/views/prp/PRPSettings.vue';
import SupplierSettings from '../../views/Settings/views/supplier/SupplierSettings.vue';

const subNavigations = {
  settings: [
    {
      label: 'Organization Settings',
      value: 'organization-settings',
      to: { name: `organization-settings` },
    },
    {
      label: 'PRP Settings',
      value: 'prp-settings',
      to: { name: `prp-settings` },
    },
    {
      label: 'Supplier Settings',
      value: 'supplier-settings',
      to: { name: `supplier-settings` },
    },
  ],
};

const routes = [
  {
    path: '/settings',
    redirect: '/settings/organization-settings',
    component: Settings,
    meta: {
      title: 'Settings | 3D Spark',
      showSubNavigation: true,
      requireLogin: true,
      requireStaff: false,
      requireInternal: false,
      requiredRole: ['sparkmanager'],
    },
    children: [
      {
        path: 'organization-settings',
        name: 'organization-settings',
        component: OrganizationSettings,
        meta: {
          title: 'Organization Settings | 3D Spark',
          subNavigation: subNavigations.settings,
        },
      },
      {
        path: 'prp-settings',
        name: 'prp-settings',
        component: PRPSettings,
        meta: {
          title: 'PRP Settings | 3D Spark',
          subNavigation: subNavigations.settings,
        },
      },
      {
        path: 'supplier-settings',
        name: 'supplier-settings',
        component: SupplierSettings,
        meta: {
          title: 'Supplier Settings | 3D Spark',
          subNavigation: subNavigations.settings,
        },
      },
    ],
  },
];

export default routes;
