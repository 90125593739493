<template>
  <div class="container">
    <div class="crop title-row">
      <span :title="template.name" v-text="template.name" />
      <div class="flex-row">
        <img class="title-row-icon" src="@/assets/icons/comparison/template.svg" />
        <img v-if="template.owner_username" class="title-row-icon" src="@/assets/icons/user.svg" />
        <div v-if="template.owner_username == 'material_proposal'">
          <Badge title="Material Proposal" selected-style="darkblue" />
        </div>
      </div>
    </div>
    <div class="spark-new-input-group">
      <SparkInput
        v-model="templateProcessSynonyms"
        custom="!cursor-default border-none"
        name="process"
        readonly
        label="Process"
      />
    </div>
    <div class="spark-new-input-group">
      <SparkInput
        v-model="materialName"
        custom="!cursor-default border-none"
        name="material"
        readonly
        label="Material"
      />
    </div>
    <div class="spark-new-input-group">
      <SparkInput v-model="machineName" custom="!cursor-default border-none" name="machine" readonly label="Machine" />
    </div>
    <div class="flex-start margin-20">
      <div class="label">Suitability</div>
      <TrafficLightBox :value="score" />
    </div>
    <div class="flex-start">
      <div class="label">Average Cost/kg</div>
      <div class="value">{{ avgCostPerKg }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkInput from '../../../SparkComponents/SparkInput.vue';

import Badge from '@/components/Reusable/Badge.vue';
import TrafficLightBox from '@/components/Reusable/TrafficLightBox.vue';

export default {
  name: 'TemplateDetails',

  components: { TrafficLightBox, Badge, SparkInput },

  props: {
    template: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    ...mapState(['part', 'user']),

    score() {
      if (this.part.process_proposal != undefined) {
        return this.part.process_proposal[this.template.prc_uid].score;
      } else {
        return null;
      }
    },

    materialName() {
      return this.template.material_name ?? '';
    },

    machineName() {
      return this.template.machine ?? '';
    },

    templateProcessSynonyms() {
      return this.getProcessSynonym(this.template.prc_uid);
    },

    avgCostPerKg() {
      if (this.template.avg_cost_per_kg == null || this.template.avg_cost_per_kg == '') {
        return '-';
      } else {
        return this.$formatPrice(this.template.avg_cost_per_kg);
      }
    },

    processSynonyms() {
      return this.user.organization.process_synonyms;
    },
  },

  methods: {
    getProcessSynonym(uid) {
      return this.processSynonyms[uid].prc_acronym;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: var(--12px);
  margin: 4px 0px;
  color: var(--spark-lightgrey);
}

.value {
  font-size: var(--13px);
}

.container {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-start {
  align-self: flex-start;
}

.margin-20 {
  margin: 0 20px;
}
</style>
