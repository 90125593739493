<template>
  <div class="flex justify-end pr-20 pb-10 pt-20">
    <div v-for="button in buttonContent" :key="button">
      <SparkButton custom="min-w-80" class="ml-8" :variant="button.type" @click="$emit('button-clicked', button.value)">
        <div v-text="button.text" />
      </SparkButton>
    </div>
  </div>
</template>

<script>
import SparkButton from '../SparkComponents/SparkButton.vue';

export default {
  name: 'PopupButtons',

  components: { SparkButton },

  props: {
    buttonContent: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  emits: ['button-clicked'],
};
</script>

<style lang="scss" scoped>
.link {
  padding: 5px;
  border-radius: 5px;
  margin: 0px;
  margin-left: 2px;
  border: solid 1px #cbd2e5;
  box-shadow: 0px 1px 2px 0px #cbd2e5;
  i {
    margin-left: 10px;
    margin-right: 5px;
    transition: 0.2s;
  }
  &:hover {
    cursor: pointer;
    i {
      color: var(--accent-color);
      transform: translateX(5px);
    }
  }
}
</style>
