<template>
  <div v-if="hasGoals">
    <div style="display: flex; justify-content: space-between; margin: 5px; margin-bottom: 32px; align-items: center">
      <div class="spark-new-input-group">
        <select class="spark-new-select-input" @change="updateSelectedPeriod">
          <option v-for="(goal, key) in organizationGoalDashboardDetails" :key="key" :value="key">
            {{ goal.title }} {{ goal.name }}
          </option>
        </select>
        <label class="spark-new-select-label">Title</label>
      </div>
    </div>
    <div v-if="!loading" class="organization-goal__summary">
      <div v-if="selectedGoal.partsValidatedGoal" class="organization-goal">
        <div>References</div>
        <div>
          {{ $formatZeroDecimalPlaces(selectedGoal.partsValidatedAchieved) }} /
          {{ $formatZeroDecimalPlaces(selectedGoal.partsValidatedGoal) }}
        </div>
        <ProgressBar
          :background-color="selectedGoal.partsValidatedBackgroundColor"
          :track="{ text: 'Goal today', position: selectedGoal.goalPercentToday }"
          :value="selectedGoal.partsValidatedAchieved"
          :max="selectedGoal.partsValidatedGoal"
        >
          <template #progress="{ percentage }"> {{ percentage }}% </template>
        </ProgressBar>
      </div>
      <div v-if="selectedGoal.costGoal" class="organization-goal">
        <div>Cost Savings</div>
        <div>{{ formattedCostSavings }} / {{ $formatZeroDecimalPlaces(selectedGoal.costGoal) }} €</div>
        <ProgressBar
          :background-color="selectedGoal.costBackgroundColor"
          :track="{ text: 'Goal today', position: selectedGoal.goalPercentToday }"
          :value="selectedGoal.costAchieved"
          :max="selectedGoal.costGoal"
        >
          <template #progress="{ percentage }"> {{ percentage }}% </template>
        </ProgressBar>
      </div>
      <div v-if="selectedGoal.leadtimeGoal" class="organization-goal">
        <div>Leadtime Savings</div>
        <div>
          {{ $formatZeroDecimalPlaces(selectedGoal.leadtimeAchieved) }} /
          {{ $formatZeroDecimalPlaces(selectedGoal.leadtimeGoal) }} d
        </div>
        <ProgressBar
          :background-color="selectedGoal.leadtimeBackgroundColor"
          :track="{ text: 'Goal today', position: selectedGoal.goalPercentToday }"
          :value="selectedGoal.leadtimeAchieved"
          :max="selectedGoal.leadtimeGoal"
        >
          <template #progress="{ percentage }"> {{ percentage }}% </template>
        </ProgressBar>
      </div>
      <div v-if="selectedGoal.co2Goal" class="organization-goal">
        <div>CO2 Savings</div>
        <div>{{ formattedCo2Savings }} / {{ $formatZeroDecimalPlaces(selectedGoal.co2Goal) }} &nbsp;kg</div>
        <ProgressBar
          :background-color="selectedGoal.co2BackgroundColor"
          :track="{ text: 'Goal today', position: selectedGoal.goalPercentToday }"
          :value="selectedGoal.co2Achieved"
          :max="selectedGoal.co2Goal"
        >
          <template #progress="{ percentage }"> {{ percentage }}% </template>
        </ProgressBar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ProgressBar from '@/components/Reusable/ProgressBar.vue';

export default {
  name: 'OrganizationGoal',

  components: { ProgressBar },

  data() {
    return {
      loading: true,
      selectedGoal: {},
    };
  },

  computed: {
    ...mapState(['organizationGoalDashboardDetails']),

    formattedCostSavings() {
      return this.$formatZeroDecimalPlaces(this.selectedGoal.costAchieved);
    },

    formattedCo2Savings() {
      return this.$formatZeroDecimalPlaces(this.selectedGoal.co2Achieved);
    },

    hasGoals() {
      return this.organizationGoalDashboardDetails.length > 0;
    },
  },

  async mounted() {
    await this.fetchOrganizationGoalDashboardDetails();
    this.loading = false;
    this.selectedGoal = this.organizationGoalDashboardDetails[0];
  },

  methods: {
    ...mapActions(['fetchOrganizationGoalDashboardDetails']),

    updateSelectedPeriod(event) {
      this.selectedGoal = this.organizationGoalDashboardDetails[event.target.value];
    },
  },
};
</script>

<style lang="scss" scoped>
.organization-goal__summary {
  display: flex;
  gap: 64px;
  margin: 5px;
  margin-bottom: 64px;

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
}

.organization-goal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.spark-table select {
  width: auto;
}

.align-horizontal {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 981px) {
  .align-horizontal {
    flex-direction: row;
  }
}
</style>
