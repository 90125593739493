<template>
  <div class="flex flex-col" @click="trigger()">
    <div v-if="feasibilityChecksOutdated" class="text-red-500 text-13 text-center bg-red-50 rounded-4">outdated</div>
    <div v-else class="flex justify-end items-center">
      <FeasibilityValueAndPieChart :percentage="feasibilityScoring" />
    </div>

    <OpenDetails :show="mouseHover === 'feasibility'" />
  </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapState, mapMutations } from 'vuex';

import OpenDetails from '@/components/PartWorkflow/Comparison/components/OpenDetails.vue';
import FeasibilityPopup from '@/components/PartWorkflow/Comparison/tablecells/feasibility/components/FeasibilityPopup.vue';
import FeasibilityValueAndPieChart from '@/components/PartWorkflow/Feasibility/FeasibilityValueAndPieChart.vue';

export default {
  name: 'Feasibility',

  components: {
    FeasibilityValueAndPieChart,
    OpenDetails,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    mouseHover: { type: String, default: '' },
  },

  data() {
    return {
      isMounted: false,
    };
  },

  computed: {
    ...mapState('application', ['showSidebar']),
    ...mapState(['part', 'investigationDetails']),

    feasibilityScoring() {
      let scoring = Math.round(100 - this.chain?.feasibility?.redesign_required_weighted);
      return scoring;
    },

    feasibilityChecksOutdated() {
      return this.chain?.feasibility?.feasibility_checks_outdated === true;
    },
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    ...mapMutations(['triggerPopup', 'changeInvestigationDetails']),

    trigger() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Feasibility',
        type: 'component',
        buttons: true,
        buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
        data: markRaw(FeasibilityPopup),
      });
    },
  },
};
</script>
