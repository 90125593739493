<template>
  <div style="margin: 10px">
    <div
      class="spark-new-input-group"
      title="The active Cost Settings for the analysis. New settings can be created by the manager of your organization via the organizational settings."
    >
      <select
        v-model="defaultConfig"
        required
        class="spark-new-select-input"
        :disabled="disabledSetting"
        :title="title"
      >
        <option v-for="(setting, key) in costingConfigs" :key="key" :value="key">
          {{ setting.name }}
        </option>
      </select>
      <label class="spark-new-select-label">Cost Configuration</label>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'CostConfigSettings',

  props: {
    chain: Object,
    disabledSetting: Boolean,
    title: String,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),

    costingConfigs() {
      return this.user.organization?.costing_configs;
    },

    defaultConfig: {
      get() {
        if (this.chain == undefined) {
          return this.user.organization.costing_config.uid;
        } else {
          return this.chain?.costing_config_id;
        }
      },

      set(value) {
        let formData = { costing_config_id: value };
        this.axiosInstance
          .put(`api/v1/process-chain/${this.chain.process_chain_id}/`, formData)
          .then(response => {
            this.updateSingleProcessChain(response.data);
          })
          .catch(error => {
            console.log('Save Process Chain Error', JSON.stringify(error));
          });
      },
    },
  },

  methods: {
    ...mapMutations(['updateSingleProcessChain']),
  },
};
</script>

<style lang="scss" scoped>
.spark-table {
  width: 100%;
}
</style>
