import { Box3, Vector3 } from 'three';

const zoomFactorPartView = 400;

export function maxBbSizeOfMesh(mesh) {
  const bbMesh = new Box3().setFromArray(mesh.geometry.attributes.position.array);
  let size = new Vector3();
  bbMesh.getSize(size);

  const maxSize = Math.max(...size.toArray());
  return maxSize;
}

export class Zoomer {
  constructor(camera, control, mesh, backsideMesh, physicalPartScale) {
    this.camera = camera;
    this.control = control;
    this.mesh = mesh;
    this.backsideMesh = backsideMesh;
    this.physicalPartScale = physicalPartScale;
  }

  zoomToExtends() {
    this.control.reset(); // reset to initial rotation state (otherwise will not yield the same views on multiple runs)

    this.scaleMesh(this.mesh);
    this.scaleMesh(this.backsideMesh);
    this.setCameraPosition();
    this.zoomCamera();
    this.camera.handleWindowResize(); // assure correct aspect ratio

    this.control.update(); // must be called after each manual camera transform (see https://threejs.org/docs/#examples/en/controls/ArcballControls.update)
  }

  scaleMesh(mesh) {
    /* Resize the mesh so that camera controls behave similar for parts of different size */
    const maxSize = maxBbSizeOfMesh(mesh);
    this.viewScaleFactor = zoomFactorPartView / maxSize;

    let scaleVec = new Vector3(...this.physicalPartScale);
    scaleVec.multiplyScalar(this.viewScaleFactor);

    mesh.scale.set(...scaleVec.toArray());
  }

  setCameraPosition() {
    this.camera.camera.position.set(1, -1, 1);
    this.camera.camera.up.set(0, 0, 1);
  }

  zoomCamera() {
    const zoomOverDeltaRatio = 0.001; // seems to be a sweet spot independent of the left, right, ...
    const delta = this.camera.camera.right - this.camera.camera.left;
    const zoom = zoomOverDeltaRatio * delta;

    this.camera.camera.zoom = zoom;
    this.camera.camera.updateProjectionMatrix(); // applying the zoom
  }
}
