<template>
  <div>
    <hr class="mr-4 mt-8" />
    <div class="flex items-top justify-between">
      <SparkSelect
        v-model="optimizationStyle"
        class="ml-4 pt-8"
        :options="optimizationStyles"
        name="style"
        label="Orientation Optimization Style"
      />
      <SparkButtonIcon class="mr-16 mt-8" icon="fas fa-times" @click="close()" />
    </div>
    <div class="flex justify-between mb-4">
      <div class="ml-8 mb-8 text-11 text-gray-500">
        {{ optimizationStyleInfo[optimizationStyle] }}
      </div>
      <div>
        <SparkButton
          v-if="customStyle"
          :variant="showHull ? 'primary' : 'outlined'"
          small
          @click="togglePickMode(!showHull)"
          ><div v-text="'Pick a face'"
        /></SparkButton>
        <SparkButton v-if="customStyle" class="mr-16 ml-16" variant="outlined" small @click="resetUserOrientation()"
          ><div v-text="'Reset Angles'"
        /></SparkButton>
      </div>
    </div>
    <div v-if="customStyle" class="ml-4 mb-16 flex">
      <CustomAxisRotation axis="x" :reset="resetKey" />
      <CustomAxisRotation axis="y" :reset="resetKey" />
      <CustomAxisRotation axis="z" :reset="resetKey" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

import SparkButton from '../../SparkComponents/SparkButton.vue';
import SparkButtonIcon from '../../SparkComponents/SparkButtonIcon.vue';
import SparkSelect from '../../SparkComponents/SparkSelect.vue';

import CustomAxisRotation from './CustomAxisRotation.vue';

import { computationRunning } from '@/composables/processChainHandling.js';

export default {
  name: 'OrientationOptimizationSetting',

  components: { SparkSelect, SparkButton, CustomAxisRotation, SparkButtonIcon },

  emits: ['close-orientation-dialog'],

  data() {
    return {
      optimizationStyleInfo: {
        alpha_beta_gamma: 'Optimize using all degrees of freedom.',
        gamma_only: 'Optimize by rotating part on build plate normal.',
        flat: 'Lay on outer flat surface and optimize by rotating in this plane.',
        fixed: 'Preserve original orientation.',
        custom: 'Pick a face or set angles manually (Euler XYZ).',
      },

      resetKey: 0,
    };
  },

  computed: {
    ...mapState(['possibleOptimizationStyles', 'investigationDetails', 'part']),
    ...mapState(['activeId']),
    ...mapState('application', ['axiosInstance']),
    ...mapState('canvas', ['canvas']),
    ...mapGetters(['lockedForUser']),

    showHull() {
      return this.canvas.showHull;
    },

    activeProcessChainUid() {
      return this.investigationDetails.uid;
    },

    customStyle() {
      return this.optimizationStyle === 'custom';
    },

    processChain() {
      return this.part.process_chains[this.activeProcessChainUid];
    },

    optimizationStyle: {
      get() {
        return this.processChain?.opt_style;
      },

      set(value) {
        console.log(value);
        let data = { uid: this.activeProcessChainUid, setting: value };
        this.updateOptimizationStyle(data);
        let formData = { opt_style: value };
        this.saveProcessChain(formData);
      },
    },

    optimizationStyles() {
      let optimizationObject = [];
      Object.keys(this.possibleOptimizationStyles).forEach(key =>
        optimizationObject.push({ value: key, label: this.possibleOptimizationStyles[key] })
      );
      return optimizationObject;
    },
  },

  mounted() {
    this.updateDisplayNesting(false);
  },

  beforeUnmount() {
    this.updateShowHull(false);
  },

  methods: {
    ...mapMutations(['updateOptimizationStyle']),
    ...mapMutations(['updateSingleProcessChain']),
    ...mapMutations('canvas', ['updateShowHull', 'updateDisplayNesting']),

    resetUserOrientation() {
      this.resetKey = this.resetKey + 1;
    },

    close() {
      this.$emit('close-orientation-dialog');
    },

    togglePickMode(showingHull) {
      this.updateShowHull(showingHull);
    },

    saveProcessChain(formData) {
      this.prohibitionHandler();
      this.boolProhibitRapidSettingsChange = true;
      if (this.processChain && this.activeProcessChainUid) {
        this.axiosInstance
          .put(`api/v1/process-chain/${this.activeProcessChainUid}/`, formData)
          .then(response => {
            this.updateSingleProcessChain(response.data);
            this.boolProhibitRapidSettingsChange = false;
          })
          .catch(error => {
            console.log('Save Process Chain Error', JSON.stringify(error));
          });
      }
    },

    prohibitionHandler() {
      if (!this.partAlreadyExisting) {
        return;
      }
      if (this.lockedForUser) {
        return;
      }
      if (computationRunning(this.chain.process_chain_id)) {
        this.$root.notify(
          'warning',
          'Analysis running',
          'You may select a new material once the analysis is finished.',
          4000
        );
        return;
      } else if (this.boolProhibitRapidSettingsChange) {
        this.$root.notify(
          'warning',
          'Material currently processing',
          'Please wait a few seconds before changing the material again.',
          4000
        );
        return;
      }
    },
  },
};
</script>
