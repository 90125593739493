<template>
  <div class="flex flex-col gap-10 p-20 border border-gray-50 rounded-8 m-8">
    <div class="text-15 font-bold">Supplier Settings</div>

    <SparkCheckbox v-model="isSupplierValue" name="is_supplier">
      <div class="text-13" v-text="'Register as Supplier'" />
    </SparkCheckbox>

    <SparkSelect
      v-model.lazy="selectedSalesRepValue"
      :options="salesRepOptions"
      label="Sales Representative"
      name="sales_rep"
    />

    <SparkButton class="mt-8 !w-[200px]" variant="secondary" small @click="updateOrganization()">
      <div v-text="'Update'" />
    </SparkButton>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import SparkButton from '../../../../../../components/SparkComponents/SparkButton.vue';

import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'SalesRepresentative',

  components: { SparkCheckbox, SparkSelect, SparkButton },

  data() {
    return {
      selectedSalesRepValue: '',
      isSupplierValue: false,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState('supplier', ['users', 'selectedSalesRep', 'isSupplier']),

    salesRepOptions() {
      return this.users.map(user => ({
        value: user.user_id,
        label: `${user.first_name} ${user.last_name}`,
      }));
    },
  },

  watch: {
    selectedSalesRepValue(value) {
      this.setSelectedSalesRep(value);
    },

    isSupplierValue(value) {
      this.setIsSupplier(value);
    },
  },

  async mounted() {
    await this.fetchUsers();
    this.selectedSalesRepValue = this.selectedSalesRep.user_id;
    this.isSupplierValue = this.isSupplier;
  },

  methods: {
    ...mapActions('supplier', ['fetchUsers']),

    ...mapMutations('supplier', ['setSelectedSalesRep', 'setIsSupplier']),

    async updateOrganization() {
      let formData = new FormData();
      formData.append('is_supplier', this.isSupplier);
      formData.append('sales_rep_id', this.selectedSalesRep);
      await this.axiosInstance
        .put('/api/v1/organization-settings/', formData)
        .then(() => {
          this.$root.notify('success', 'Supplier settings saved successfully.', 3000);
        })
        .catch(error => {
          this.$root.notify('error', error.response.data.error_message, 3000);
        });
    },
  },
};
</script>
