<template>
  <div>
    <table class="spark-table" style="margin-bottom: 5px; vertical-align: baseline">
      <tr v-if="tableInput[0][0] === 'input'">
        <td>{{ tableInput[0][1].name }}</td>
        <td>
          <input
            v-model.lazy="tableInput[0][1].value"
            required=""
            type="text"
            name="text"
            autocomplete="off"
            class="spark-new-input-clean table-input"
          />
        </td>
      </tr>

      <tr v-if="tableInput[1][0] === 'select'">
        <td>{{ tableInput[1][1].name }}</td>
        <td>
          <select v-model="tableInput[1][1].value">
            <option v-for="item in tableInput[1][1].options" :key="item.key" :value="item.value">
              {{ item.text }}
            </option>
          </select>
        </td>
      </tr>

      <tr v-else>
        <td>{{ tableInput[0][0] }}</td>
        <td>{{ tableInput[0][1] }}</td>
        <td>{{ tableInput[0][2] }}</td>
      </tr>
      <tr v-for="info in tableDataWithoutInput" :key="info">
        <td>{{ info[0] }}</td>
        <td>{{ info[1] }}</td>
        <td>{{ info[2] }}</td>
      </tr>
    </table>

    <!-- <component :is="Component" /> -->
  </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapMutations } from 'vuex';
export default {
  name: 'PopupTable',

  props: {
    tableInput: Object,
  },

  data() {
    return {
      Component: markRaw(this.tableInput),
    };
  },

  computed: {
    tableDataWithoutInput() {
      return this.tableInput.slice(2);
    },
  },

  watch: {
    tableInput: {
      handler(newVal) {
        this.triggerPopup({ tableInput: newVal });
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),
  },
};
</script>

<style lang="scss" scoped>
.table-input,
.table-input:hover {
  margin-left: unset;
  padding-left: 1px;
}
</style>
