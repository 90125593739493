<template>
  <div class="pr-40 p-4 pl-8 border border-solid border-transparent border-r-gray-100">
    <div class="text-gray-400 text-11">{{ heading }}</div>
    <div><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'SummaryItem',

  props: {
    heading: { type: String, default: '' },
  },
};
</script>
