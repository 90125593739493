<template>
  <div class="flex mx-20"><FeasibilityCard /></div>
</template>

<script>
import FeasibilityCard from './FeasibilityCard.vue';

export default {
  name: 'FeasibilityPopup',

  components: { FeasibilityCard },
};
</script>
