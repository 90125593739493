const prpMutations = {
  setSelectedPrpParts(state, payload) {
    for (let key in payload) {
      state.selectedPrpParts[key] = payload[key];
    }
  },
  setInitialPrpSelectedParts(state) {
    const prpParts = state.prpParts;
    const selectedParts = prpParts.reduce((acc, part) => {
      acc[part.part_id] = false;
      return acc;
    }, {});
    state.selectedPrpParts = selectedParts;
  },
  setPrpPartLibraryData(state, data) {
    state.prpPartLibraryData = data;
  },
  setPrpPartAnalysisDone(state, value) {
    state.prpPartAnalysisDone = value;
  },
  setPrpParts(state, data) {
    state.prpParts = data;
  },
  setPrpOrderCategories(state, categories) {
    state.prpOrderCategories = categories;
  },
  setProcessChainUpdated(state, value) {
    state.processChainUpdated = value;
  },
};
export default prpMutations;
