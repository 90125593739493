<template>
  <ValidationForm v-slot="data" :validation-schema="validationSchema">
    <slot v-bind="data" />
  </ValidationForm>
</template>

<script>
import { Form as ValidationForm } from 'vee-validate';
export default {
  name: 'SparkForm',

  components: { ValidationForm },

  props: {
    validationSchema: { type: Object, default: () => {} },
  },
};
</script>
