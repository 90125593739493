<template>
  <div style="overflow-y: scroll; max-height: 90%">
    <TransitionGroup name="fadening">
      <div v-if="!newsfeedLength">You are up to date.</div>
      <div v-for="(news, index) in allUserNews" v-else :key="news.uid">
        <div
          :id="index"
          class="card2"
          style="margin-bottom: 20px; cursor: default"
          @mouseover="mouseHover[index] = true"
          @mouseleave="mouseHover[index] = false"
        >
          <div v-if="validateThisUrl(news.url)">
            <News :news="news" :news-url="news.url" @deleteNews="deleteNews($event)" />
            <CardArrow
              v-if="validateThisUrl(news.url)"
              arrow-title="Go to"
              :to-route="news.url"
              :mouse-hover="mouseHover[index]"
            />
          </div>
          <div v-else>
            <News :news="news" @deleteNews="deleteNews($event)" />
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CardArrow from './CardArrow.vue';
import News from './News.vue';

import validateUrl from '@/composables/validateUrl.js';

export default {
  name: 'Newsfeed',

  components: {
    CardArrow,
    News,
  },

  props: {
    fetchNews: { type: Number, required: true },
  },

  data() {
    return {
      allUserNews: null,
      mouseHover: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    newsfeedLength() {
      if (this.allUserNews != null) {
        let newsfeedLength = Object.keys(this.allUserNews).length;
        if (Array.isArray(this.allUserNews)) {
          this.mouseHover = new Array(newsfeedLength).fill(false);
          return this.allUserNews.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },

  watch: {
    fetchNews() {
      this.fetchAllAccessibleNews();
    },
  },

  mounted() {
    this.getNewsfeed();
  },

  methods: {
    validateThisUrl(url) {
      return validateUrl(url).validateBool;
    },

    async getNewsfeed() {
      await this.axiosInstance
        .get('/api/v1/news-subscription/')
        .then(response => {
          let allUserNews = response.data;
          this.allUserNews = allUserNews;
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    async fetchAllAccessibleNews() {
      await this.axiosInstance
        .get('/api/v1/news-all-accessible/')
        .then(response => {
          this.allUserNews = response.data;
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    async deleteNews(uid) {
      await this.axiosInstance
        .put(`/api/v1/news-subscription/${uid}/`)
        .then(() => {
          const index = this.allUserNews.findIndex(news => news.uid === uid);
          if (index !== -1) {
            this.allUserNews.splice(index, 1);
          }
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card2 {
  --border-radius: 0.2rem;
  padding: 1rem;
  margin: 5px;
  cursor: pointer;
  border-radius: var(--border-radius);
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 10%);
  position: relative;
  margin-bottom: 20px;
  transition: all 0.3s ease-out;
}

.fadening-move, /* apply transition to moving elements */
.fadening-enter-active,
.fadening-leave-active {
  transition: all 0.7s ease;
}

.fadening-enter-from,
.fadening-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
// .fadening-leave-active {
//   position: absolute;
// }
</style>
