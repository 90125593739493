<template>
  <div class="max-w-full bg-white rounded-8 border-solid border border-gray-100">
    <div class="p-4 flex items-center">
      <PartThumbnail />
      <PartName :no-file-uploaded="noCADFileUploaded" :locked-for-user="lockedForUser" />
      <div class="mr-0 ml-auto">
        <Status v-if="!userIsExternal" />
      </div>
    </div>
    <div class="bg-gray-50">
      <InputSummary :locked-for-user="lockedForUser" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import InputSummary from '@/components/PartWorkflow/Header/InputSummary.vue';
import PartName from '@/components/PartWorkflow/Header/PartName.vue';
import PartThumbnail from '@/components/PartWorkflow/Header/PartThumbnail.vue';
import Status from '@/components/PartWorkflow/Header/Status.vue';
export default {
  name: 'HeaderPartInformation',

  components: { Status, PartName, InputSummary, PartThumbnail },

  computed: {
    ...mapState(['part', 'user']),
    ...mapState('application', ['lockedTitle']),
    ...mapGetters(['lockedForUser']),
    noCADFileUploaded() {
      return (this.part.name == undefined || this.part.name === '') && this.part.basename === '';
    },

    userIsExternal() {
      return this.user.is_external;
    },
  },
};
</script>
