<template>
  <SparkModal custom="w-[500px]" dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold" v-text="'Add Property'" />
    </template>

    <template #default>
      <SparkInput v-model="name" label="Name" name="name" class="w-full mb-48 mt-16" />
      <SparkInput v-model="type" label="Type" name="type" class="w-full" />
    </template>
  </SparkModal>
</template>

<script>
import SparkInput from '../../../../components/SparkComponents/SparkInput.vue';
import SparkModal from '../../../../components/SparkComponents/SparkModal.vue';

export default {
  name: 'FileUploadModal',

  components: { SparkModal, SparkInput },

  props: { show: { type: Boolean } },

  emits: ['close'],

  data() {
    return {
      name: '',
      type: '',
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
