<template>
  <div class="filter-element">
    <div class="filter-label">{{ label }}</div>
    <div class="filtering">
      <Toggle :possible-classes="levels" :selected-class="selectedLevel" @selected="selectLevel($event)" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import Toggle from '../Toggle.vue';

export default {
  name: 'PartsFilter',

  components: { Toggle },

  props: {
    reset: { type: Number, required: true },
    component: { type: String, required: true },
    label: { type: String, required: true },
    parent: { type: String, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      levels: {
        all: 'All Parts',
        prp: 'PRP Parts',
      },

      selectedLevel: {
        all: 'All Parts',
      },
    };
  },

  watch: {
    reset: {
      handler() {
        if (this.reset) {
          this.remove();
          this.selectedLevel = {
            all: 'All Parts',
          };
        }
      },
    },
  },

  mounted() {
    if (this.parent === 'library') {
      const filter = JSON.parse(sessionStorage.getItem('libraryFilter'));
      const partType = filter?.prp_parts ? 'prp' : 'all';
      const selectedPartType = {};
      if (this.levels[partType]) {
        selectedPartType[partType] = this.levels[partType];
        this.selectedLevel = selectedPartType;
      } else {
        this.selectLevel(this.selectedLevel);
      }
    } else {
      this.selectLevel(this.selectedLevel);
    }
  },

  methods: {
    ...mapMutations(['disablePartLibraryFilter', 'resetFilter']),

    selectLevel(event) {
      this.selectedLevel = event;
      const query_type = Object.keys(this.selectedLevel)[0];
      const prp_parts = query_type === 'prp';
      this.$emit('selected', { prp_parts: prp_parts });
    },

    remove() {
      this.disablePartLibraryFilter({
        [this.label]: this.component,
      });
      this.resetFilter({ prp_parts: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.filtering {
  padding: 5px 0px;
}
</style>
