<template>
  <div class="default-layout flex h-full w-full flex-col">
    <div class="flex h-full w-full flex-col gap-16 self-center">
      <SubNavigation />
      <div class="flex h-full w-full">
        <div class="flex h-full w-full flex-col">
          <router-view />
        </div>
        <transition
          appear
          name="slide-in-right"
          enter-active-class="transition-all duration-300"
          enter-from-class="opacity-0 translate-x-full"
        >
          <div
            v-if="showSidebar"
            class="w-[500px] h-[calc(100vh-66px)] mt-[66px] overflow-visible relative bg-white flex-col gap-16 xl:flex border border-gray-50"
          >
            <div>
              <div class="flex items-center justify-between border-b border-gray-50 p-20">
                <div id="sidebar-title" />
                <SparkButtonIcon icon="fas fa-xmark" @click="toggleSidebar" />
              </div>
            </div>
            <div id="sidebar-teleport" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButtonIcon from '@/components/SparkComponents/SparkButtonIcon.vue';
import SubNavigation from '@/views/Settings/components/SubNavigation.vue';

export default {
  name: 'DefaultLayout',

  components: { SubNavigation, SparkButtonIcon },

  computed: {
    ...mapState('application', ['showSidebar']),
  },

  methods: {
    ...mapMutations('application', ['toggleSidebar']),
  },
};
</script>
