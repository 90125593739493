<template>
  <div class="relative" :class="{ [custom]: !!custom }">
    <label v-if="(!!label || $slots.label) && topLabel" class="text-13 block text-gray-500" :for="uid">
      <slot name="label">
        {{ label }}
      </slot>
    </label>

    <div class="relative">
      <Field
        :id="uid"
        v-model="inputField"
        validate-on-input
        class="block border h-32 focus:border-2 px-12 pb-2 pt-4 w-full text-15 text-gray-900 bg-white rounded-4 border-1 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary-600 peer"
        :class="{
          '!border-red-500': !!hasErrors,
          '!border-gray-200 !bg-gray-50 !text-gray-300': disabled,
          [custom]: !!custom,
        }"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="rules"
        :type="type"
        :autocomplete="autocomplete"
        @change="$emit('change', $event)"
        @blur="$emit('blur', $event)"
      />
      <label
        v-if="label && animateLabel"
        :for="uid"
        :name="name"
        class="absolute ml-8 text-15 text-gray-500 duration-300 transform scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:-top-4 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        :class="{
          'text-13 !-top-10': !!inputField || inputField === 0,
        }"
        v-text="label"
      />
      <label
        v-else-if="label && !animateLabel && !topLabel"
        :for="uid"
        :name="name"
        class="absolute text-13 text-gray-500 ml-8 bg-white duration-300 transform origin-[0] -top-8 start-1"
        v-text="label"
      />
    </div>

    <div v-if="suffixIcon || hasErrors" class="absolute right-0 top-1/2 transform -translate-y-1/2 py-2 px-12">
      <i
        class="text-13"
        :class="{ [suffixIcon]: !!suffixIcon, 'cursor-pointer': suffixIcon !== 'fas fa-search' }"
        @click="$emit('suffix-clicked')"
      />
    </div>

    <span v-if="error" class="text-11 absolute left-0 top-full pt-0 text-red-500" v-text="error" />
  </div>
</template>

<script>
import { Field } from 'vee-validate';
import { computed, ref, watch } from 'vue';

export default {
  name: 'SparkInput',

  components: { Field },

  props: {
    autocomplete: { type: HTMLInputElement['autocomplete'], default: 'off' },
    disabled: { type: Boolean },
    label: { type: String, default: '' },
    name: { type: String, required: true },
    placeholder: { type: String, default: '' },
    readonly: { type: Boolean },
    rules: { type: [Object, String, Function], default: '' },
    type: { type: String, default: 'text' },
    suffixIcon: { type: String, default: '' },
    animateLabel: { type: Boolean },
    topLabel: { type: Boolean },
    error: { type: String, default: '' },

    custom: { type: String, default: '' },

    modelValue: { type: [Number, String, null, File], default: '' },
  },

  emits: ['update:modelValue', 'suffix-clicked', 'change', 'blur'],

  setup(props, { emit }) {
    const inputField = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value),
    });

    const uid = computed(() => `input-${Math.random().toString(36).substring(2, 9)}`);

    const hasErrors = ref(false);
    watch(
      () => props.error,
      () => {
        hasErrors.value = props.error ? true : false;
      },
      { immediate: true }
    );

    return { inputField, uid, hasErrors };
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
