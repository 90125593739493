<template>
  <div style="margin: 10px">
    <div
      class="spark-new-input-group"
      title="The active Pricing Settings for the analysis. Pricing Settings can be created by the manager of your organization via the organizational settings."
    >
      <select
        v-model="selectedPricingOption"
        required
        class="spark-new-select-input"
        :disabled="disabledSetting"
        :title="title"
      >
        <option v-for="(option, key) in pricingOptions" :key="key" :value="option.uid">
          {{ option.name }}
        </option>
      </select>
      <label class="spark-new-select-label">Price Configuration</label>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'PricingConfigSettings',

  props: {
    chain: Object,
    disabledSetting: Boolean,
    title: String,
  },

  data() {
    return {
      pricingOptionList: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['pricingOptions']),

    selectedPricingOption: {
      get() {
        return this.chain?.pricing_config_id;
      },

      set(value) {
        console.log(value);
        let formData = { pricing_config_id: value };
        this.axiosInstance.put(`api/v1/process-chain/${this.chain.process_chain_id}/`, formData).then(response => {
          this.updateSingleProcessChain(response.data);
        });
      },
    },
  },

  methods: {
    ...mapMutations(['updateSingleProcessChain']),
  },
};
</script>

<style lang="scss" scoped>
.spark-table {
  width: 100%;
}
</style>
