<template>
  <div>
    <p style="background: var(--spark-delete); border-radius: 3px; padding: 5px; display: block; text-align: center">
      Please do not leave this page. This will take a while.
    </p>
    <Loading />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Loading from '@/components/Reusable/Loading.vue';

export default {
  name: 'PartLibraryDump',

  components: {
    Loading,
  },

  emits: ['dump-finished'],

  computed: {
    ...mapState(['user', 'fetchPartLibraryDump']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    fetchPartLibraryDump: function (newVal) {
      if (newVal) {
        this.fetchPartLibraryDumpFile();
        this.updateFetchPartLibraryDump(false);
      }
    },
  },

  mounted() {
    this.dumpLibrary();
  },

  methods: {
    ...mapMutations(['updateFetchPartLibraryDump']),

    async dumpLibrary() {
      await this.axiosInstance
        .post('/api/v1/library-dump/')
        .then(response => {})
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    async fetchPartLibraryDumpFile() {
      this.axiosInstance
        .get('/api/v1/fetch-library-dump/', {
          responseType: 'blob',
        })
        .then(response => {
          let currentDate = new Date().toISOString().slice(0, 10);
          let fileName = currentDate + '_Part_Library.xlsx';
          saveAs(response.data, fileName);
          this.$emit('dump-finished');
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
