<template>
  <div class="glass-box mt-32">
    <div class="flex justify-between items-center">
      <p class="font-bold text-15">Calculation Details</p>
      <SparkButton
        v-if="processSteps.length === 0"
        class="mt-16"
        small
        variant="secondary"
        @click="showCalculationDetails"
        >Get Calculation Details</SparkButton
      >
    </div>
    <div v-if="processSteps.length > 0" class="mt-4">
    <div v-for="processStep in processSteps" :key="processStep.id" class="my-8 p-4 border rounded-8">
      <h4 class="font-bold text-lg mb-3">{{ processStep.name }}</h4>
      <div v-for="calculation in processStep.calculations" :key="calculation.name" class="mb-4">
        <details class="[&[open]]:bg-gray-100 rounded-4">
          <summary class="cursor-pointer flex items-center hover:bg-gray-100">
            <span class="pl-4">{{ calculation.name }}</span>
          </summary>
          <div class="mt-2 pl-8 bg-white">
            <div class="mt-4"><span class="text-gray-500">Expression</span> {{ calculation.expression }}</div>
            <div>
              <span class="text-gray-500">Evaluated</span>
              {{ evaluateExpression(calculation.expression, calculation.calculation_variables) }} =
              {{ calculation.value }}
            </div>

            <table class="w-full border-collapse mt-4">
              <thead>
                <tr>
                  <th class="border p-2 text-left">Variable</th>
                  <th class="border p-2 text-left">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key) in filterUsedVariables(calculation.expression, calculation.calculation_variables)" :key="key">
                  <td class="border p-2">{{ key }}</td>
                  <td class="border p-2">{{ value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </details>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';

export default {
  name: 'GlassBox',

  components: {
    SparkButton,
  },

  props: {
    processChainId: { type: String, default: '' },
  },

  data() {
    return {
      processSteps: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  methods: {
    ...mapMutations(['changeDisplayInfo', 'setCalculationDetails']),

    showCalculationDetails() {
      this.axiosInstance
        .get(`/api/v1/calculation-details/${this.processChainId}/`)
        .then(response => {
          this.processSteps = response.data;
        })
        .catch(error => {
          this.$root.notifyError('Fetching Calculation Details', error);
        });
    },

    evaluateExpression(expression, variables) {
      let evaluatedExpression = expression;
      // Sort keys by length in descending order to replace longer variable names first
      const sortedKeys = Object.keys(variables).sort((a, b) => b.length - a.length);

      for (const key of sortedKeys) {
        const value = variables[key];
        const regex = new RegExp(`\\b${key}\\b`, 'g');
        evaluatedExpression = evaluatedExpression.replace(regex, value);
      }
      return evaluatedExpression;
    },

    filterUsedVariables(expression, variables) {
      const usedVariables = {};
      for (const [key, value] of Object.entries(variables)) {
        if (expression.includes(key)) {
          usedVariables[key] = value;
        }
      }
      return usedVariables;
    },
  },

};
</script>