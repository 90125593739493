<template>
  <SparkModal :model-value="show" dismissable @update:model-value="close">
    <template #header> Please set your role </template>
    <template #default>
      <div class="text-15 mb-8 font-bold">What is your role in your company?</div>
      <div class="flex mb-32">
        <div v-for="role in userCompanyRoles" :key="role.value" class="flex flex-col gap-8">
          <div
            class="flex items-center gap-8 hover:bg-primary-300 hover:cursor-pointer p-4 mr-8 rounded-4"
            :class="
              selectedRole === role.value
                ? 'bg-primary-500 rounded-8 border border-primary-500'
                : ' border border-primary-500 rounded-8'
            "
            @click="roleSelected(role.value)"
          >
            {{ role.text }}
          </div>
        </div>
      </div>
      <div class="text-15 font-bold">What is your intended use of 3d spark? (How can we help at your daily work?)</div>
      <div class="text-11 mb-8 text-gray-300">Choose one or more options</div>
      <div class="flex flex-col gap-8 mb-32">
        <SparkCheckbox v-model="usage.process_orders" name="process_orders">
          <div v-text="'Process internal or external Orders (Costing/ Pricing)'" />
        </SparkCheckbox>
        <SparkCheckbox v-model="usage.manufacturability_checks" name="manufacturability_checks">
          <div v-text="'Check manufacturability of parts'" />
        </SparkCheckbox>
        <SparkCheckbox v-model="usage.technology_comparison" name="technology_comparison">
          <div v-text="'Technology Comparison'" />
        </SparkCheckbox>
        <SparkCheckbox v-model="usage.supplier_selection" name="supplier_selection">
          <div v-text="'Supplier Selection'" />
        </SparkCheckbox>
        <SparkCheckbox v-model="usage.tracking_savings" name="tracking_savings">
          <div v-text="'Tracking Savings'" />
        </SparkCheckbox>
        <SparkCheckbox v-model="usage.reporting" name="reporting">
          <div v-text="'Reporting'" />
        </SparkCheckbox>
        <SparkCheckbox v-model="usage.data_visualisation" name="data_visualisation">
          <div v-text="'Data Visualisation'" />
        </SparkCheckbox>
      </div>
      <div>
        <SparkButton @click="save()">Submit</SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'RoleModal',

  components: {
    SparkModal,
    SparkButton,
    SparkCheckbox,
  },

  props: {
    show: {
      type: Boolean,
    },
  },

  emits: ['close'],

  data() {
    return {
      selectedRole: '',
      usage: {
        process_orders: false,
        manufacturability_checks: false,
        technology_comparison: false,
        supplier_selection: false,
        tracking_savings: false,
        reporting: false,
        data_visualisation: false,
      },
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['user', 'userCompanyRoles']),
  },

  methods: {
    close() {
      this.$emit('close');
    },

    roleSelected(role) {
      this.selectedRole = role;
    },

    save() {
      console.log(this.usage);
      let result = this.axiosInstance.post(`api/v1/user/user_role_type/${this.user.user_id}/`, {
        role: this.selectedRole,
        usage: this.usage,
      });
      this.close();
    },
  },
};
</script>
