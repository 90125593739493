<template>
  <div id="canvas" class="bg-white rounded-8 border border-gray-100 flex flex-col justify-around">
    <img src="@/assets/img/cadless_image.svg" class="pl-24" />
    <table class="spark-table">
      <tbody>
        <tr>
          <td>Bounding Box</td>
          <td class="align-right">{{ part.bb_x }}mm x {{ part.bb_y }} mm x {{ part.bb_z }} mm</td>
        </tr>
        <tr>
          <td>Volume</td>
          <td class="align-right">{{ part.part_vol }} cm³</td>
        </tr>
        <tr>
          <td>Mass</td>
          <td class="align-right">{{ displayPartMass }} g</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CadLessCanvas',

  data() {
    return {
      xMax: 400,
      yMax: 400,
    };
  },

  computed: {
    ...mapState(['part', 'investigationDetails']),

    displayPartMass() {
      const activeChain = this.activeProcessChain;
      if (activeChain && activeChain.part_mass) {
        return activeChain.part_mass;
      } else {
        return this.part.current_part_mass;
      }
    },

    highlightedProcessChainUid() {
      return this.investigationDetails.uid;
    },

    activeProcessChain() {
      if (this.highlightedProcessChainUid != '' || this.highlightedProcessChainUid != null) {
        let allChains = this.part.process_chains;
        return allChains[this.highlightedProcessChainUid];
      } else return undefined;
    },
    // bbX() {
    //     return part.bb_x;
    // },

    // y() {
    //     return part.bb_y
    // }
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: white;
  margin: 0px 100px;
  position: sticky;
  z-index: 4;
  display: flex;
  flex-direction: column;
}

img {
  margin: 20px;
}
.align-right {
  text-align: right;
}
</style>
