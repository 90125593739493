<template>
  <div>
    <div v-for="(option, key, index) in popupSelectOptions" :key="index" class="flex items-center m-12">
      <SparkCheckBox
        v-model="popupSelectOptions[key].selected"
        :disabled="option.disabled"
        :name="`${index}-${option.title}`"
      >
        <div v-text="option.title" />
      </SparkCheckBox>
    </div>
  </div>
</template>

<script>
import SparkCheckBox from '@/components/SparkComponents/SparkCheckbox.vue';

export default {
  name: 'PopupMultiSelectOption',

  components: { SparkCheckBox },

  props: {
    popupSelectOptions: { type: Object, required: true },
  },
};
</script>
