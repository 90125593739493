const canvasMutations = {
  resetCanvas(state) {
    state.canvas.rot_stat = 'rot_init';
    state.canvas.transparency = false;
  },
  updateReloadCanvas(state, newBool) {
    state.reloadCanvas = newBool;
  },
  updateDisplayNesting(state, value) {
    state.canvas.showNesting = value;
  },
  updateRotStat(state, newStr) {
    state.canvas.rot_stat = newStr;
  },
  updateTransparency(state, value) {
    state.canvas.transparency = value;
  },
  updateShowGrid(state, value) {
    state.canvas.showGrid = value;
  },
  updateShowHull(state, value) {
    state.canvas.showHull = value;
  },
  updateFeasibilityToggle(state, newStr) {
    state.feasibilityToggle = newStr;
  },
};
export default canvasMutations;
