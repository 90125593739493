<template>
  <div>
    <div class="chart-container">
      <h5 class="margin-30">Total and Active Users reference chart</h5>
      <div v-if="loading" class="loading-container">
        <Loading />
      </div>
      <div v-else>
        <apexchart ref="shortChart" type="line" :options="chartOptions" :series="series" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/Reusable/Loading.vue';

export default {
  name: 'TotalAndActiveUsers',
  components: { Loading },
  props: {
    organizations: Array,
    totalActiveUsers: Object,
    totalNewUsers: Object,
    totalUsers: Object,
    months: Array,
  },
  data: function () {
    return {
      loading: true,
      series: [],
    };
  },
  computed: {
    ...mapState('application', ['axiosInstance']),
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          fontFamily: 'Poppins, Arial, sans-serif',
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>',
            },
          },
        },

        subtitle: {
          text: 'Total, Active and New Users representation.',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '10px',
            color: '#263238',
          },
        },

        stroke: {
          width: [3, 3, 3, 3],
          curve: 'smooth',
        },

        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5,
          },
        },

        markers: {
          size: 1,
        },

        xaxis: {
          categories: this.months,
        },

        yaxis: {
          title: {
            text: 'Users',
          },

          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(0);
            },
          },
        },

        legend: {
          show: true,
          position: 'top',
        },
      };
    },
  },
  
  watch: {
    totalActiveUsers: 'updateSeries',
    totalNewUsers: 'updateSeries',
    totalUsers: 'updateSeries'
  },
  methods: {
    updateSeries() {
      this.series = [];

      if (this.totalActiveUsers && Object.keys(this.totalActiveUsers).length > 0) {
        this.series.push(this.totalActiveUsers);
      }

      if (this.totalNewUsers && Object.keys(this.totalNewUsers).length > 0) {
        this.series.push(this.totalNewUsers);
      }

      if (this.totalUsers && Object.keys(this.totalUsers).length > 0) {
        this.series.push(this.totalUsers);
      }

      this.loading = false;
    },
  },
  mounted() {
    this.updateSeries();
  }
};
</script>

<style lang="scss" scoped>
.margin-30 {
  margin-top: 30px;
}
.chart-container {
  position: relative;
}
.loading-container {
  position: relative;
  top: -100px;
  left: 0;
  right: 0;
  align-self: start;
}
</style>
