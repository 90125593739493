<template>
  <div class="info">
    <MeshChecks v-if="displayInfo == 'meshChecks'" />
    <BreakEvenChart v-if="displayInfo == 'breakEvenChart'" show-toolbar />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import BreakEvenChart from '@/components/PartWorkflow/Info/BreakEvenChart.vue';
import MeshChecks from '@/components/PartWorkflow/Info/MeshChecks.vue';
export default {
  name: 'Info',

  components: { MeshChecks, BreakEvenChart },

  computed: {
    ...mapState(['part']),
    displayInfo() {
      return this.part.displayInfo;
    },
  },

  methods: {
    ...mapMutations(['changeDisplayInfo']),
  },
};
</script>

<style lang="scss" scoped>
.info {
  width: 100%;
  height: auto;
}
</style>
