<template>
  <SparkButton class="ph-share-document" small variant="secondary" @click="open()"
    ><i class="fas fa-share mr-4" />Share</SparkButton
  >
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ShareOptions from './ShareOptions.vue';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';

export default {
  name: 'ShareButton',

  components: { SparkButton },

  emits: ['printPDFReport'],

  computed: {
    ...mapState(['popup']),
  },

  watch: {
    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    open() {
      this.$store.commit('triggerPopup', {
        key: this.$options.name,
        show: true,
        title: 'Share this part',
        type: 'component',
        data: ShareOptions,
        buttons: true,
        buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
      });
    },
  },
};
</script>
