<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #default>
      <div class="font-semibold text-19 mb-16" v-text="'Add Part'" />

      <Upload />
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkModal from '../../../../components/SparkComponents/SparkModal.vue';
import Upload from '../Upload.vue';

export default {
  name: 'FileUploadModal',

  components: { SparkModal, Upload },

  props: { show: Boolean },

  emits: ['close'],

  computed: {
    ...mapState(['partUploadError']),
  },

  watch: {
    partUploadError(error) {
      if (error) {
        this.close();
      }
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
