<template>
  <div class="w-full">
    <div class="flex justify-start items-baseline">
      <p class="font-bold text-15 mt-24 pr-4">Process Chain</p>
      <Toggle :possible-classes="levels" :selected-class="selectedLevel" @selected="selectLevel($event)" />
    </div>
    <div v-if="!analysisFinished" class="text-12 text-red-500 my-8 bg-red-50 p-4 rounded-4 flex items-center">
      <img src="@/assets/img/red_exclamation_mark.svg" class="w-16 h-16 mr-8" />
      <span>Values are estimations. Please calculate for more accuracy.</span>
    </div>
    <ProcessChain :chain="activeProcessChain" :selected-level="selectedLevel" property="co2" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ProcessChain from '@/components/PartWorkflow/ProcessChain/ProcessChain.vue';
import Toggle from '@/components/Reusable/Toggle.vue';
import { analysisFinished } from '@/composables/processChainHandling.js';

export default {
  name: 'CarbonFootprint',

  components: {
    ProcessChain,
    Toggle,
  },

  data() {
    return {
      selectedLevel: {
        _pp: 'Part',
      },
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part']),

    analysisFinished() {
      return analysisFinished(this.activeInvestigationDetails.uid);
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },

    levels() {
      let labels = {
        _pp: 'Part',
        _pj: '',
        _pl: 'Lot [' + this.activeProcessChain.lot_size + ']',
      };

      if (this.activeProcessChain.lot_only) {
        labels['_pj'] = 'Job [Ø ' + this.activeProcessChain.parts_pj + ']';
      } else {
        labels['_pj'] = 'Job [' + this.activeProcessChain.parts_pj + ']';
      }
      return labels;
    },
  },

  methods: {
    selectLevel(event) {
      this.selectedLevel = event;
    },
  },
};
</script>
