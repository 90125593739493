<template>
  <SparkModal custom="w-[500px]" dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold" v-text="'Edit Properties'" />
    </template>

    <div class="flex flex-col">
      <ChainDescriptionOptions multiple-parts :part-ids="partIds" :part="part" @option-selected="optionSelected" />
      <SparkButton custom="!text-13 min-w-80" class="self-end mt-32" variant="secondary" @click="close">
        <div v-text="'Ok'" />
      </SparkButton>
    </div>
  </SparkModal>
</template>

<script>
import SparkButton from '../../../../components/SparkComponents/SparkButton.vue';
import SparkModal from '../../../../components/SparkComponents/SparkModal.vue';
import ChainDescriptionOptions from '../ChainDescriptionOptions.vue';

export default {
  name: 'EditPropertiesModal',

  components: { SparkModal, ChainDescriptionOptions, SparkButton },

  props: {
    show: { type: Boolean },
    part: { type: Object, default: () => {} },
    partIds: { type: Array, default: () => [] },
  },

  emits: ['close', 'option-selected'],

  methods: {
    close() {
      this.$emit('close');
    },

    optionSelected(data) {
      this.$emit('option-selected', data);
    },
  },
};
</script>
