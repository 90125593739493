<template>
  <div class="card-arrow" :class="mouseHover ? 'card-arrow-hover' : ''">
    <a v-if="isURL(toRoute)" rel="noopener noreferrer" target="_blank" :href="toRoute"
      ><i class="fas fa-arrow-right" />&nbsp;{{ arrowTitle }}</a
    >
    <router-link v-else :to="toRoute"><i class="fas fa-arrow-right" />&nbsp;{{ arrowTitle }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'CardArrow',

  props: {
    arrowTitle: String,
    toRoute: String,
    Object,
    mouseHover: Boolean,
  },

  data() {
    return {
      toRouteString: '',
    };
  },

  methods: {
    isURL(str) {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return pattern.test(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-arrow {
  margin-top: 10px;
  position: absolute;
  padding: 0.4rem;
  padding-right: 20px;
  padding-left: 15px;
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  bottom: 0;
  right: 0;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    transition: 0.2s;
  }
}

.card-arrow-hover {
  background: var(--accent-color);

  i {
    transform: translateX(3px);
  }
}
</style>
