import Debugging from '@/views/Developer/components/Debugging.vue';
import MachineLearning from '@/views/Developer/components/MachineLearning.vue';
import NewsManagement from '@/views/Developer/components/NewsManagement.vue';
import Developer from '@/views/Developer/Developer.vue';

const subNavigations = {
  developer: [
    {
      label: 'Debugging',
      value: 'debugging',
      to: { name: `debugging` },
    },
    {
      label: 'Machine Learning',
      value: 'machine-learning',
      to: { name: `machine-learning` },
    },
    {
      label: 'News Management',
      value: 'news-management',
      to: { name: `news-management` },
    },
  ],
};

const routes = [
  {
    path: '/developer',
    redirect: '/developer/debugging',
    component: Developer,
    meta: {
      title: 'Debugging',
      showSubNavigation: true,
      subNavigationHeading: 'Debugging',
      requireLogin: true,
      requireStaff: true,
      requireInternal: false,
      requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
      subNavigation: subNavigations.developer,
    },
    children: [
      {
        path: 'debugging',
        name: 'debugging',
        component: Debugging,
        props: true,
        meta: {
          title: 'Debugging | 3D Spark',
          requireLogin: true,
          requireStaff: true,
          requireInternal: true,
          requiredRole: ['sparkmanager'],
          subNavigation: subNavigations.developer,
        },
      },
      {
        path: 'news-management',
        name: 'news-management',
        component: NewsManagement,
        props: true,
        meta: {
          title: 'News Management | 3D Spark',
          requireLogin: true,
          requireStaff: true,
          requireInternal: true,
          requiredRole: ['sparkmanager'],
          subNavigation: subNavigations.developer,
        },
      },
      {
        path: 'machine-learning',
        name: 'machine-learning',
        component: MachineLearning,
        props: true,
        meta: {
          title: 'Machine Learning | 3D Spark',
          requireLogin: true,
          requireStaff: true,
          requireInternal: true,
          requiredRole: ['sparkmanager'],
          subNavigation: subNavigations.developer,
        },
      },
    ],
  },
];

export default routes;
