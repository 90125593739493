<template>
  <button
    type="button"
    class="rounded-4 text-15 relative h-40 px-12 font-medium disabled:cursor-default"
    :class="{
      '!h-32 !px-16': small,
      'bg-primary-500 text-primary-900 hover:bg-primary-600 active:bg-primary-700  disabled:bg-primary-200 disabled:text-primary-500':
        variant === 'primary',
      'bg-gray-800 text-gray-0 hover:bg-gray-900 active:bg-black disabled:bg-gray-500 disabled:text-gray-200':
        variant === 'secondary',
      'border border-gray-200 bg-white text-gray-700 hover:border-gray-400 hover:bg-gray-50 active:bg-gray-100 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-200 ':
        variant === 'outlined',
      '!h-32 bg-white text-gray-900 hover:bg-gray-50 disabled:bg-gray-0 disabled:text-gray-200': variant === 'plain',
      [`${custom}`]: !!custom,
    }"
  >
    <div :class="{ invisible: loading }">
      <slot />
    </div>

    <div v-if="loading" class="absolute inset-0 flex items-center justify-center">
      <SparkSpinner :custom="custom" class="h-[1.4rem] w-[1.4rem] border-2" />
    </div>
  </button>
</template>

<script>
import SparkSpinner from './SparkSpinner.vue';

export default {
  name: 'SparkButton',

  components: { SparkSpinner },

  props: {
    variant: { type: String, default: 'primary' }, // primary, secondary, outlined, plain
    small: { type: Boolean },
    loading: { type: Boolean },
    custom: { type: String, default: '' },
  },
};
</script>
