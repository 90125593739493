<template>
  <div>
    <SparkBadge v-if="mainProcessUidChecked" variant="success">
      <div>Main</div>
    </SparkBadge>
    <SparkBadge v-if="benchmarkProcessUidChecked" variant="warning">
      <div>Benchmark</div>
    </SparkBadge>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkBadge from '../../../../../SparkComponents/SparkBadge.vue';
export default {
  name: 'MainBenchmarkBadge',

  components: {
    SparkBadge,
  },

  props: {
    chain: { type: Object, required: true },
  },

  computed: {
    ...mapState(['part']),

    mainProcessUid() {
      return this.part.primary_process_chain_id;
    },

    benchmarkProcessUid() {
      return this.part.benchmark_process_chain_id;
    },

    mainProcessUidChecked() {
      return this.mainProcessUid == this.chain.process_chain_id;
    },

    benchmarkProcessUidChecked() {
      return this.benchmarkProcessUid == this.chain.process_chain_id;
    },
  },
};
</script>
