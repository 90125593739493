<template>
  <div v-if="validCheck">
    <div class="flex justify-between items-start w-full h-full grow my-16">
      <div class="flex w-full items-start">
        <div class="flex w-1/2 flex-col">
          <span class="text-13">{{ feasibility.name }}</span>
          <div class="text-11 text-gray-500">{{ feasibility.additionalInfo }}</div>
        </div>

        <div v-if="feasibility.minAndMaxPresent" class="text-13 text-gray-600 flex-grow">
          <div v-for="(minMaxKey, index) in ['min', 'max']" :key="index">
            <div class="flex items-center justify-start text-11">
              <div class="w-1/4">{{ minMaxText(minMaxKey) }}</div>
              <div class="ml-20 w-3/4">
                <FeasibilityValueAndPieChart
                  :percentage="toFeas(feasibility[minMaxKey]['percent_violation'])"
                  :weight="feasibility[minMaxKey]['weight']"
                />
              </div>
            </div>
          </div>
        </div>

        <span v-else class="flex items-center text-13 text-gray-600 flex-grow justify-end">
          <!-- This div is here to allign the priorities -->
          <div class="w-1/4" />

          <div class="ml-20 w-3/4">
            <FeasibilityValueAndPieChart
              :percentage="toFeas(feasibility['percent_violation'])"
              :weight="feasibility['weight']"
            />
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import FeasibilityValueAndPieChart from '@/components/PartWorkflow/Feasibility/FeasibilityValueAndPieChart.vue';

export default {
  name: 'FeasibilityCheck',

  components: { FeasibilityValueAndPieChart },

  props: {
    feasibility: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    validCheck() {
      const percent_violation = this.feasibility?.percent_violation;
      const min_percent_violation = this.feasibility?.min?.percent_violation;
      const max_percent_violation = this.feasibility?.max?.percent_violation;

      return (
        this.feasIsValid(percent_violation) ||
        this.feasIsValid(min_percent_violation) ||
        this.feasIsValid(max_percent_violation)
      );
    },
  },

  methods: {
    feasIsValid(percent_violation) {
      return percent_violation !== null && percent_violation !== undefined;
    },

    toFeas(percent_violation) {
      return Math.round(100 - percent_violation);
    },

    minMaxText(minMaxKey) {
      return minMaxKey === 'min' ? 'Minimal' : 'Maximal';
    },
  },
};
</script>
