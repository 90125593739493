<template>
  <div class="bg-white overflow-hidden border border-gray-50 rounded-8 pb-120">
    <table class="spark-table !w-full" :title="lockedForUser ? lockedTitle : ''">
      <thead class="text-gray-600 border-b border-gray-50 h-60">
        <tr class="">
          <th v-if="parent === 'materials'" />
          <th />
          <th
            v-for="col in selectedColumns"
            :id="col[0]"
            :key="col.index"
            @click="sort(col.sorting)"
            @mouseover="hover[col.title] = true"
            @mouseleave="hover[col.title] = false"
          >
            <div v-if="hover[col.title] || currentOrder === col.sorting">
              <Sorting :enabled="currentOrder === col.sorting" :reverse="currentOrderReverse" />
            </div>
            <span>{{ col.title }}</span>
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody class="text-start cursor-pointer divide-y divide-gray-50">
        <TransitionGroup name="fadening">
          <tr
            v-for="row in tableRows"
            :key="row"
            :class="{ 'bg-green-100 font-bold': parent === 'materials' && highlightedMaterials.includes(row.mat_id) }"
          >
            <td
              v-if="parent === 'materials' && row.favorite"
              key=""
              class="!text-center"
              @click.stop="removeFromFavorites(row)"
            >
              <i class="fas fa-star" title="Unset Favorite" />
            </td>
            <td v-else-if="parent === 'materials'" class="favourite !text-center" @click.stop="addToFavourites(row)">
              <i class="far fa-star" title="Set Favorite" />
            </td>
            <td v-if="row.inhouse" :class="{ '!pl-16': parent === 'machines' }" @click.stop="removeFromInHouse(row)">
              <img src="@/assets/icons/in_house_filled.svg" class="w-16 h-16" title="Unset In-House" />
            </td>
            <td v-else :class="{ '!pl-16': parent === 'machines' }" @click.stop="addToInHouse(row)">
              <img src="@/assets/icons/in_house.svg" class="w-16 h-16" title="Set In-House" />
            </td>
            <td v-for="col in selectedColumns" :key="col">
              {{ row[col['database']] }}
              <span v-if="row[col['database']] != null && col.unit" class="text-gray-500"> {{ col.unit }} </span>
            </td>
            <td class="favourite">
              <DropdownMenu
                :list-options="dropdownOptions[parent === 'materials' ? row.mat_id : row.mac_id]"
                title-icon="fas fa-ellipsis-h"
                close-click-style="always"
                @update-option="dropdownEvent($event, row)"
              />
            </td>
          </tr>
        </TransitionGroup>
      </tbody>
    </table>
    <div class="border-t items-center py-1 px-24 border-gray-50 flex justify-between">
      <div>
        <div v-if="totalNumberOfItems === 1" class="table-sub-title">{{ numberOfTableItems }}&nbsp;Part</div>
        <div v-else-if="totalNumberOfItems === 0" class="table-sub-title">{{ numberOfTableItems }}&nbsp;Parts</div>
        <div v-else class="text-13">
          <span class="text-gray-400" v-text="'Viewing'" />&nbsp;
          <span class="font-bold">{{ partCountStart }} &nbsp;-&nbsp;{{ partCountEnd }}</span>
          &nbsp;<span v-text="'of'" />&nbsp;
          <span class="font-bold">{{ totalNumberOfItems }} &nbsp;</span>
          <span class="text-gray-400" v-text="parent.charAt(0).toUpperCase() + parent.slice(1)" />
        </div>
      </div>

      <TablePagination
        :current-page="currentPage"
        :max-page-number="maxPageNumber"
        @update-current-page="updateTable"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import Sorting from '@/components/Reusable/Sorting.vue';
import TablePagination from '@/components/Reusable/TablePagination.vue';

export default {
  name: 'SparkTable',

  components: { DropdownMenu, Sorting, TablePagination },

  props: {
    tableRows: { type: Array, required: true },
    selectedColumns: { type: Array, required: true },
    currentOrderReverse: { type: Boolean },
    dropdownOptions: { type: Object, required: true },
    parent: { type: String, required: true },
    currentOrder: { type: String, required: true },
    currentPage: { type: Number, required: true },
    maxPageNumber: { type: Number, required: true },
    highlightedMaterials: { type: Array, default: () => [] },
    numberPerPage: { type: Number, required: true },
    totalCount: { type: Number, required: true },
  },

  emits: [
    'dropdown-event',
    'sort',
    'add-to-favourites',
    'remove-from-favorites',
    'add-to-in-house',
    'remove-from-in-house',
    'update-current-page',
  ],

  data() {
    return {
      hover: {},
    };
  },

  computed: {
    ...mapState('application', ['lockedTitle']),
    ...mapGetters(['lockedForUser']),

    numberOfTableItems() {
      return this.tableRows.length;
    },

    totalNumberOfItems() {
      return this.totalCount;
    },

    partCountStart() {
      return 1 + (this.currentPage - 1) * this.numberPerPage;
    },

    partCountEnd() {
      return this.partCountStart + this.tableRows.length - 1;
    },
  },

  methods: {
    dropdownEvent(event, row) {
      this.$emit('dropdown-event', { event, row });
    },

    sort(sorting) {
      this.$emit('sort', sorting);
    },

    addToFavourites(row) {
      this.$emit('add-to-favourites', row);
    },

    removeFromFavorites(row) {
      this.$emit('remove-from-favorites', row);
    },

    addToInHouse(row) {
      this.$emit('add-to-in-house', row);
    },

    removeFromInHouse(row) {
      this.$emit('remove-from-in-house', row);
    },

    updateTable(page) {
      this.$emit('update-current-page', page);
    },
  },
};
</script>

<style lang="scss" scoped>
.favorite {
  visibility: hidden;
}

tr:hover td.favorite {
  visibility: visible;
}
</style>
