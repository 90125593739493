<template>
  <div
    class="hover-animation"
    :class="[
      mouseHover ? 'hover-animation-show' : '',
      creationInProgress || creationFinished ? 'creation-animation' : '',
    ]"
    style="display: flex"
  >
    <img v-if="creationInProgress" src="@/assets/img/loading.gif" style="width: 30px; margin: 15px" />
    <i v-else-if="creationFinished" class="fas fa-check" style="margin: 20px" />
    <i v-else class="fas fa-plus" style="margin: 20px" />
  </div>
</template>

<script>
export default {
  name: 'AnimationSuggestion',

  props: {
    creationInProgress: Boolean,
    creationFinished: Boolean,
    mouseHover: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.hover-animation {
  background-color: var(--primary-color);
  width: 0px;
  transition: all 0.3s ease-out;
  visibility: hidden;
  padding: 0px;
  margin: 0px;
  color: white;
}
.hover-animation-show {
  @extend .hover-animation;

  width: 50px;
  background-color: var(--accent-color);
  visibility: visible;
  height: 100%;
}

.hover-animation-hide {
  width: 50px;
  transition: all 0.3s ease-out;
}

.creation-animation {
  @extend .hover-animation-show;
}
</style>
