<template>
  <div>
    <div v-if="dataExists" class="overall-savings">
      <div style="display: flex; justify-content: space-between; margin: 5px; align-items: center">
        <div class="spark-new-input-group">
          <select v-model="selectedOption" class="spark-new-select-input">
            <option v-for="cat in categories" :key="cat" :value="cat" :selected="selectedOption === cat ? true : false">
              {{ cat }}
            </option>
          </select>
          <label class="spark-new-select-label">Category</label>
        </div>
        <Toggle
          style="padding: 5px"
          :possible-classes="possibleClasses"
          :selected-class="selectedClass"
          @selected="changeOrder($event)"
        />
      </div>

      <table class="spark-table">
        <div v-if="noCategories">
          <p>Categories do not exist.</p>
        </div>
      </table>

      <div v-if="loading" class="loading-container">
        <Loading />
      </div>
      <div v-else class="align-horizontal">
        <Chart :chart-data="data['references']" title="Number of References" :sum="data['references_sum']" />
        <Chart :chart-data="data['cost']" title="Cost Savings in €" :sum="data['cost_sum']" />
        <Chart :chart-data="data['leadtime']" title="Lead Time Savings" unit="days" :sum="data['leadtime_sum']" />
      </div>
    </div>
    <div v-if="!dataExists" style="justify-content: start">
      <h5>Overall Savings</h5>
      <div>No Categories defined.</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Chart from './Chart.vue';

import Loading from '@/components/Reusable/Loading.vue';
import Toggle from '@/components/Reusable/Toggle.vue';

export default {
  name: 'OverallSavings',

  components: { Chart, Toggle, Loading },

  props: {
    filtering: { type: Number, required: true },
  },

  data() {
    return {
      loading: true,
      data: [[], []],
      categories: [],
      selectedOption: '',
      noCategories: false,
      chartData: null,
      dataExists: false,
      possibleClasses: {
        _size: 'Sort By Size',
        _name: 'Sort by Name',
      },

      selectedClass: {
        _size: 'Sort By Size',
      },
    };
  },

  computed: {
    ...mapState(['dashboardFilters']),
    ...mapState('application', ['axiosInstance']),

    filterSettings() {
      return this.dashboardFilters;
    },
  },

  watch: {
    filtering() {
      this.loading = true;
      this.getData();
    },

    selectedOption(newVal) {
      this.data = this.chartData[newVal];
    },

    chartData(newVal) {
      this.data = newVal[this.selectedOption];
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    changeOrder(event) {
      this.selectedClass = event;
      this.getData();
    },

    async getData() {
      let params = { ...this.filterSettings, ...this.selectedClass };
      this.axiosInstance.post('/api/v1/dashboard/overall-savings/', params).then(response => {
        this.chartData = response.data.chart;
        if (Object.keys(this.chartData).length === 0) {
          this.dataExists = false;
        } else {
          this.dataExists = true;
          this.categories = Object.keys(this.chartData);
          if (this.selectedOption == '') {
            this.selectedOption = this.categories[0];
          }
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.overall-savings {
  max-width: 100%;
  overflow: hidden;
}
.spark-table select {
  width: auto;
}
.align-horizontal {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 981px) {
  .align-horizontal {
    flex-direction: row;
  }
}
</style>
