<template>
  <svg width="40" height="40" viewBox="0 0 40 40" class="justify-center align-middle">
    <circle cx="20" cy="20" :r="radius" class="fill-none stroke-[3px] stroke-gray-100" />
    <circle
      cx="20"
      cy="20"
      :r="radius"
      class="fill-none stroke-[3px]"
      :style="{ stroke: strokeColor }"
      :stroke-dasharray="circumference"
      :stroke-dashoffset="dashOffset"
      transform="rotate(-90 20 20)"
    />
  </svg>
</template>

<script>
export default {
  name: 'PercentagePieChart',

  props: {
    percentage: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 100,
    },

    strokeColor: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      radius: 10,
    };
  },

  computed: {
    circumference() {
      return 2 * Math.PI * this.radius;
    },

    dashOffset() {
      return this.circumference * (1 - this.percentage / 100);
    },
  },
};
</script>
