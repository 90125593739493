<template>
  <FeasibilitySymbol v-if="chainisAM" :bool-feasibility="totalFeasibility(processChain)?.toString()" />
  <div v-else>-</div>
  <!--
 <div style="width: inherit; display: contents" v-if="processChain != undefined && feasibilityCheck != null">
    <tr>
      <td>Feasibility</td>
      <td style="white-space: nowrap; text-align: left"><FeasibilitySymbol :boolFeasibility="feasibilityCheck?.toString()" /></td>
      <td></td>
      <td></td>
    </tr>
    <tr v-if="!sizeCheck">
      <td style="width: 5%"></td>
      <td style="white-space: nowrap">Part Size</td>
      <td style="white-space: nowrap"><FeasibilitySymbol :boolFeasibility="sizeCheck?.toString()" /></td>
      <td @click="showFeasibiltyInCanvas('geometry')" class="show-feasibility" style="width: 5%"><i class="far fa-eye"></i></td>
      <td style="width: 5%"></td>
      <td style="width: 90%"></td>
    </tr>
    <tr v-if="!sizeCheck && !fitsMachineChk">
      <td style="width: 5%"></td>
      <td @click="showFeasibiltyInCanvas('geometry')" colspan="4" class="show-feasibility-text">
        The part is too big for the selected manufacturing machine.
      </td>
    </tr>
    <tr v-if="!sizeCheck && !feasibility?.size.max.chk">
      <td style="width: 5%"></td>
      <td @click="showFeasibiltyInCanvas('geometry')" colspan="4" class="show-feasibility-text">
        The part is too big for the selected manufacturing process.
      </td>
    </tr>
    <tr v-if="!sizeCheck && !feasibility?.size.min.chk">
      <td style="width: 5%"></td>
      <td @click="showFeasibiltyInCanvas('geometry')" colspan="4" class="show-feasibility-text">
        The part is too small for the selected manufacturing process.
      </td>
    </tr>
    <tr v-if="!wallThicknessCheck">
      <td style="width: 5%"></td>
      <td style="white-space: nowrap">Wall Thickness</td>
      <td style="white-space: nowrap"><FeasibilitySymbol :boolFeasibility="wallThicknessCheck?.toString()" /></td>
      <td @click="showFeasibiltyInCanvas('_thickness')" class="show-feasibility" style="width: 5%"><i class="far fa-eye"></i></td>
      <td style="width: 90%"></td>
    </tr>
    <tr v-if="!wallThicknessCheck">
      <td style="width: 5%"></td>
      <td @click="showFeasibiltyInCanvas('_thickness')" colspan="4" class="show-feasibility-text">
        The part has walls that fall below the minimum acceptable wall thickness of the process. The limit is
        <span>{{ feasibility?.thickness.t_lim_min }} mm</span>, but a value of
        <span>{{ roundValue(feasibility?.thickness.t_min) }} mm</span> was detected.
      </td>
    </tr>
    <tr v-if="!gapSizeCheck">
      <td style="width: 5%"></td>
      <td style="white-space: nowrap">Gap Size</td>
      <td style="white-space: nowrap"><FeasibilitySymbol :boolFeasibility="gapSizeCheck?.toString()" /></td>
      <td @click="showFeasibiltyInCanvas('_gap_size')" class="show-feasibility" style="width: 5%"><i class="far fa-eye"></i></td>
      <td style="width: 90%"></td>
    </tr>
    <tr v-if="!gapSizeCheck">
      <td style="width: 5%"></td>
      <td @click="showFeasibiltyInCanvas('_gap_size')" colspan="4" class="show-feasibility-text">
        The part has gaps that fall below the minimum acceptable gap size of the process. The limit is
        <span>{{ feasibility?.gap_size.gap_lim_min }} mm</span>, but a value of
        <span>{{ roundValue(feasibility?.gap_size.gap_min) }} mm</span> was detected.
      </td>
    </tr>
  </div>
  <div v-else>
    <tr>
      <td colspan="2" style="white-space: nowrap">Feasibility</td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </div> 
-->
</template>

<script>
import FeasibilitySymbol from '@/components/PartWorkflow/Feasibility/FeasibilitySymbol.vue';
import { totalFeasFromObj } from '@/helpers.js';

export default {
  name: 'ExternalFeasibility',

  components: { FeasibilitySymbol },

  props: {
    processChain: Object,
  },

  computed: {
    chainisAM() {
      return this.processChain.tech == 'additive_manufacturing';
    },
  },

  //   feasibility() {
  //     console.log(this.processChain?.feasibility);
  //     return this.processChain?.feasibility;
  //   },
  //   sizeCheck() {
  //     if (this.feasibility == undefined) return;
  //     let size = this.feasibility.size;
  //     return size.max.chk && size.min.chk;
  //   },
  //   fitsMachineChk() {
  //     if (this.feasibility == undefined) return;
  //     return this.feasibility.fits_mac.max.chk;
  //   },
  //   wallThicknessCheck() {
  //     if (this.feasibility == undefined) return;
  //     return this.feasibility.thickness.min.chk;
  //   },
  //   gapSizeCheck() {
  //     if (this.feasibility == undefined) return;
  //     return this.feasibility.gap_size.min.chk;
  //   },
  //   feasibilityCheck() {
  //     return this.sizeCheck && this.wallThicknessCheck && this.gapSizeCheck;
  //   },
  // },
  methods: {
    // roundValue(number, digit = 2) {
    //   return parseFloat(floatFormat(number, digit));
    // },
    // showFeasibiltyInCanvas(feasibilityString) {
    //   this.$store.commit("canvas/updateFeasibilityToggle", feasibilityString, {root: true});
    // },
    totalFeasibility(chain) {
      if (chain == undefined) return undefined;
      if (chain.feasibility == undefined) return undefined;

      let feasibility_values = {};
      if (chain.feasibility && Object.keys(chain.feasibility).length > 0) {
        feasibility_values = totalFeasFromObj(chain.feasibility);
      }
      return feasibility_values;
    },
  },
};
</script>

<style lang="scss" scoped>
.show-feasibility {
  cursor: pointer;
  color: grey;
  transition: all 0.3s ease-in;
  &:hover {
    color: black;
    transition: all 0.3s ease-in;
  }
}

.show-feasibility-text {
  cursor: pointer;
}

span {
  text-decoration: underline;
  text-decoration-color: var(--spark-red);
}
</style>
