<template>
  <div
    class="text-11 rounded-full h-24 px-12 font-medium border flex items-center"
    :class="{
      '!h-18 !px-16': small,
      'bg-primary-100 text-primary-700 border-primary-300': variant === 'success',
      'bg-red-50 text-red-600 border-red-200': variant === 'error',
      'bg-orange-100 text-orange-900 border-orange-300': variant === 'warning',
      'bg-gray-50 text-gray-600 border-200': variant === 'outlined',
      [`${custom}`]: !!custom,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SparkBadge',

  props: {
    variant: { type: String, default: 'primary' },
    small: { type: Boolean },
    custom: { type: String, default: '' },
  },
};
</script>
