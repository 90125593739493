import Batch from '../../views/Library/batch/Batch.vue';
import Library from '../../views/Library/Library.vue';
import Machines from '../../views/Library/machine/Machines.vue';
import Materials from '../../views/Library/material/Materials.vue';
import PartLibrary from '../../views/Library/part/PartLibrary.vue';
import ProcessChainSteps from '../../views/Library/steps/ProcessChainSteps.vue';

const subNavigations = {
  library: [
    {
      label: 'Parts',
      value: 'part',
      to: { name: `part-library` },
    },
    {
      label: 'Groups',
      value: 'group',
      to: { name: `group` },
    },
    {
      label: 'Materials',
      value: 'materials',
      to: { name: `material-library` },
    },
    {
      label: 'Machines',
      value: 'machines',
      to: { name: `machine-library` },
    },
    {
      label: 'Process Steps',
      value: 'process-steps',
      to: { name: `process-step-library` },
    },
  ],
};

const routes = [
  {
    path: '/library',
    redirect: '/library/part',
    component: Library,
    meta: {
      title: 'Library | 3D Spark',
      showSubNavigation: true,
      requireLogin: true,
      requireStaff: false,
      requireInternal: false,
      requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
    },
    children: [
      {
        path: 'part',
        name: 'part-library',
        component: PartLibrary,
        meta: {
          title: 'Parts | 3D Spark',
          subNavigation: subNavigations.library,
        },
      },
      {
        path: 'materials',
        name: 'material-library',
        component: Materials,
        meta: {
          title: 'Materials | 3D Spark',
          subNavigation: subNavigations.library,
        },
      },
      {
        path: 'machines',
        name: 'machine-library',
        component: Machines,
        meta: {
          title: 'Machines | 3D Spark',
          subNavigation: subNavigations.library,
        },
      },
      {
        path: 'groups',
        name: 'group',
        component: Batch,
        props: true,
        meta: {
          title: 'Batch | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
          subNavigation: subNavigations.library,
        },
      },
      {
        path: 'process-steps',
        name: 'process-step-library',
        component: ProcessChainSteps,
        meta: {
          title: 'Process Steps | 3D Spark',
          subNavigation: subNavigations.library,
        },
      },
    ],
  },
];

export default routes;
