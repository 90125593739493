<template>
  <div class="bg-white flex w-full h-full">
    <div class="w-1/3 flex justify-center bg-white pt-10">
      <div>
        <h2 class="flex justify-center pt-40">{{ title }}</h2>
        <p class="flex justify-center">{{ description }}</p>
        <div class="ml-32 w-full">
          <slot />
        </div>
        <div v-if="backToTitle != ''" class="flex justify-center">
          <router-link :to="backTo">
            <SparkButton class="mt-16" variant="secondary" @click="handleBackButtonClick">
              <div v-text="backToTitle" />
            </SparkButton>
          </router-link>
        </div>
        <div class="flex justify-center mt-16">
          <SparkButton @click="trigger()"><div v-text="'Log out'" /></SparkButton>
        </div>
      </div>
    </div>
    <img class="flex justify-center w-auto max-w-[1200px]" :src="imagePath" :alt="imageDescription" />
    <Logout :trigger-logout="triggerLogout" />
  </div>
</template>

<script>
import Logout from '../Login/Logout.vue';
import SparkButton from '../SparkComponents/SparkButton.vue';

export default {
  name: 'ErrorPage',

  components: { SparkButton, Logout },

  props: {
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    backTo: { type: String, default: '' },
    backToTitle: { type: String, default: '' },
    imageName: { type: String, default: '' },
    imageDescription: { type: String, default: '' },
  },

  data() {
    return {
      triggerLogout: false,
    };
  },

  computed: {
    origin() {
      return !import.meta.env.PROD ? 'http://localhost:3030' : document.location.origin;
    },

    imagePath() {
      return new URL(this.imageName, this.origin);
    },
  },

  methods: {
    handleBackButtonClick() {
      this.$keycloak.clearToken();
    },

    trigger() {
      this.triggerLogout = true;
    },
  },
};
</script>
