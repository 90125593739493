<template>
  <StylingColors />
</template>

<script>
import StylingColors from './StylingColors.vue';

export default {
  name: 'Styling',

  components: { StylingColors },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import '@/assets/css/animation.scss';
@import '@/assets/css/apexCharts.scss';
@import '@/assets/css/button.scss';
@import '@/assets/css/canvas.scss';
@import '@/assets/css/cards.scss';
@import '@/assets/css/collapsible.scss';
@import '@/assets/css/color_scheme.scss';
@import '@/assets/css/font.scss';
@import '@/assets/css/footer.scss';
@import '@/assets/css/input.scss';
@import '@/assets/css/libraryFilter.scss';
@import '@/assets/css/notifications.scss';
@import '@/assets/css/processStep.scss';
@import '@/assets/css/radioButton.scss';
@import '@/assets/css/scrollbar.scss';
@import '@/assets/css/select.scss';
@import '@/assets/css/sidebar.scss';
@import '@/assets/css/styling.scss';
@import '@/assets/css/table.scss';
@import '@/assets/css/whitespace.scss';
@import '@/assets/css/window.scss';
@import '@/assets/css/investigation.scss';
@import 'vue-multiselect/dist/vue-multiselect.css';

.default-info {
  color: var(--primary-color-light);
  width: 65%;
  font-size: var(--12px);
}

/* Shadows
    --------------------------------------------------------------------------------------------------*/
.spark-shadow-large {
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 10%);
}

.spark-shadow-small {
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 50%);
}

/* Placeholders -------------------------------------------------------------------------------------- */
.toggle-placeholder {
  height: 36px;
}

.toggle-input-placeholder {
  height: 54px;
}

.toggle-input-placeholder-reverse {
  margin-bottom: -54px;
}

.crop {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
