<template>
  <div>
    <div class="grid">
      <div class="card box1 flex flex-col">
        <h3 class="card-title m-8">Personal Information</h3>

        <div class="m-8">
          <img v-if="profilePic" :src="profilePic" alt="Profile Picture" class="profile-pic-big" />
          <label for="profilePic">Profile Picture</label>
        </div>

        <input
          id="profilePic"
          ref="profilePicInput"
          class="m-8"
          type="file"
          accept=".png, .jpg, .jpeg"
          @change="onPicChange"
        />
        <SparkButton v-if="profilePic" custom="!w-1/3" class="m-8" variant="secondary" @click="deleteProfilePic">
          <div v-text="'Delete Profile Picture'" />
        </SparkButton>
        <table
          class="spark-table"
          style="margin-top: 25px; margin-left: 17px; margin-right: auto; margin-bottom: 25px; width: 70%"
        >
          <tr>
            <td>User</td>
            <td>{{ user.username }}</td>
          </tr>
          <tr>
            <td>Role</td>
            <td>{{ userRoleVerbose }}</td>
          </tr>
          <tr v-if="user.is_staff === true">
            <td>Staff Member</td>
            <td>{{ user.is_staff }}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{{ user.first_name }} {{ user.last_name }}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <td>Organization</td>
            <td>{{ user.organization.name }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';

export default {
  name: 'UserAccount',

  components: { SparkButton },

  data() {
    return {
      current_pw: '',
      new_pw1: '',
      new_pw2: '',
    };
  },

  computed: {
    ...mapState(['user', 'isAuthenticated']),
    ...mapState('application', ['axiosInstanceFileUpload', 'axiosInstance']),

    profilePic() {
      return this.user.profile_pic;
    },

    userId() {
      return this.user.user_id;
    },

    userRoleVerbose() {
      let userRole = this.user.user_role;
      if (this.user.is_staff) {
        userRole += ', staff';
      } else if (this.user.is_external) {
        userRole += ', external';
      } else if (this.user.is_usermanager) {
        userRole += ', user manager';
      }
      return userRole;
    },
  },

  methods: {
    ...mapMutations(['setProfilePic']),

    async onPicChange(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }

      // Check file type
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        this.$root.notify('warning', 'Invalid file type', 'Please upload a JPEG or PNG image file.', 6000);
        return;
      }

      // Check file size
      const maxSize = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSize) {
        this.$root.notify('warning', 'File too large', 'Maximum size is 10MB.', 6000);
        return;
      }
      let formData = new FormData();
      formData.append('image', file);
      this.axiosInstanceFileUpload
        .put(`/api/v1/user/profile-pic/${this.userId}/`, formData, {})
        .then(() => {
          // Assuming the response contains the new image URL
          this.fetchProfilePic();
          this.$root.notify('success', 'Profile Picture Updated', '', 3000);
        })
        .catch(error => {
          this.$root.notify('warning', error.message, error.response.data.error_message, 6000);
        });
    },

    async fetchProfilePic() {
      try {
        const response = await this.axiosInstance.get(`/api/v1/user/profile-pic/${this.userId}/`, {
          responseType: 'blob',
        });
        if (response.data.type.startsWith('image/')) {
          const urlCreator = window.URL || window.webkitURL;
          let profilePic = urlCreator.createObjectURL(response.data);
          this.setProfilePic(profilePic);
        }
      } catch (error) {
        console.error('Failed to fetch profile picture:', error);
      }
    },

    async deleteProfilePic() {
      // Call the DELETE API endpoint
      this.axiosInstance
        .delete(`/api/v1/user/profile-pic/${this.userId}/`)
        .then(response => {
          // Check the success status
          if (response.data.status === 'success') {
            // Remove the profile picture from the UI
            this.setProfilePic('');
            this.$root.notify('success', 'Profile Picture Deleted', '', 3000);
            this.$refs.profilePicInput.value = '';
          } else {
            // Handle errors
            this.$root.notify('warning', 'Error', response.data.message, 3000);
          }
        })
        .catch(error => {
          let errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : 'An error occurred';
          this.$root.notify('warning', 'Error', errorMessage, 3000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  align-items: stretch;
  margin: var(--grid-outer-whitespace);
  grid-auto-rows: minmax(120px, auto);
  grid-template-columns: repeat(6, 1fr);
  grid: 'box1 box2';
}
.profile-pic-big {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
</style>
