/**
 * Parses a query string into an object.
 *
 * @param {string} queryString - The query string to parse (without the leading '?')
 * @returns {Object} An object representing the parsed query parameters
 */
export function parseQuery(queryString) {
  // Initialize an empty object to store the parsed parameters
  const params = {};

  // If the queryString is empty, return the empty object
  if (!queryString) {
    return params;
  }

  // Split the query string by '&' to get individual key-value pairs
  const pairs = queryString.split('&');

  for (let pair of pairs) {
    // Split each pair by '=' to separate key and value
    const [key, value] = pair.split('=');

    // Decode the key and value, and store in the params object
    // If a key appears multiple times, store the values in an array
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));

    if (params[decodedKey]) {
      // If the key already exists, convert to array or push to existing array
      if (Array.isArray(params[decodedKey])) {
        params[decodedKey].push(decodedValue);
      } else {
        params[decodedKey] = [params[decodedKey], decodedValue];
      }
    } else {
      params[decodedKey] = decodedValue;
    }
  }

  return params;
}

/**
 * Converts an object into a query string.
 *
 * @param {Object} obj - The object to stringify
 * @returns {string} A query string representation of the object (without the leading '?')
 */
export function stringifyQuery(obj) {
  // If the input is not an object or is null, return an empty string
  if (typeof obj !== 'object' || obj === null) {
    return '';
  }

  // Array to store encoded key-value pairs
  const pairs = [];

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      // Handle array values
      if (Array.isArray(value)) {
        for (let item of value) {
          pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(item));
        }
      }
      // Handle null or undefined values
      else if (value === null || value === undefined) {
        pairs.push(encodeURIComponent(key) + '=');
      }
      // Handle all other types
      else {
        pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      }
    }
  }

  // Join all pairs with '&' and return
  return pairs.join('&');
}
