<template>
  <div ref="priceSettings" class="flex p-20">
    <div class="flex flex-col w-1/3">
      <div class="text-15 font-bold">Price Configuration</div>
      <div class="text-13 text-primary-500 cursor-pointer hover:text-primary-600" @click="addPricingConfiguration">
        Add
      </div>
    </div>
    <div class="flex flex-col w-2/3 border border-gray-50 rounded-8">
      <div
        v-for="config in filteredPricingOptions"
        :key="config.uid"
        class="p-12 flex flex-col border-b border-gray-50"
      >
        <div class="flex justify-between">
          <div class="flex items-center">
            <i class="fas fa-tag mr-8 text-gray-600" />
            <div class="text-13 font-bold">{{ config.name }}</div>
            <SparkBadge v-if="config.combined_configs.length > 0" class="ml-8" variant="outlined">Combo</SparkBadge>
          </div>
          <DropdownMenu
            :list-options="dropdownOptions"
            title-icon="fas fa-ellipsis-h"
            close-click-style="always"
            @update-option="handleAction($event, config)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';

export default {
  name: 'PricingConfigurationSettings',

  components: {
    DropdownMenu,
    SparkBadge,
  },

  data() {
    return {
      dropdownOptions: [
        { text: 'Edit', value: 'edit', icon: 'fas fa-edit' },
        // { text: 'Duplicate', value: 'duplicate', icon: 'fas fa-copy' },
        { text: 'Evaluate pricing', value: 'evaluate', icon: 'fas fa-calculator' },
        { text: 'Delete', value: 'delete', icon: 'fas fa-trash' },
      ],

      priceSettings: null,
    };
  },

  computed: {
    ...mapState(['pricingOptions']),

    filteredPricingOptions() {
      return this.pricingOptions.filter(option => option.name !== '3D Spark');
    },
  },

  mounted() {
    this.priceSettings = this.$refs.priceSettings;
    this.scrollToPricingIfNeeded();
  },

  methods: {
    ...mapMutations(['fetchPricingOptionList']),
    ...mapActions(['deletePricingOption']),

    scrollToPricingIfNeeded() {
      if (this.$route.query.scrollToPricing) {
        this.$nextTick(() => {
          this.scrollToPricingSection();
        });
      }
    },

    scrollToPricingSection() {
      if (this.priceSettings) {
        this.priceSettings.scrollIntoView({ behavior: 'smooth' });
      }
    },

    addPricingConfiguration() {
      this.$router.push({ name: 'pricing' });
    },

    editConfig(config) {
      const pricingType = config.combined_configs.length > 0 ? 'pricing-combined' : 'pricing-single';
      this.$router.push({
        name: pricingType,
        params: { configUid: config.uid },
        query: { edit: true },
      });
    },

    // duplicateConfig(config) {
    //   const pricingType = config.combined_configs.length > 0 ? 'pricing-combined' : 'pricing-single';
    //   this.$router.push({ name: pricingType, params: { configUid: config.uid }, query: { duplicate: true } });
    // },

    evaluateConfig(config) {
      const pricingType = config.combined_configs.length > 0 ? 'pricing-combined' : 'pricing-single';
      this.$router.push({ name: pricingType, params: { configUid: config.uid }, query: { evaluate: true } });
    },

    deleteConfig(config) {
      try {
        this.deletePricingOption(config.uid);
        this.$root.notify('success', 'Pricing option deleted', 6000);
      } catch (error) {
        this.$root.notify('error', 'Error deleting pricing option', 6000);
      }
    },

    handleAction(option, config) {
      switch (option.value) {
        case 'edit':
          this.editConfig(config);
          break;
        case 'delete':
          this.deleteConfig(config);
          break;
        //case 'duplicate':
        //  this.duplicateConfig(config);
        //  break;
        case 'evaluate':
          this.evaluateConfig(config);
          break;
      }
    },
  },
};
</script>
