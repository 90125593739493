<template>
  <div>
    <CostProfileSettings />
    <PricingConfigurationSettings class="border-t border-gray-50" />
  </div>
</template>

<script>
import CostProfileSettings from './components/CostProfileSettings.vue';
import PricingConfigurationSettings from './components/PricingConfigurationSettings.vue';

export default {
  name: 'CostSettings',

  components: { CostProfileSettings, PricingConfigurationSettings },
};
</script>
