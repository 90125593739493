<template>
  <div class="flex flex-col items-center">
    <img src="@/assets/img/loading.gif" class="h-24" />
    <div>Calibration ongoing...</div>
    <div class="text-gray-600 text-13">This shouldn't take more than 30s</div>
  </div>
</template>

<script>
export default {
  name: 'CalibrationRunning',
};
</script>
