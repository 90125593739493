<template>
  <div>
    <div class="border-t border-gray-50">
      <div class="py-20 flex text-15 items-center justify-between">
        <div class="flex items-center">
          <div class="font-bold mr-16">Overall Feasibility</div>
          <div
            v-if="showConfigureButton && !feasibilityChecksOutdated"
            class="text-primary-700 text-13 hover:text-primary-500 hover:cursor-pointer"
            @click="emitShowWeightsModal"
          >
            Configure
          </div>
        </div>
        <div v-if="!feasibilityChecksOutdated"><FeasibilityValueAndPieChart :percentage="feasibilityScoring" /></div>
      </div>
    </div>

    <div v-if="!activeProcessChainFeasibilitiesIsLegit">No Feasibilities evaluated for this process chain.</div>
    <div
      v-else-if="feasibilityChecksOutdated"
      class="text-red-500 bg-red-50 rounded-4 m-8 text-13 hover:text-black hover:cursor-pointer"
      @click="updateFeasibilityChecksInBackend"
    >
      <div class="px-20 flex items-center justify-between">
        Feasibility checks are outdated.
        <br />Click here to update them.
      </div>
    </div>
    <div v-else>
      <ul>
        <li v-for="[feasKey, feasValue] in Object.entries(parsedFeasibilities)" :key="feasKey">
          <FeasibilityCheck class="border-b border-gray-50 mb-8" :feasibility="feasValue" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeasibilityCheck from '@/components/PartWorkflow/Feasibility/FeasibilityCheck.vue';
import parseFeasDict from '@/components/PartWorkflow/Feasibility/FeasibilityParsing.js';
import FeasibilityValueAndPieChart from '@/components/PartWorkflow/Feasibility/FeasibilityValueAndPieChart.vue';

export default {
  name: 'FeasibilityChecksList',

  components: { FeasibilityCheck, FeasibilityValueAndPieChart },

  props: {
    activeProcessChain: {
      type: Object,
      required: true,
    },

    showConfigureButton: {
      type: Boolean,
    },
  },

  emits: ['show-weights-modal'],

  computed: {
    ...mapState('application', ['axiosInstance']),

    parsedFeasibilities() {
      return parseFeasDict(this.activeProcessChainFeasibilities);
    },

    activeProcessChainFeasibilities() {
      return this.activeProcessChain.feasibility;
    },

    activeProcessChainFeasibilitiesIsLegit() {
      return Object.keys(this.activeProcessChainFeasibilities).length > 0;
    },

    feasibilityScoring() {
      let scoring = Math.round(100 - this.activeProcessChainFeasibilities.redesign_required_weighted);
      return scoring;
    },

    feasibilityChecksOutdated() {
      return this.activeProcessChainFeasibilities?.feasibility_checks_outdated === true;
    },
  },

  methods: {
    ...mapMutations(['updateSingleProcessChain']),

    emitShowWeightsModal() {
      this.$emit('show-weights-modal');
    },

    updateFeasibilityChecksInBackend() {
      if (this.activeProcessChain.process_chain_id) {
        this.axiosInstance
          .put(`api/v1/feasibility-weights/${this.activeProcessChain.process_chain_id}/`, {})
          .then(response => {
            this.updateSingleProcessChain(response.data);
          })
          .catch(error => {
            this.$root.notifyError('Recomputing Feasibilities', error);
          });
      }
    },
  },
};
</script>
