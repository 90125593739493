<template>
  <div style="margin: 50px">
    <h1>Machine Learning Platform</h1>
    Number of assets: {{ numberOfNewAssets }}
    <div style="max-width: fit-content">
      <Datepicker
        v-model="startDate"
        :format-locale="de"
        format="dd.MM.Y"
        auto-apply
        show-now-button
        text-input
        :text-input-options="textInputOptions"
        :enable-time-picker="false"
        :clearable="false"
        :disabled="!isActive"
      />
    </div>
    <div>
      <button :disabled="!isActive" class="spark-btn" @click="download()">Download Assets</button>
    </div>
    <div style="max-width: fit-content">
      <input
        type="file"
        accept=".zip"
        name="cu-input-upload-folder"
        @input="uploadLabels"
        @click="resetFileUpload"
        @drop="resetFileUpload"
      />
    </div>
    <div>Number of labeled drawings: {{ numberOfLabeledDrawings }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { de } from 'date-fns/locale';
import { ref } from 'vue';

export default {
  name: 'MachineLearningControl',

  components: {
    Datepicker,
  },

  data() {
    return {
      de: de,
      textInputOptions: ref({
        enterSubmit: true,
        format: 'dd.MM.yyyy',
      }),

      startDate: this.$dayjs().subtract(1, 'months').utc().format(),
      numberOfNewAssets: 0,
      numberOfLabeledDrawings: 0,
      isActive: true,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    startDate: {
      handler() {
        this.getNumberOfUploadedAssets();
      },

      immediate: true,
    },
  },

  mounted() {
    this.getNumberOfLabeledDrawings();
  },

  methods: {
    download() {
      this.isActive = false;
      this.getAssets();
    },

    async getAssets() {
      await this.axiosInstance
        .get(`/api/v1/ml/assets/`, {
          responseType: 'blob',
          params: { timestamp_filter: this.startDate, download: true },
        })
        .then(response => {
          let pretty_date = this.startDate.toLocaleString().substring(0, 9).replace('.', '_');
          saveAs(response.data, 'ml_drawings_' + pretty_date + '.zip');
          this.isActive = true;
        });
    },

    async getNumberOfUploadedAssets() {
      await this.axiosInstance
        .get(`/api/v1/ml/assets/`, {
          params: { timestamp_filter: this.startDate, download: false },
        })
        .then(response => {
          this.numberOfNewAssets = response.data.number_of_new_assets;
        });
      //
    },

    async uploadLabels(event) {
      let formData = new FormData();
      formData.append('file', event.target.files[0]);
      await this.axiosInstance
        .post('/api/v1/ml/labels/', formData)
        .then(response => {
          this.numberOfLabeledDrawings = response.data.number_of_labeled_drawings;
        })
        .catch(error => {
          console.error(JSON.stringify(error));
        });
    },

    async getNumberOfLabeledDrawings() {
      await this.axiosInstance
        .get('/api/v1/ml/labels/')
        .then(response => {
          this.numberOfLabeledDrawings = response.data.number_of_labeled_drawings;
        })
        .catch(error => {
          console.error(JSON.stringify(error));
        });
    },

    resetFileUpload(event) {
      /* Reset the input field so that even multiple uploads of the same file in a row are detected as inputs
          and the upload event is handeled properly */
      event.target.value = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
