<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #default>
      <div class="order-confirmation p-48 border border-gray-100 rounded-8 flex flex-col gap-24 bg-white">
        <i class="fas fa-check-circle text-48 text-primary-500" />
        <div class="text-19 font-semibold" v-text="'Request submitted'" />
        <div class="text-15 tr">
          <ul>
            <li v-for="(line, index) in confirmationTextLines" :key="index" v-text="line" />
          </ul>
        </div>

        <div class="flex gap-16">
          <SparkButton variant="secondary" @click="createNewOrder">
            <div class="text-13" v-text="'Create new Request'" />
          </SparkButton>
          <SparkButton variant="plain" custom="!h-40" @click="goToOrders">
            <div class="flex font-semibold items-center justify-between gap-8">
              <div class="text-13" v-text="'Requests'" />
              <i class="fas fa-arrow-right text-13" />
            </div>
          </SparkButton>
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'OrderConfirmationModal',

  components: { SparkModal, SparkButton },

  props: { show: Boolean },

  emits: ['close'],

  computed: {
    ...mapState(['user']),

    confirmationTextLines() {
      if (this.user.organization?.prp_checkout_info) {
        return this.user.organization?.prp_checkout_info.split('\n');
      } else {
        return [];
      }
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    createNewOrder() {
      this.$router.push({ name: 'prp' });
      this.close();
    },

    goToOrders() {
      this.$router.push({ name: 'part-library' });
      this.close();
    },
  },
};
</script>
