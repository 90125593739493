<template>
  <div>
    <ExternalTemplateSelection />
    <ExternalButton />
  </div>
</template>

<script>
import ExternalButton from './ExternalButton.vue';
import ExternalTemplateSelection from './ExternalTemplateSelection.vue';

export default {
  name: 'ExternalViewFrame',

  components: {
    ExternalTemplateSelection,
    ExternalButton,
  },
};
</script>
