<template>
  <div>
    <div
      style="display: flex; flex-direction: row; align-items: center; width: 100%; cursor: pointer"
      @mouseenter="mouseHover = true"
      @mouseleave="mouseHover = false"
      @click="selected"
    >
      <AnimationSuggestion
        :creation-in-progress="creationInProgress"
        :creation-finished="creationFinished"
        :mouse-hover="mouseHover"
      />
      <div class="title-row">
        <span class="profile-name truncate w-[500]" v-text="profile.name" />
        <div style="display: flex; flex-direction: row; align-items: center">
          <img class="title-row-icon" src="@/assets/icons/comparison/profile.svg" />
          <img v-if="profile.restriction == 'owner'" class="title-row-icon" src="@/assets/icons/user.svg" />
          <img
            v-if="profile.restriction == 'organization'"
            class="title-row-icon"
            src="@/assets/icons/organization.svg"
          />
          <img v-if="profile.restriction == 'external'" class="title-row-icon" src="@/assets/icons/external.svg" />
          <div v-if="profile.uid == defaultAnalysisProfile"><Badge title="Default" selected-style="mint" /></div>
          <div v-if="profile.restriction == 'external'"><Badge title="PRP" selected-style="purple" /></div>
        </div>
      </div>
    </div>
    <details style="font-size: var(--9px); margin: 0px 20px; cursor: pointer" title="Click to show">
      <summary>{{ numberOfTemplates }} Processes</summary>
      <draggable :list="templateList" item-key="order_index" @end="reindexProfileTemplates">
        <template #item="{ element }">
          <div class="draggable-item">
            <div class="drag-icon ml-10">&#x2630;</div>
            <TemplateDetails :template="element" />
            <SparkButton variant="outlined" class="delete-button ml-12" @click="deleteFromProfile(element)">
              <div class="fas fa-trash text-11" />
            </SparkButton>
          </div>
        </template>
      </draggable>
    </details>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapMutations, mapState } from 'vuex';

import SparkButton from '../../../SparkComponents/SparkButton.vue';

import AnimationSuggestion from './AnimationSuggestion.vue';
import TemplateDetails from './TemplateDetails.vue';

import Badge from '@/components/Reusable/Badge.vue';
export default {
  name: 'ProfileRow',

  components: { Badge, AnimationSuggestion, TemplateDetails, draggable, SparkButton },

  props: {
    profile: Object,

    loadingUid: { type: String, default: '' },
  },

  emits: ['selected-profile'],

  data() {
    return {
      mouseHover: false,
      creationInProgress: false,
      creationFinished: false,
      selectable: true,
    };
  },

  computed: {
    ...mapState(['user', 'part']),
    ...mapState('application', ['axiosInstance']),
    defaultAnalysisProfile() {
      return this.user.default_analysis_profile;
    },

    numberOfTemplates() {
      return Object.keys(this.profile.process_chain_templates).length;
    },

    templateList() {
      let templates = Object.values(this.profile.process_chain_templates);
      return templates.sort((a, b) => a.order_index - b.order_index);
    },

    cadUploadedOrCadless() {
      return this.part.part_id != 0 && (this.part.basename != '' || this.part.is_cadless);
    },
  },

  watch: {
    loadingUid(newVal, oldVal) {
      if (oldVal == this.profile.uid && newVal == '') {
        this.creationInProgress = false;
        this.creationFinished = true;
        setTimeout(() => {
          this.creationFinished = false;
        }, 3000);
      } else if (newVal == 'abort') {
        this.creationInProgress = false;
        this.selectable = true;
      }
    },
  },

  methods: {
    ...mapMutations(['updateTemplatesAndProfiles']),
    selected() {
      if (this.selectable) {
        this.$emit('selected-profile');
        if (this.cadUploadedOrCadless) {
          this.creationInProgress = true;
        }
      }
      this.selectable = false;
      setTimeout(() => {
        this.selectable = true;
      }, 3000);
    },

    reindexProfileTemplates() {
      let formData = {
        template_order: {},
      };
      this.templateList.forEach((template, index) => {
        template.order_index = index;
        formData.template_order[index] = template.uid;
      });

      // put new order
      this.axiosInstance.put(`/api/v1/analysis-profile/${this.profile.uid}/`, formData).then(response => {
        console.debug(response.data); // Updated profile
      });
    },

    deleteFromProfile(element) {
      this.axiosInstance.delete(`api/v1/analysis-profile-list/${element.uid}/`).then(() => {
        this.updateTemplatesAndProfiles(new Date());
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: var(--12px);
  color: var(--spark-lightgrey);
}

.value {
  font-size: var(--12px);
}

.subtext {
  font-size: var(--9px);
  color: var(--spark-lightgrey);
  margin-left: 17px;
}

.draggable-item {
  display: flex;
  align-items: center; /* Align icon and template details horizontally */
}
</style>
