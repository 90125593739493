<template>
  <div id="issue" class="text-black" click-outside @paste="handlePaste">
    <form class="issueContainer" @submit.prevent="sendIssue()">
      <div>
        <i style="float: right; cursor: pointer" class="fas fa-times" @click="close()" />
      </div>
      <div class="text-11">Title</div>
      <SparkInput v-model="title" custom="!h-24" name="title" />
      <div class="description">Description</div>
      <textarea v-model="message" class="!focus:border-primary-500" maxlength="250" required />
      <p class="input-counter">{{ message.length }} / {{ 250 - message.length }}</p>
      <div class="text-11">Issue Type</div>
      <div
        v-for="issueType in issueTypes"
        :key="issueType"
        :class="selectedIssueType == issueType ? 'active' : ''"
        class="issue-type"
        @click="selectedIssueType = issueType"
      >
        {{ issueType }}
      </div>
      <div class="description">Upload or Paste Additional File (e.g. Screenshot)</div>
      <input
        ref="fileref"
        type="file"
        accept=".stl,.stp,.step,.jpg,.jpeg,.png,.tif,.tiff,.pdf,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.ppt,.pptx,.doc,.docx,.mp4,.mpg,.mpeg"
        @input="uploadFiles"
      />
      <div v-for="(preview, index) in previews" :key="index">
        <img v-if="preview.type === 'image'" :src="preview.url" alt="Uploaded Thumbnail" class="thumbnail-preview" />
        <video v-if="preview.type === 'video'" :src="preview.url" class="video-preview" controls />
        <p
          style="cursor: pointer; text-decoration: underline; font-size: var(--10px); margin-left: 3px"
          @click="removeFile(index)"
        >
          Delete File
        </p>
      </div>
      <p
        :style="{ visibility: files ? 'visible' : 'hidden' }"
        style="cursor: pointer; text-decoration: underline; font-size: var(--10px); margin-left: 3px"
        @click="deleteFile()"
      >
        Delete File
      </p>

      <SparkButton small variant="secondary" type="submit"> <div v-text="'Send'" /> </SparkButton>
      <SparkButton small variant="outlined" class="ml-8" @click="close()"><div v-text="'Cancel'" /></SparkButton>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '../SparkComponents/SparkButton.vue';
import SparkInput from '../SparkComponents/SparkInput.vue';

export default {
  name: 'Issue',

  components: { SparkButton, SparkInput },

  emits: ['close'],

  data() {
    return {
      title: '',
      message: '',
      selectedIssueType: 'Improvement', // "Bug", "Improvement", "New Feature", "Other"
      issueTypes: ['Bug', 'Improvement', 'New Feature', 'Other'],
      files: null,
      clipboardFiles: [],
      previews: [],
    };
  },

  computed: {
    ...mapState(['user', 'part']),
    ...mapState('application', ['axiosInstanceFileUpload']),

    currentRouteName() {
      return this.$route.name;
    },
  },

  methods: {
    deleteFile() {
      this.files = null;
      this.clipboardFile = null;
      this.imagePreview = null;
      this.$refs.fileref.value = '';
    },

    uploadFiles(event) {
      const uploadedFiles = event.target.files;

      for (let file of uploadedFiles) {
        if (file.size > 100 * 1024 * 1024) {
          // 100MB
          this.$root.notify('error', 'File too large', 'Please select a file smaller than 100MB.', 3000);
          continue;
        }
        this.clipboardFiles.push(file);
        const fileType = file.type;
        if (fileType.startsWith('image/')) {
          this.previews.push({ type: 'image', url: URL.createObjectURL(file) });
        } else if (fileType.startsWith('video/')) {
          this.previews.push({ type: 'video', url: URL.createObjectURL(file) });
        }
      }
    },

    attach_file(file, issue_id) {
      let formData = new FormData();
      formData.append('file', file);
      this.axiosInstanceFileUpload.post(`/api/v1/issue-attachment/${issue_id}/`, formData).catch(error => {
        console.log(JSON.stringify(error));
      });
    },

    close() {
      this.$emit('close');
    },

    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const items = clipboardData.items;

      for (let i = 0; i < items.length; i++) {
        const type = items[i].type;
        const blob = items[i].getAsFile();

        if (!blob) continue;

        if (blob.size > 100 * 1024 * 1024) {
          // 100MB
          this.$root.notify('error', 'File too large', 'Please use a file smaller than 100MB.', 3000);
          return;
        }

        if (type.indexOf('image') !== -1) {
          const file = new File([blob], `clipboard-image-${Date.now()}.png`, { type: blob.type });
          this.clipboardFiles.push(file);
          this.previews.push({ type: 'image', url: URL.createObjectURL(file) });
        } else if (type.indexOf('video') !== -1) {
          const file = new File([blob], `clipboard-video-${Date.now()}.mp4`, { type: blob.type });
          this.clipboardFiles.push(file);
          this.previews.push({ type: 'video', url: URL.createObjectURL(file) });
        }
      }
    },

    async sendIssue() {
      this.$emit('close');

      let formData = new FormData();

      formData.append('ticket_origin', 'Support Feature');
      formData.append('title', this.title);
      formData.append('message', this.message);
      formData.append('username', this.user);
      formData.append('part_id', this.part.part_id);
      formData.append('view', this.currentRouteName);
      formData.append('issue_type', this.selectedIssueType);
      formData.append('current_url', window.location.href);

      await this.axiosInstanceFileUpload
        .post('/api/v1/issue/', formData)
        .then(response => {
          this.$root.notify(
            'success',
            'Message sent',
            'Thank you for your message! We will process it as soon as possible.',
            4000
          );
          this.title = '';
          this.message = '';

          let allFiles = [...(this.files ? Array.from(this.files) : []), ...this.clipboardFiles];

          for (let file of allFiles) {
            this.attach_file(file, response.data.issue_id);
          }

          this.previews = [];
          this.clipboardFiles = [];
          this.files = null;
          if (this.$refs.fileref) {
            this.$refs.fileref.value = '';
          }
        })
        .catch(error => {
          console.log(error);
          this.$root.notify('error', 'Sorry, something went wrong.', 3000);
        });
    },

    removeFile(index) {
      this.clipboardFiles.splice(index, 1);
      this.previews.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.issueContainer {
  position: fixed;
  min-height: 450px;
  max-height: 70vh;
  width: 300px;
  right: 40px;
  top: 70px;
  border-radius: var(--border-radius);
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 10%);
  background: white;
  overflow-y: auto;
  z-index: 100;
  padding: 15px;
}

textarea {
  font-size: var(--11px);
  height: 150px;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 4px;
  resize: none;
  background-color: white;
  width: 100%;

  &:hover {
    border: 2px solid rgb(196, 196, 196);
    border-radius: 4px;
  }
}

.active {
  color: white;
  background-color: var(--accent-color);
}

.issue-type {
  display: inline-block;
  padding: 1px 3px;
  font-size: var(--12px);
  border: 0.15em solid var(--light-color);
  margin: 2px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    border: 0.15em solid grey;
  }
}

input {
  margin: unset;
  border: 1px solid rgb(196, 196, 196);
  resize: none;
  font-size: var(--11px);

  &:hover {
    margin: -1px;
    border: 2px solid rgb(196, 196, 196);
  }
}

.description {
  font-size: var(--10px);
  margin-top: 10px;
}

.input-counter {
  font-size: var(--10px);
  float: right;
  margin: 0;
}

.spark-btn,
.spark-cancel-btn {
  margin: unset;
  margin-right: 5px;
  font-size: var(--12px);

  &:hover {
    cursor: pointer;
  }
}

.thumbnail-preview {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
  border: 1px solid #ccc;
}
.video-preview {
  max-width: 200px;
  max-height: 100px;
  margin-top: 10px;
  border: 1px solid #ccc;
}
</style>
