<template>
  <th
    :title="hoverTitle"
    class="hover: cursor-pointer rounded-8 border-l border-b border-gray-50 whitespace-nowrap px-4"
    @click="sortingActivated"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div v-if="sortby != ''" class="w-8 inline-block mr-4">
      <Sorting v-show="hover || activeSorting" :enabled="activeSorting" :ascending="ascending" />
    </div>
    {{ title }}
  </th>
</template>

<script>
import Sorting from '@/components/Reusable/Sorting.vue';

export default {
  name: 'InvestigationTitle',

  components: { Sorting },

  props: {
    title: {
      type: String,
      default: '',
    },

    hoverTitle: {
      type: String,
      default: '',
    },

    sortby: { type: String, default: '' },
    activeSorting: {
      type: Boolean,
    },

    ascending: {
      type: Boolean,
    },
  },

  emits: ['sort'],

  data() {
    return {
      hover: false,
    };
  },

  methods: {
    sortingActivated() {
      this.$emit('sort', { sortby: this.sortby, ascending: !this.ascending });
    },
  },
};
</script>
