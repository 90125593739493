<template>
  <div class="filter-element">
    <div class="filter-label">Group</div>

    <div class="filtering">
      <VueMultiselect
        v-model="activeBatchId"
        :options="batchOptions"
        close-on-select
        :limit="10"
        :limit-text="limitText"
        placeholder="Pick some"
        label="name"
      />
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'BatchFilter',

  components: {
    VueMultiselect,
  },

  props: {
    reset: { type: Number, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      activeBatchId: '',
      batchOptions: [],
    };
  },

  computed: {
    ...mapState(['partLibraryFilters']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    reload: {
      handler() {
        this.getBatches();
      },

      immediate: true,
    },

    reset: {
      handler() {
        this.activeBatchId = '';
      },

      immediate: true,
    },

    activeBatchId: {
      handler() {
        this.$emit('selected', { batches: this.activeBatchId });
      },
    },
  },

  mounted() {
    this.getBatches();

    let selected = this.partLibraryFilters.batch;
    if (selected != '' && selected != undefined) {
      this.activeBatchId = selected;
    }
  },

  methods: {
    ...mapMutations(['updateActiveBatch', 'resetFilter', 'disablePartLibraryFilter']),

    // async sendBatchId(activeBatchId) {
    //   this.$emit('selected', activeBatchId);

    //   if (activeBatchId === '') {
    //     this.updateActiveBatch({});
    //     this.resetFilter({ batch: 'batch' });
    //   } else {
    //     let selectedBatch = this.batches.find(batch => batch.batch_pk === activeBatchId);
    //     if (selectedBatch) {
    //       await this.axiosInstance
    //         .get(`/api/v1/batch/${activeBatchId}/`)
    //         .then(response => {
    //           selectedBatch.parts = response.data.parts;
    //           this.updateActiveBatch(selectedBatch);
    //         })
    //         .catch(error => {
    //           console.log(error);
    //         });
    //     }
    //   }
    // },

    // remove() {
    //   this.disablePartLibraryFilter({
    //     [this.label]: this.component,
    //   });
    //   this.activeBatchId = '';
    //   this.$emit('selected', activeBatchId);
    //   this.resetFilter({ batch: 'batch' });
    // },

    // clear() {
    //   this.activeBatchId = '';
    //   this.$emit('selected', activeBatchId);
    //   this.resetFilter({ batch: 'batch' });
    // },

    async getBatches() {
      // Make API call to get a list of all batches from the user
      await this.axiosInstance
        .get('/api/v1/batch-dropdown/')
        .then(response => {
          this.batchOptions = response.data.map(entry => ({
            batch_pk: entry.batch_pk,
            name: entry.batch_name,
            prp_order_uid: entry.prp_order_uid,
          }));
        })
        .catch(error => {
          console.log(error);
        });
    },

    limitText() {
      return `...`;
    },
  },
};
</script>

<style scoped lang="scss">
select {
  padding-left: 5px;
}
</style>
