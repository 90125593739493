export function getCalibrationState() {
  return {
    selectedParts: {},
    selectedMode: 'unset',
    actualPage: 'CalibrationMode',
    transformedPartsData: [],
    selectedProcessChain: {},
    selectedParameters: {},
    calibrationConfigUid: '',
    calibrationResult: {},
    calibrationInstancesResult: {},
    instancesCreated: false,
    loadCalibration: false,
  };
}
export default getCalibrationState();
