export default {
  install: app => {
    app.config.globalProperties.$getUser = (keycloak, axios, store) => getUser(keycloak, axios, store);
  },
};

async function getUser(keycloak, axios, store) {
  let currentToken = 'Bearer ' + keycloak.token;
  const formData = { token: currentToken };
  let user = {};
  if (axios) {
    await axios
      .get('api/v1/user/', formData)
      .then(response => {
        user = response.data;
        store.commit('setUser', user);
        store.commit('application/setInitializationStatus', 'INITIALIZED', { root: true });
      })
      .catch(error => {
        if (error.response) {
          console.log(JSON.stringify(error));
        }
      });

    try {
      const response = await axios.get(`/api/v1/user/profile-pic/${user?.user_id}/`, {
        responseType: 'blob',
      });
      if (response.data.type.startsWith('image/')) {
        const urlCreator = window.URL || window.webkitURL;
        const profilePic = urlCreator.createObjectURL(response.data);
        store.commit('setProfilePic', profilePic);
      }
    } catch (error) {
      console.error('Failed to fetch profile picture:', error);
    }
  } else {
    console.debug('Axios unavailable.');
  }
}
