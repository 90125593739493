<template>
  <div class="flex flex-col gap-8 mt-16">
    <div class="flex justify-between">
      {{ title }}
      <div v-if="title != 'In-Process'" class="cursor-pointer text-primary-500" @click="$emit('add', title)">Add</div>
    </div>
    <div>
      <draggable class="grid grid-cols-1 gap-16" tag="div" :list="stepList" item-key="order" @end="reindexSteps">
        <template #item="{ element }">
          <div class="flex flex-row items-start">
            <div v-if="stepList.length > 1" class="mr-10 hover:cursor-grab">&#x2630;</div>
            <ProcessStep :property="property" :step="Object.values(element)[0]" :level="level" :color="color" />
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';

import ProcessStep from '@/components/PartWorkflow/ProcessChain/ProcessStep.vue';

export default {
  name: 'ProcessStepGroup',

  components: { ProcessStep, draggable },

  props: {
    title: { type: String, default: '' },
    color: { type: String, default: '' },
    steps: { type: Object, default: () => {} },
    level: { type: Object, default: () => {} },
    property: { type: String, default: '' },
  },

  emits: ['add'],

  computed: {
    ...mapState('application', ['axiosInstance']),

    stepList() {
      if (this.steps) {
        let steps = Object.values(this.steps).map(step => {
          return { [step.process_step_id]: step };
        });
        const sortedSteps = steps.sort((a, b) => {
          const orderA = Object.values(a)[0].order;
          const orderB = Object.values(b)[0].order;
          return orderA - orderB;
        });

        return sortedSteps;
      }

      return [];
    },
  },

  methods: {
    reindexSteps() {
      let formData = {
        process_step_order: {},
      };
      this.stepList.forEach((step, index) => {
        Object.values(step)[0].order = index;
        formData.process_step_order[index] = Object.values(step)[0].process_step_id;
      });
      this.axiosInstance.put(`api/v1/process-step-list/`, formData).then(() => {});
    },
  },
};
</script>
