<template>
  <div
    class="canvas-button"
    :class="[warning ? 'warning' : '', clicked ? 'accent-border' : '', hasOptions ? 'has-options' : '', addedClass]"
    @click="buttonClicked"
  >
    <img :src="icon" class="canvas-btn h-24" :class="styles" :title="text" />
  </div>
</template>

<script>
export default {
  name: 'CanvasButton',

  props: {
    icon: {
      type: String,
      default: '',
    },

    styles: {
      type: String,
      default: '',
    },

    text: {
      type: String,
      default: '',
    },

    addedClass: {
      type: String,
      default: '',
    },

    clicked: Boolean,
    warning: Boolean,
    hasOptions: Boolean,
  },

  emits: ['button-clicked'],

  methods: {
    buttonClicked() {
      this.$emit('button-clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-button {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 8px;
  align-items: center;
  position: relative;
  border: 1px solid white;
  overflow: hidden;
}

.accent-border-hover {
  &:hover {
    cursor: pointer;
    border: 1px solid var(--accent-color);
  }
}
p {
  margin: 0px;
  padding: 5px 0px 0px 0px;
  font-size: 0.45em;
  text-align: center;
}
</style>
