<template>
  <div class="mb-150">
    <MachineTable />
  </div>
</template>

<script>
import MachineTable from './components/MachineTable.vue';
export default {
  name: 'Machines',

  components: { MachineTable },
};
</script>
