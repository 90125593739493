<template>
  <div>
    <SparkButton :disabled="disabled" variant="secondary" @click="openChangeRoleModal">
      <i class="fas fa-exchange-alt mr-8" />
      Change Role
    </SparkButton>
    <ChangeRoleModal
      :role-options="roleOptions"
      :show="showChangeRoleModal"
      :current-role="changeFormData?.oldRole"
      @close="closeChangeRoleModal"
      @update-role="changeRoles"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '../../../components/SparkComponents/SparkButton.vue';

import ChangeRoleModal from './modals/ChangeRoleModal.vue';

export default {
  name: 'ChangeRoles',

  components: { ChangeRoleModal, SparkButton },

  props: {
    triggerUpdateRole: { type: Number, default: 0 },
    changeFormData: { type: Object, default: () => {} },
    disabled: { type: Boolean },
  },

  emits: ['reload'],

  data() {
    return {
      showChangeRoleModal: false,
      roleOptions: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['popup', 'roles']),
  },

  watch: {
    triggerUpdateRole(value) {
      if (value) {
        this.openChangeRoleModal();
      }
    },

    roles: {
      handler(value) {
        this.roleOptions = Object.keys(value).map(key => {
          return { label: value[key], value: key };
        });
      },

      immediate: true,
    },
  },

  methods: {
    changeRoles(role) {
      let formData = { newRole: role, userId: this.changeFormData['userId'] };
      this.axiosInstance.patch('api/v1/user-management/update-user-role/', formData).then(response => {
        this.$root.notify('success', response.data.message);
        this.$emit('reload');
      });
    },

    openChangeRoleModal() {
      this.showChangeRoleModal = true;
    },

    closeChangeRoleModal() {
      this.showChangeRoleModal = false;
    },
  },
};
</script>
