export function getSupplierState() {
  return {
    isSupplier: false,
    suppliers: [],
    publicSuppliers: [],
    customersSuppliers: [],
    users: [],
    selectedSuppliers: [],
    selectedSalesRep: {},
  };
}
export default getSupplierState();
