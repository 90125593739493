export default function getCurrentDateWithTime() {
  let today = new Date();

  let currentDay = String(today.getDate());
  if (currentDay.length == 1) {
    currentDay = '0' + currentDay;
  }
  let currentMonth = String(today.getMonth() + 1);
  if (currentMonth.length == 1) {
    currentMonth = '0' + currentMonth;
  }
  let currentDate = currentDay + '-' + currentMonth + '-' + today.getFullYear();

  let currentHour = String(today.getHours());
  if (currentHour.length == 1) {
    currentHour = '0' + currentHour;
  }
  let currentMinute = String(today.getMinutes());
  if (currentMinute.length == 1) {
    currentMinute = '0' + currentMinute;
  }
  let currentSecond = String(today.getSeconds());
  if (currentSecond.length == 1) {
    currentSecond = '0' + currentSecond;
  }
  let currentTime = currentHour + ':' + currentMinute + ':' + currentSecond;

  let dateWithTime = currentDate + ' ' + currentTime;
  return { dateWithTime };
}
