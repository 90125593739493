<template>
  <div class="cont flex flex-col items-center justify-center gap-12">
    <img :src="FileUploadSVG" width="28" height="28" alt="file-upload" />
    <div class="text-gray-1000 font-semibold" v-text="'Drag and drop or Browse'" />
    <div class="text-gray-300 text-11" v-text="'STL, PDF, JPEG, PNG, TIFF'" />
    <div class="flex gap-16 mt-20 text-gray-1000 text-11 items-center font-semibold">
      <div class="flex flex-col gap-8 items-center">
        <img :src="PackageSVG" width="28" height="28" alt="map" />
        <span v-text="'CAD Files'" />
      </div>
      <div class="flex flex-col gap-8 items-center">
        <img :src="MapSVG" width="28" height="28" alt="package" />
        <span v-text="'Technical Drawings'" />
      </div>
      <div class="flex flex-col gap-8 items-center">
        <img :src="FileSVG" width="28" height="28" alt="file-upload" />
        <span v-text="'Other Files'" />
      </div>
    </div>
  </div>
</template>

<script>
import FileSVG from '../../assets/icons/file.svg';
import MapSVG from '../../assets/icons/map.svg';
import PackageSVG from '../../assets/icons/package.svg';
import FileUploadSVG from '../../assets/icons/prp/file-upload.svg';

export default {
  name: 'FileDropDisplay',

  computed: {
    FileUploadSVG() {
      return FileUploadSVG;
    },

    PackageSVG() {
      return PackageSVG;
    },

    MapSVG() {
      return MapSVG;
    },

    FileSVG() {
      return FileSVG;
    },
  },
};
</script>
