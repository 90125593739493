<template>
  <div class="app-loader h-screen">
    <component :is="layout" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DefaultLayout from '../layouts/default/DefaultLayout.vue';
export default {
  name: 'AppLoader',

  components: { DefaultLayout },

  data() {
    return {
      resizeObserver: {},
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['initializationStatus']),

    layout() {
      return this.$route.meta.layout || DefaultLayout;
    },
  },

  watch: {
    $route() {
      this.scrollToTop();
    },
  },

  mounted() {
    this.setInnerHeight();
  },

  methods: {
    setInnerHeight() {
      this.resizeObserver = new ResizeObserver(() => {
        document.documentElement.style.setProperty('window-inner-height', `${window.innerHeight}px`);
      });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
