import PRPLayout from '../../layouts/prp/PRPLayout.vue';
import PartRequestPortal from '../../views/PartRequestPortal/PartRequestPortal.vue';

const routes = [
  {
    path: '/prp',
    name: 'prp',
    component: PartRequestPortal,
    meta: {
      title: 'PRP | 3D Spark',
      showSubNavigation: true,
      subNavigationHeading: 'Part Request Portal',
      showFooter: true,
      layout: PRPLayout,
      requireLogin: true,
      requireStaff: false,
      requireInternal: false,
      requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
    },
  },
];

export default routes;
