<template>
  <div class="page-log-in">
    <div class="columns">
      <div class="column is-4 is-offset-4">
        <div class="login-container">
          <div class="login-header">
            <img src="@/assets/img/Logo_Original.svg" alt="3D Spark Logo" class="header-img" />
          </div>
          <div class="login-body">
            <h1>Redirecting</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import posthog from 'posthog-js';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Login',

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),

    userId() {
      return this.user.user_id;
    },
  },

  beforeMount() {
    this.submitForm();
  },

  methods: {
    ...mapMutations([
      'setProfilePic',
      'updateLibraryUserSettings',
      'updateMaterialUserSettings',
      'updateMachineUserSettings',
    ]),

    async submitForm() {
      this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
      await this.getUserSettings();
      await this.fetchProfilePic();
      this.setLocalStorageEntries();
      const excludedPaths = ['/login', '/new-user', '/unlicensed', '/401', '/something-went-wrong', '/404'];
      const queryPath = this.$route.query.r;

      if (queryPath && !excludedPaths.includes(queryPath)) {
        this.$router.push(this.$route.query.r);
      } else {
        this.$router.push(this.$route.query.to || '/start');
      }

      posthog.identify(this.generateRandomUID(), {
        organization: this.user.organization.name,
        organization_role: this.user.organization_role,
        usage_type: this.user.usage_type,
        is_staff: this.user.is_staff,
      });

      posthog.capture('login');
    },

    setLocalStorageEntries() {
      let showPopup = localStorage.getItem('create-processchain-popup');
      if (showPopup == null) {
        localStorage.setItem('create-processchain-popup', true);
      }
    },

    async getUserSettings() {
      this.$keycloak.updateToken(0);
      await this.axiosInstance
        .get('/api/v1/user-settings?update_user=true')
        .then(response => {
          this.updateLibraryUserSettings(response.data.part_library_columns);
          this.updateMaterialUserSettings(response.data.material_library_columns);
          this.updateMachineUserSettings(response.data.machine_library_columns);
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    async fetchProfilePic() {
      this.axiosInstance
        .get(`/api/v1/user/profile-pic/${this.userId}/`, {
          responseType: 'blob',
        })
        .then(response => {
          if (response.data.type.startsWith('image/')) {
            const urlCreator = window.URL || window.webkitURL;
            let profilePic = urlCreator.createObjectURL(response.data);
            this.setProfilePic(profilePic);
          }
        })
        .catch(error => {
          console.error('Failed to fetch profile picture:', error);
        });
    },

    generateRandomUID() {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
  },
};
</script>

<style lang="css">
.login-container {
  border-top: 3px solid;
  border-top-color: #97ab12;
  margin: 20px auto auto auto;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 25%;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}

.login-container .login-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: var(--primary-color);
}

.login-container .login-header .header-img {
  width: 100%;
  padding: 20px;
  margin: auto;
}

.login-container .login-body {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #ffffff;
  color: var(--primary-color);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 10px;
}

input {
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 3px;
}
</style>
