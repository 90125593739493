<template>
  <ComboTextCategories />
</template>

<script>
import ComboTextCategories from './components/ComboTextCategories.vue';

export default {
  name: 'CategorySettings',

  components: {
    ComboTextCategories,
  },
};
</script>
