<template>
  <div>
    <OrthographicCamera
      ref="coreCamera"
      :left="cameraLeft"
      :right="cameraRight"
      :bottom="cameraBottom"
      :top="cameraTop"
      :near="cameraNear"
      :far="cameraFar"
    />
  </div>
</template>

<script>
import { OrthographicCamera } from 'troisjs';

const cameraDist = 1000000;

export default {
  name: 'CanvasCamera',

  components: {
    OrthographicCamera,
  },

  props: {
    clientWidth: Number(0),
    clientHeight: Number(0),
  },

  emits: ['scene-width-in-mm-updated'],

  data() {
    return {
      cameraLeft: -cameraDist,
      cameraRight: cameraDist,
      cameraBottom: -cameraDist,
      cameraTop: cameraDist,
      cameraNear: -cameraDist,
      cameraFar: cameraDist,
    };
  },

  computed: {
    // needed by parent component --> disable the unused-properties warning
    // eslint-disable-next-line vue/no-unused-properties
    camera() {
      return this.$refs.coreCamera.camera;
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    handleWindowResize() {
      const aspectCanvas = this.clientWidth / this.clientHeight;
      const requiredAspectOfScene = 1; // defined as width / height

      const widthInMM = this.cameraRight - this.cameraLeft; // keep old width
      const heightInMM = (widthInMM * requiredAspectOfScene) / aspectCanvas;

      this.cameraTop = heightInMM / 2;
      this.cameraBottom = -heightInMM / 2;

      this.$emit('scene-width-in-mm-updated', widthInMM);
    },
  },
};
</script>
