<template>
  <div class="relative w-full m-0">
    <div
      class="w-full px-0 rounded-8 h-80 border-2 border-gray-200 bg-white flex flex-wrap items-center gap-1"
      @click="focusInput"
    >
      <template v-for="(word, index) in words" :key="index">
        <span
          class="relative flex justify-center items-center rounded-4 mt-8 mx-4 group min-w-24 min-h-24"
          :class="[
            (word.length > 1 && !isOperatorOrConditional(word)) || isNumber(word)
              ? 'bg-black text-white py-4 px-4'
              : 'bg-primary-50 px-4 py-4',
          ]"
          @mouseover="hoverIndex = index"
          @mouseleave="hoverIndex = -1"
        >
          <span v-text="word" />
          <button
            v-if="hoverIndex === index"
            type="button"
            class="absolute -top-4 -right-4 font-bold bg-white w-20 h-20 rounded-full px-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
            @click.stop="removeWord(index)"
          >
            <span><i class="fas fa-trash text-red-500" /></span>
          </button>
        </span>
      </template>
      <input
        ref="input"
        v-model="currentInput"
        :name="label.toLowerCase()"
        type="text"
        class="self-end outline-none border-0 flex-grow min-w-[4em] !m-0 p-0 mt-8 rounded-b-4 h-40 appearance-none focus:outline-none focus:ring-0 focus:border-0 peer"
        placeholder="Type to search..."
        @input="onInput"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keydown.enter="onEnter"
        @keydown.backspace="onBackspace"
      />
    </div>
    <div
      v-if="showSuggestions && currentInput.length > 0"
      class="absolute w-full z-10 rounded-4 mt-1 bg-white border border-gray-200 rounded-md shadow-lg"
    >
      <div
        v-for="(suggestion, index) in filteredSuggestions"
        :key="suggestion.variable"
        class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        :class="[{ 'bg-gray-100': index === selectedIndex }]"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion.verbose }}
      </div>
    </div>
    <span v-if="errorMessage" class="text-9 text-red-500" v-text="errorMessage" />
  </div>
</template>

<script>
export default {
  name: 'CustomExpression',

  props: {
    options: { type: Array, required: true },
    label: { type: String, default: '' },
    reset: { type: Number, default: 0 },
    incomingWords: { type: Array, default: () => [] },
    incomingActualValue: { type: String, default: '' },
  },

  emits: ['input'],

  data() {
    return {
      words: [],
      currentInput: '',
      actualValue: '',
      showSuggestions: false,
      filteredSuggestions: [],
      selectedIndex: -1,
      hoverIndex: -1,
      errorMessage: '',
    };
  },

  watch: {
    actualValue() {
      this.$emit('input', this.actualValue);
    },

    reset(value) {
      if (value) {
        this.words = [];
        this.currentInput = '';
        this.actualValue = '';
        this.displayValue = '';
      }
    },

    incomingWords(value) {
      this.words = value;
    },

    incomingActualValue(value) {
      this.actualValue = value;
    },

    currentInput: {
      handler(value) {
        if (!value) {
          this.errorMessage = '';
        }
      },

      immediate: true,
    },
  },

  mounted() {
    this.words = this.incomingWords;
    this.actualValue = this.incomingActualValue;
  },

  methods: {
    focusInput() {
      this.$refs.input.focus();
    },

    currentWordValid() {
      const currentWord = this.currentInput.trim().toLowerCase();
      const matchingOption = this.options.find(option => option.verbose.toLowerCase() === currentWord);
      if (matchingOption) {
        return matchingOption.verbose;
      }
      const isNumber = !isNaN(parseFloat(currentWord)) && isFinite(currentWord);
      return isNumber ? currentWord : '';
    },

    onInput() {
      this.filterSuggestions();
      this.showSuggestions = this.filteredSuggestions.length > 0 && this.currentInput.length > 0;
      this.selectedIndex = -1;
      this.updateActualValue();
    },

    onBackspace() {
      if (!this.currentInput && this.words.length) {
        this.currentInput = this.words.pop() || '';
        this.updateActualValue();
      }
    },

    filterSuggestions() {
      if (this.currentInput.length === 0) {
        this.filteredSuggestions = [];
        return;
      }
      const inputLower = this.currentInput.toLowerCase();
      this.filteredSuggestions = this.options.filter(item => item.verbose.toLowerCase().startsWith(inputLower));
    },

    onArrowDown() {
      if (this.showSuggestions) {
        this.selectedIndex = (this.selectedIndex + 1) % this.filteredSuggestions.length;
      }
    },

    onArrowUp() {
      if (this.showSuggestions) {
        this.selectedIndex =
          (this.selectedIndex - 1 + this.filteredSuggestions.length) % this.filteredSuggestions.length;
      }
    },

    onEnter() {
      if (this.showSuggestions && this.selectedIndex !== -1) {
        this.selectSuggestion(this.filteredSuggestions[this.selectedIndex]);
        this.errorMessage = '';
      } else if (this.currentInput.trim()) {
        const validWord = this.currentWordValid();

        if (validWord) {
          this.words.push(validWord.trim());
          this.currentInput = '';
          this.updateActualValue();
          this.errorMessage = '';
        } else {
          this.errorMessage = 'Invalid parameter';
          return;
        }
      }
    },

    selectSuggestion(suggestion) {
      this.words.push(suggestion.verbose);
      this.currentInput = '';
      this.updateActualValue();
      this.showSuggestions = false;

      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },

    removeWord(index) {
      this.words.splice(index, 1);
      this.updateActualValue();
    },

    updateActualValue() {
      const allWords = [...this.words, this.currentInput].filter(word => word.trim() !== '');
      const actualWords = allWords.map(word => {
        const match = this.options.find(item => item.verbose.toLowerCase() === word.toLowerCase());
        return match ? match.variable : word;
      });
      this.actualValue = actualWords.join(' ');
      this.$emit('input', this.actualValue);
    },

    isNumber(value) {
      return !isNaN(value);
    },

    isOperatorOrConditional(value) {
      return ['+', '-', '*', '/', '(', ')', '>', '<', '>=', '<=', '==', '!=', '&&', '||'].includes(value);
    },
  },
};
</script>
