<template>
  <div class="min-w-0">
    <div>
      <div class="table-title">
        <h5>Machines</h5>
        <DropdownMenu
          class="!ml-auto !mr-0"
          :list-options="tableDropdownOptions"
          title-icon="fas fa-ellipsis-h"
          close-click-style="always"
          @update-option="tableAction($event)"
        />
      </div>
      <img v-if="loading" class="h-20 w-20" src="@/assets/img/loading.gif" />
      <div v-else-if="machines.length > 0" class="w-full overflow-x-scroll !h-[550px]">
        <table class="spark-table">
          <thead>
            <tr>
              <th
                v-for="col in selectedMachineLibraryColumns"
                :id="col[0]"
                :key="col.index"
                @click="sort(col.sorting)"
                @mouseover="hover[col.title] = true"
                @mouseleave="hover[col.title] = false"
              >
                <div class="w-12 inline-block">
                  <Sorting
                    v-show="hover[col.title] || currentOrder === col.sorting"
                    :enabled="currentOrder === col.sorting"
                    :reverse="currentOrderReverse"
                  />
                </div>
                {{ col.title }}
                <span class="resize-handle" />
              </th>
              <th>&nbsp;<span class="resize-handle" /></th>
            </tr>
          </thead>
          <tbody>
            <TransitionGroup name="fadening">
              <!-- Iterate over parts -->
              <tr v-for="machine in machines" :key="machine" :class="machineClasses(machine)">
                <td v-for="col in selectedMachineLibraryColumns" :key="col" @click="showWarning()">
                  {{ machine[col['database']] }}
                </td>
                <td class="favourite">
                  <DropdownMenu
                    :list-options="dropdownOptions[machine.mac_id]"
                    title-icon="fas fa-ellipsis-h"
                    close-click-style="always"
                    @update-option="machineEvent($event, machine)"
                  />
                </td>
              </tr>
            </TransitionGroup>
          </tbody>
        </table>
      </div>
      <div v-else class="m-auto" >
      <p>        
        Go to the <span class="font-bold cursor-pointer" @click="gotoLibrary"><i class="fas fa-arrow-right"/> Machine Library</span> to select
        <ul class=" list-none">
          <li class="flex gap-8 items-center" >
            <i class="far fa-star" title="Set Favorite"/>
            <span v-text="'Favorite Machines'" />
            </li>
          <li class="flex gap-8 items-center">
            <img class="!w-16 !h-16 inline-block" src="@/assets/icons/in_house.svg"  />
            <span v-text="'In-House Machines'" />
          </li>
        </ul>
      </p>
    </div>
    </div>
    <TablePagination :current-page="currentPage" :max-page-number="numberOfPages" @update-current-page="updateTable" />
    <CustomMachine
      :machinedata="machineData"
      :type="type"
      @get-machines="getMachines()"
      @highlight-machine="highlightNewMachine"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import CustomMachine from '@/components/PartWorkflow/Machine/CustomMachine.vue';
import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import Sorting from '@/components/Reusable/Sorting.vue';
import TablePagination from '@/components/Reusable/TablePagination.vue';

export default {
  name: 'MachineTable',

  components: {
    TablePagination,
    Sorting,
    DropdownMenu,
    CustomMachine,
  },

  data() {
    return {
      machines: [],
      highlightedMachines: [],
      currentPage: undefined,
      numberOfPages: 1,
      filterParams: {},
      loading: true,
      currentOrder: '',
      currentOrderReverse: true,
      type: 'view',
      machineData: {},
      defaultDropdownOptions: [
        { text: 'View Details', value: 'view', icon: 'fas fa-glasses', disabled: false },
        { text: 'Add Similar Machine', value: 'add', icon: 'fas fa-plus', disabled: false },
        { text: 'Edit Machine', value: 'edit', icon: 'fas fa-pen', disabled: true },
        { text: 'Delete Machine', value: 'delete', icon: 'fas fa-trash', disabled: true },
      ],

      ownDropdownOptions: [
        { text: 'View Details', value: 'view', icon: 'fas fa-glasses', disabled: false },
        { text: 'Add Similar Machine', value: 'add', icon: 'fas fa-plus', disabled: false },
        { text: 'Edit Machine', value: 'edit', icon: 'fas fa-pen', disabled: false },
        { text: 'Delete Machine', value: 'delete', icon: 'fas fa-trash', disabled: false },
      ],

      dropdownOptions: {},

      tableDropdownOptions: [{ text: 'Edit Columns', value: 'editColumns', icon: '', disabled: false }],
      hover: {},
    };
  },

  computed: {
    ...mapState(['user', 'processes', 'investigationDetails', 'part', 'popup']),
    ...mapState('application', ['axiosInstance']),

    username() {
      return this.user.username;
    },

    processChains() {
      return this.part.process_chains;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    selectedMacId() {
      let actualProcessUid = this.activeInvestigationDetails.uid;
      let actualProcessChain = this.processChains[actualProcessUid];
      return actualProcessChain.mac_id;
    },

    machineLibraryColumns: {
      get() {
        let cols = this.user.user_settings.machine_library_columns;
        // if (this.user.is_external == true) {
        //   cols = Object.fromEntries(Object.entries(cols).filter(([key, value]) => value.external == true));
        // }
        return cols;
      },

      set(columns) {
        this.setMachineUserSettings();
        this.updateMachineUserSettings(columns);
      },
    },

    // 2. get selected Part Library Columns
    selectedMachineLibraryColumns() {
      let shown = [];
      for (let col in this.machineLibraryColumns) {
        if (this.machineLibraryColumns[col].selected === true) {
          shown.push(this.machineLibraryColumns[col]);
        }
      }
      // sort selected Columns
      shown.sort((a, b) => {
        return a.index - b.index;
      });
      return shown;
    },

    machineClasses() {
      return machine => {
        return {
          'highlighted-machine': this.highlightedMachines.includes(machine.mac_id),
          'selected-machine': this.selectedMacId === machine.mac_id,
        };
      };
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.triggerPopup('');
          this.setMachineUserSettings();
          this.updateMachineUserSettings(this.machineLibraryColumns);
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  mounted() {
    this.getMachines();
  },

  methods: {
    ...mapMutations(['updateAllMachines', 'updateMachineUserSettings', 'updateFilteredMachines', 'triggerPopup']),

    showWarning() {
      this.$root.notify('warning', 'Machines', 'At the moment Machine is selected through Material', 6000);
    },

    gotoLibrary() {
      this.$router.push({ name: 'machine-library' });
    },

    tableAction() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Edit Columns',
        selectOptions: this.machineLibraryColumns,
        type: 'multi',
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    calculateDropownOptions() {
      this.machines.forEach(machine => {
        if (machine.owner_username == this.username) {
          this.dropdownOptions[machine.mac_id] = this.ownDropdownOptions;
        } else {
          this.dropdownOptions[machine.mac_id] = this.defaultDropdownOptions;
        }
      });
    },

    getMachines() {
      this.loading = true;
      let url = `/api/v1/machine-list/?page=${this.currentPage}`;

      let params = { ...this.filterParams };
      params.inhouse = true;
      if (this.currentPage === undefined) {
        url = `/api/v1/machine-list/${this.selectedMacId}/`;
      }
      this.axiosInstance
        .get(url, {
          params,
        })
        .then(response => {
          this.machines = response.data.results;
          this.addPrcAcronym(this.machines);
          this.currentPage = Number(response.data.page_number);
          this.calculateDropownOptions();
          this.numberOfPages = response.data.num_pages;
          this.loading = false;

          // load also machines for machine table new
          this.getAllMachines();
        });
    },

    addPrcAcronym(obj) {
      Object.keys(obj).forEach(key => {
        let prc_uid = obj[key]['prc_uid'];
        obj[key]['prc_acronym'] = this.user.organization.process_synonyms[prc_uid].prc_acronym;
      });
    },

    async getAllMachines() {
      await this.axiosInstance.get('api/v1/machines-all/').then(response => {
        let allMachines = response.data;
        let filteredMachines = Object.keys(allMachines).map(key => {
          return {
            key: Number(key),
            value: allMachines[key].mac_id,
            text: allMachines[key].mac_name + ' | ' + allMachines[key].oem_name,
            prc_uid: allMachines[key].prc_uid,
          };
        });
        filteredMachines = { ...filteredMachines };
        this.updateAllMachines(filteredMachines);
        this.updateFilteredMachines(filteredMachines);
      });
    },

    async getMachine(machine) {
      await this.axiosInstance.get(`/api/v1/machine/${machine.mac_id}`).then(response => {
        this.machineData = { ...response.data };
        this.machineData['prc_acronym'] =
          this.user.organization.process_synonyms[this.machineData['prc_uid']].prc_acronym;
      });
    },

    async deleteMachine(machine) {
      await this.axiosInstance
        .delete(`api/v1/machine/${machine.mac_id}/`)
        .then(response => {
          this.getMachines();
          this.$root.notify('success', response.data, 3000);
        })
        .catch(error => {
          if (error.response) {
            this.$root.notify('warning', error.response.data.error_message, 3000);
          }
        });
    },

    machineEvent(event, machine) {
      this.type = event.value;
      if (event.value == 'add') {
        this.getMachine(machine);
      }
      if (event.value == 'delete') {
        if (machine.owner_username != this.username) {
          this.$root.notify('warning', 'This Machine cannot be deletetd. You can only delete your own machine.', 3000);
        } else {
          this.deleteMachine(machine);
        }
      }
      if (event.value == 'edit') {
        if (machine.owner_username != this.username) {
          this.$root.notify('warning', 'This Machine cannot be edited. You can only delete your own machine.', 3000);
        } else {
          this.getMachine(machine);
        }
      }
      if (event.value == 'view') {
        this.getMachine(machine);
      }
    },

    highlightNewMachine(mac_id) {
      this.highlightedMachines.push(mac_id);
      setTimeout(() => {
        const index = this.highlightedMachines.indexOf(mac_id);
        if (index > -1) {
          this.highlightedMachines.splice(index, 1);
        }
      }, 60000); // Remove highlight after 1 minute
    },

    updateTable(pageNumber) {
      this.currentPage = pageNumber;
      this.getMachines();
    },

    sort(str) {
      this.currentOrder = str;
      this.currentOrderReverse = !this.currentOrderReverse;
      this.filterParams['order_by'] = this.currentOrder;
      this.filterParams['order_reverse'] = this.currentOrderReverse;
      this.getMachines();
    },

    async setMachineUserSettings() {
      this.axiosInstance.put('/api/v1/user-settings/', {
        machine_library_columns: this.machineLibraryColumns,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
th {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.highlighted-machine {
  background-color: rgb(232, 255, 230);
  font-weight: bold;
}

.selected-machine {
  background-color: var(--light-color);
  font-weight: bold;
}

.table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
