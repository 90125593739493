<template>
  <div class="flex flex-col gap-16">
    <div class="bg-white rounded-8 border-solid border border-gray-100 p-10 max-w-full flex justify-end">
      <div class="flex ml-0 mr-auto">
        <SparkButton
          small
          class="ml-4"
          variant="outlined"
          :style="currentRouteName === 'Comparison' ? 'color: white; background-color: gray' : ''"
          @click="$router.push({ name: 'Comparison', params: { part_id: part.part_id } })"
          >My Production</SparkButton
        >
        <SparkButton
          small
          class="ml-4"
          variant="outlined"
          :style="currentRouteName === 'Supplier' ? 'color: white; background-color: gray' : ''"
          @click="$router.push({ name: 'Supplier', params: { part_id: part.part_id } })"
          >My Suppliers</SparkButton
        >
      </div>
      <AnalysisButton v-if="currentRouteName === 'Comparison'" />
      <SparkButton v-if="currentRouteName === 'Supplier'" variant="secondary" small @click="triggerRFQViaMail()"
        ><i class="fa-regular fa-paper-plane pr-8" />Send RFQ</SparkButton
      >
      <Profile v-if="currentRouteName === 'Comparison'" class="ml-8" />
    </div>
    <div
      v-if="currentRouteName === 'Supplier'"
      class="bg-white rounded-8 border-solid border border-gray-100 p-10 max-w-full flex"
    >
      <div class="flex gap-32">
        <SparkSelect
          v-model="selectedMaterial"
          custom="!w-[300px] !mx-auto"
          label="Material"
          :options="materialOptions"
          name="selection"
        />
        <SparkSelect
          v-model="selectedPrcAcronym"
          custom="!w-[300px] !mx-auto"
          label="Technology"
          :options="prcAcronymOptions"
          name="selection"
        />
        <SparkButton class="self-center mt-20" small variant="secondary" @click="sendData"
          ><i class="fa-regular fa-filter pr-8" />Filter</SparkButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import SparkButton from '../../SparkComponents/SparkButton.vue';

import AnalysisButton from '@/components/PartWorkflow/Comparison/components/AnalysisButton.vue';
import Profile from '@/components/PartWorkflow/Comparison/components/Profile.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'PartActionButtons',

  components: { AnalysisButton, SparkButton, Profile, SparkSelect },

  data() {
    return {
      prcAcronymOptions: [],
      selectedPrcAcronym: '',
      materialOptions: [],
      selectedMaterial: '',
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance']),

    currentRouteName() {
      return this.$route.name;
    },
  },

  mounted() {
    this.fetchMaterials();
    this.fetchPrcAcronyms();
  },

  methods: {
    ...mapMutations(['sendTriggerRFQ']),
    ...mapActions('supplier', ['requestPriceData']),

    triggerRFQViaMail() {
      this.sendTriggerRFQ();
    },

    async fetchMaterials() {
      const prefiltered = true; // prefilter by material level 2
      const distinct = true; // distinct mat_names
      await this.axiosInstance
        .get(`api/v1/default-materials/?prefiltered=${prefiltered}&distinct=${distinct}`)
        .then(response => {
          this.materialOptions = response.data.map(entry => ({
            value: entry.mat_id,
            label: entry.mat_name,
          }));
          this.materialOptions.unshift({ label: ' ', value: null }); // No Material
        })
        .catch(error => {
          console.log(error);
        });
    },

    async fetchPrcAcronyms() {
      const prefiltered = true; // prefilter by template prc
      await this.axiosInstance
        .get(`api/v1/supplier-prc-acronyms/?prefiltered=${prefiltered}`)
        .then(response => {
          this.prcAcronymOptions = response.data.map(entry => ({
            value: entry.prc_acronym,
            label: entry.prc_name,
          }));
          this.prcAcronymOptions.unshift({ label: ' ', value: null }); // No Process
        })
        .catch(error => {
          console.log(error);
        });
    },

    async sendData() {
      const data = {
        mat_id: this.selectedMaterial,
        prc_acronym: this.selectedPrcAcronym,
      };

      this.requestPriceData({ partId: this.part.part_id, data });
    },
  },
};
</script>
