<template>
  <div class="filter-element">
    <div class="filter-label">Part Validation</div>
    <div class="filtering">
      <Toggle :possible-classes="levels" :selected-class="selectedLevel" @selected="selectLevel($event)" />
    </div>
  </div>
</template>

<script>
import Toggle from '../Toggle.vue';

export default {
  name: 'ValidationFilter',

  components: {
    Toggle,
  },

  props: {
    reset: { type: Number, required: true },
    parent: { type: String, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      levels: {
        all: 'All',
        validated: 'Validated',
        not_validated: 'Not Validated',
        unset: 'Unset',
      },

      selectedLevel: {
        validated: 'Validated',
      },
    };
  },

  watch: {
    reset: {
      handler() {
        this.selectedLevel = { all: 'All' };
        this.selectLevel(this.selectedLevel);
      },
    },
  },

  mounted() {
    if (this.parent === 'library') {
      const filter = JSON.parse(sessionStorage.getItem('libraryFilter'));
      const partStat = filter?.part_stat;
      const level = {};
      if (this.levels[partStat]) {
        level[partStat] = this.levels[partStat];
        this.selectedLevel = level;
      } else {
        this.selectedLevel = { all: 'All' };
      }
    }
    if (this.parent === 'dashboard') {
      this.selectedLevel = {
        validated: 'Validated',
      };
    }
    this.selectLevel(this.selectedLevel);
  },

  methods: {
    selectLevel(event) {
      this.selectedLevel = event;
      let partStat = Object.keys(this.selectedLevel)[0];
      this.$emit('selected', { part_stat: partStat });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-element {
  width: 100%;
}

.filtering {
  padding: 5px 0px;
}
</style>
