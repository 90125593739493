<template>
  <span v-if="value != null" class="box" :style="{ 'background-color': color }">{{ formattedValue }} %</span>
  <span v-else>-</span>
</template>

<script>
export default {
  name: 'TrafficLight',

  props: {
    value: Number,
  },

  computed: {
    formattedValue() {
      return this.$formatZeroDecimalPlaces(this.value);
    },

    color() {
      if (this.formattedValue === 0) {
        return '#cc3232';
      } else if (this.formattedValue < 80) {
        return '#e7b416';
      } else {
        return '#2dc937';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  border: 1px solid var(--light-color);
  padding: 0px 5px 0px 0px;
  border-radius: 5px;
  width: 40px;
  justify-content: end;
  font-size: var(--11px);
}
</style>
