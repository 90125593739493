<template>
  <div v-if="chartData" style="display: inline-block">
    <p>{{ chartTitle }}</p>
    <apexchart
      style="display: inline-block; padding-right: 50px"
      type="bar"
      :options="options"
      :series="series"
      width="80%"
      height="300"
    />
  </div>
</template>

<script>
export default {
  name: 'ComparisonChart',

  props: {
    chartData: Object,
    chartTitle: String,
  },

  data() {
    return {
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'bar',
          fontFamily: 'Poppins, Arial, sans-serif',
        },

        colors: ['#00293c', '#005364'],
        stroke: {
          show: false,
        },

        dataLabels: {
          enabled: false,
        },

        yaxis: {
          labels: {
            show: true,
            tickamount: 5,
            formatter: function (value) {
              if (value < Infinity && value != null) {
                if (value > 10000) {
                  return (value / 1000).toFixed(0) + ' k';
                } else return value.toFixed(0);
              }
            },
          },
        },

        xaxis: {
          labels: {
            show: true,
          },
        },

        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },

        tooltip: {
          shared: true,
          intersect: false,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            let data = w.globals.initialSeries[seriesIndex].data;
            return (
              "<div style='padding:4px;'>" +
              "<table class='spark-table'>" +
              "<tr><td style='text-align:right;'>" +
              data[0].x +
              '</td><td>' +
              parseFloat(data[0].y).toFixed(0) +
              '</br>' +
              "</tr><tr><td style='text-align:right;'>" +
              data[1].x +
              '</td><td>' +
              parseFloat(data[1].y).toFixed(0) +
              '</br>' +
              '</td></tr></table>' +
              '</div>'
            );
          },
        },
      },
    };
  },

  watch: {
    chartData: {
      handler(newVal) {
        if (newVal !== null) {
          this.series = [this.chartData];
        }
      },

      immediate: true,
    },
  },

  mounted() {},
};
</script>

// goals: [ // { // name: "Average Cost Saved in your Organization in €", // value: newVal.avgCostSaved, // strokeWidth: 50, // strokeHeight: 5,
// strokeLineCap: "round", // strokeColor: "#96d575", // }, // ],
