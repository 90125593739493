<template>
  <div style="text-align: center">
    <h2>Silent SSO Check</h2>
    <h6>This is not the page you're looking for.</h6>
    <div>
      <router-link to="/login">
        <button class="spark-btn">To Login</button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    parent.postMessage(location.href, location.origin);
    // https://github.com/keycloak/keycloak-documentation/blob/main/securing_apps/topics/oidc/javascript-adapter.adoc
  },
};
</script>
