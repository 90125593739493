<template>
  <div>
    <i class="icon-button fas fa-redo" @click="startAnalysis()" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AnalysisButton',

  props: {
    checkedParts: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(['activeBatch']),
    ...mapState('application', ['axiosInstance']),
  },

  methods: {
    errorCatcher(error) {
      // This will handle error notifications of axios calls from the analyse button
      let errorResponse = error.response ? error.response.data : error.message;

      if (error.response && error.response.status === 409) {
        this.$root.notify(
          'error',
          'Mandatory Fields Error',
          'Mandatory fields are None (Volume and Bounding Box)',
          6000
        );
      } else if (
        errorResponse.includes('Request failed with status code 403') ||
        errorResponse.includes('Invalid token specified')
      ) {
        this.$root.notify('error', 'Authorization Error', 'Please try to logout and login again.', 6000);
      } else {
        this.$root.notify('error', 'Error', JSON.stringify(errorResponse), 6000);
      }
      console.log(JSON.stringify(error));
    },

    async startAnalysis() {
      const selectedPartIds = Object.keys(this.checkedParts).filter(key => this.checkedParts[key]);
      let formData = JSON.stringify({
        parts: selectedPartIds,
        queue: 'batch_queue',
      });
      try {
        await this.axiosInstance.post(`api/v1/analysis/`, formData, {
          headers: { 'Content-Type': 'application/json' },
        });
        this.$root.notify('success', 'Batch Analysis', 'The batch analysis successfully started.', 3000);
      } catch (error) {
        console.log(error);
        this.errorCatcher(error);
      }
    },
  },
};
</script>
