<template>
  <div class="part-frame">
    <PartNavigation class="navi" />
    <HeaderPartInformation v-if="currentRoute === 'Comparison' || currentRoute === 'Supplier'" class="header" />
    <PartActionButtons v-if="currentRoute === 'Comparison' || currentRoute === 'Supplier'" class="actions" />
    <div class="left-container">
      <router-view />
    </div>
    <div v-if="!isCadLess" class="right-container">
      <TroisCanvas class="canvas" display-user-actions />
      <Info />
    </div>
    <div v-else class="right-container">
      <CadLessCanvas />
    </div>
    <PartFooter class="footer" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import CadLessCanvas from '@/components/PartWorkflow/Canvas/CadLessCanvas.vue';
import TroisCanvas from '@/components/PartWorkflow/Canvas/TroisCanvas.vue';
import PartFooter from '@/components/PartWorkflow/Footer/PartFooter.vue';
import HeaderPartInformation from '@/components/PartWorkflow/Header/HeaderPartInformation.vue';
import PartActionButtons from '@/components/PartWorkflow/Header/PartActionButtons.vue';
import PartNavigation from '@/components/PartWorkflow/Header/PartNavigation.vue';
import Info from '@/components/PartWorkflow/Info/Info.vue';

export default {
  name: 'PartFrame',

  components: {
    TroisCanvas,
    CadLessCanvas,
    Info,
    HeaderPartInformation,
    PartNavigation,
    PartActionButtons,
    PartFooter,
  },

  beforeRouteLeave(to) {
    // check store for unsaved changes

    // at least file upload has to be done in order to save information
    // check for unsaved changes
    if (this.unsavedChanges.length != []) {
      if (this.boolFileConversion) {
        this.savePart(this.unsavedChanges);
      } else {
        // Show popup with either stay and upload a file or stay to wait for file upload
        // only call next() if ok was clicked
        this.storedRoute = to;
        this.popupShow = true;
        this.triggerPopup({
          key: this.$options.name,
          show: true,
          title: 'Unsaved Changes detected',
          message: 'If you leave this page. Your changes will be lost. It is necessary to upload a CAD file.',
          buttons: true,
          buttonContent: [
            { text: 'Stay here', type: 'outlined', value: 'cancel' },
            { text: 'Leave to ' + this.storedRoute?.name, type: 'secondary', value: 'ok' },
          ],
        });
        return false;
      }
    } else {
      // No unsaved changes, just going to "to" route.
    }

    // save actual page to HTML5 storage
    let objectToSave = JSON.stringify({ name: this.$route.name, params: this.$route.params });
    localStorage.setItem(this.part.part_id, objectToSave);
  },

  data() {
    return {
      storedRoute: null,
      onRoutePartLoaded: false,
      mountingWithId: false,
    };
  },

  computed: {
    ...mapState(['part', 'activeId', 'unsavedChanges', 'popup']),
    ...mapState('application', ['websocketDisconnect', 'axiosInstance']),
    ...mapGetters(['fileConversionFinished', 'fileGeometricPropertiesFinished', 'cadComputationRunning']),

    isCadLess() {
      return this.part.is_cadless;
    },

    currentRoute() {
      return this.$route.name;
    },
  },

  watch: {
    activeId: function (newId) {
      /* reload canvas, if create in navbar is clicked, but user is already on the page*/
      if (newId === '') {
        this.updateReloadCanvas(true);
      }
      /* set the url to the current part id url, when the part id changes (is created by the backend) */
      this.pushIdToUrl(newId);
    },

    websocketDisconnect: {
      handler(websocketDisconnect) {
        this.handleWebsocketDisconnect(websocketDisconnect);
      },

      immediate: true,
    },

    $route(to) {
      let partId = to.params.part_id;
      if (partId === '' || partId === 0 || partId === '0') {
        /* set the url to the current part id url, when the part id changes (is created by the backend) */
        this.pushIdToUrl(0);
      } else {
        if (this.mountingWithId) {
          this.mountingWithId = false;
        } else {
          this.loadPartAndUpdateStore(partId);
        }
      }
    },

    boolCadComputationFinished: {
      handler(boolCadComputationFinished) {
        if (boolCadComputationFinished) {
          // Once CAD Computation is finished unsaved changes have to be saved to the backend
          this.savePart(this.unsavedChanges);
        }
      },

      immediate: true,
    },

    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.triggerPopup('');
          // reset unsaved changes in the store
          this.updateUnsavedChanges(null);
          // leave page and loose unsaved changes
          this.$router.push({
            name: this.storedRoute.name,
            params: this.storedRoute.params,
          });
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  mounted() {
    let activeId = '';

    if (this.$route.params.part_id != undefined) {
      activeId = this.$route.params.part_id;
    }
    if (activeId === '0') {
      // don't try to getPartDetails from placeholder url part_id
      activeId === '';
    }
    this.mountingWithId = true;
    this.loadPartAndUpdateStore(activeId);
  },

  methods: {
    ...mapMutations(['updateUnsavedChanges', 'updateActiveId', 'triggerPopup', 'updatePart', 'setPartCategories']),

    ...mapMutations('canvas', ['updateReloadCanvas']),
    ...mapMutations('application', ['setWebsocketDisconnect']),

    pushIdToUrl(newId) {
      this.$router.replace({
        name: this.$route.name,
        params: {
          part_id: newId,
        },
      });
      this.onRoutePartLoaded = false;
    },

    loadPartAndUpdateStore(activeId) {
      if (!this.onRoutePartLoaded && !this.cadComputationRunning) {
        this.updatePart({ part_id: activeId });
        // update the active Id in the store
        this.updateActiveId(activeId);
        // get details from backend, async // also part categories from group
        this.getPartDetail(activeId);
        this.onRoutePartLoaded = true;
      }
    },

    handleWebsocketDisconnect(websocketDisconnect) {
      // if a websocket disconnect is noticed the part details are GET from the backend to make sure to be up to date.
      if (websocketDisconnect && this.activeId != null) {
        this.getPartDetail(this.activeId);
        this.setWebsocketDisconnect(false);
      } else if (this.activeId == null) {
        this.setWebsocketDisconnect(false);
      }
    },

    async getPartDetail(activeId) {
      this.axiosInstance
        .get('api/v1/part/' + activeId + '/')
        .then(response => {
          return response.data;
        })
        .then(data => {
          // update part in store and load part. But only do it if activeId didnt change in the meantime.
          if (activeId == this.activeId) {
            this.updatePart(data);
            this.setPartCategories();
            this.updateReloadCanvas(true);
          }
        })
        .catch(error => {
          this.$root.notify('error', error.response.data.error_message, 3000);
          console.log(error);
        });
    },

    savePart(formData) {
      // This function will parse the current 'part' instance to the database.
      this.axiosInstance
        .put(`/api/v1/part/${this.part.part_id}/`, formData)
        .then(response => {
          this.updatePart(response.data);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  grid-area: hd;
  margin: 10px 10px;
  max-width: 100%;
  min-width: 0;
  position: relative;
}
.navi {
  grid-area: nv;
  max-width: 100%;
  min-width: 0;
}
.actions {
  grid-area: ac;
  max-width: 100%;
  min-width: 0;
  position: relative;
  margin: 20px 10px;
}
.footer {
  grid-area: ft;
  width: 100%;
  min-width: 0;
  position: fixed;
  bottom: 0;
}

.canvas {
  user-select: none;
}

.right-container {
  grid-area: rc;
  margin: 0 10px;
  position: relative;
  min-height: 100vh;
  padding-bottom: 80px;
}

.left-container {
  grid-area: lc;
  max-width: 100%;
  min-width: 0;
  position: relative;
}

.part-frame {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  grid-auto-rows: auto auto;
  grid-template-areas: 'nv' 'hd' 'ac' 'sp' 'lc' 'rc' 'ft';
  transition: all 1s ease-in;
  position: relative;
  top: 56px;
}

// @media only screen and (min-width: 640px) {
//   .part-frame {
//     grid-template-columns: repeat(2, 1fr);
//     grid-template-areas: "hd hd" "sp sp" "lc rc";
//   }
// }
@media only screen and (min-width: 1700px) {
  .part-frame {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'nv nv nv' 'hd hd hd' 'ac ac ac ' 'sp sp sp' 'lc lc rc' 'ft ft ft';
  }
}
</style>
