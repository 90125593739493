<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="flex flex-col">
        <div class="text-17">Feasibility Priority Configuration</div>
      </div>
    </template>

    <template #default>
      <div v-for="[feasKey, feasValue] in Object.entries(parsedFeasibilities)" :key="feasKey">
        <div
          v-if="!feasValue['hideInWeightDialogIfLimitUnset'] || feasValue['limitIsSet']"
          class="flex flex-col border-b border-gray-50 mb-16 mt-16"
        >
          <div class="text-13">{{ feasValue['name'] }}</div>
          <div class="text-11 text-gray-400 mb-8">{{ feasValue['additionalInfo'] }}</div>

          <div v-if="feasValue.minAndMaxPresent" class="text-13 text-gray-600">
            <div v-for="(minMaxKey, index) in ['min', 'max']" :key="index">
              {{ minMaxKey }}
              <FeasibilityWeightsSliderWrapper
                :limit-is-set="activeCheck(feasValue[minMaxKey])"
                :initial-value="parseWeightToSliderValue(feasValue[minMaxKey]['weight'])"
                @slider-change-handler="saveUserInputs(feasKey, minMaxKey, $event)"
              />
            </div>
          </div>

          <div v-else>
            <div>
              <FeasibilityWeightsSliderWrapper
                :limit-is-set="activeCheck(feasValue)"
                :initial-value="parseWeightToSliderValue(feasValue['weight'])"
                @slider-change-handler="saveUserInputs(feasKey, feasValue['minOrMax'], $event)"
              />
            </div>
          </div>

          <div class="mb-16" />
        </div>
      </div>
      <div class="flex gap-8 my-8 justify-end">
        <SparkButton variant="outlined" @click="close()"><div v-text="'Cancel'" /></SparkButton>
        <SparkButton variant="secondary" @click="submit()"><div v-text="'Submit'" /></SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import parseFeasDict from '@/components/PartWorkflow/Feasibility/FeasibilityParsing.js';
import FeasibilityWeightsSliderWrapper from '@/components/PartWorkflow/Feasibility/FeasibilityWeightsSliderWrapper.vue';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

const sliderValToWeight = {
  0: 0,
  1: 0.5,
  2: 1,
  3: 10,
  4: 1000,
};

export default {
  name: 'FeasibilityWeightsModal',

  components: {
    SparkModal,
    SparkButton,
    FeasibilityWeightsSliderWrapper,
  },

  props: {
    show: { type: Boolean },
    processChainId: { type: String, default: '' },
  },

  emits: ['close', 'select'],

  data() {
    return {
      weights: {},
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['investigationDetails', 'part']),

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },

    parsedFeasibilities() {
      return parseFeasDict(this.activeProcessChain.feasibility);
    },
  },

  methods: {
    ...mapMutations(['updateSingleProcessChain']),

    activeCheck(feasObj) {
      return feasObj['percent_violation'] !== null && feasObj['limitIsSet'];
    },

    saveUserInputs(feasName, minMaxKey, newVal) {
      const parseFeasNameToBackendConvention = {
        thickness: 't',
        thickness_mean: 't_mean',
        gap_size: 'gap',
        support_occluded: 'support',
        material_agglom: 'mat_agglom',
      };
      if (feasName in parseFeasNameToBackendConvention) {
        feasName = parseFeasNameToBackendConvention[feasName];
      }

      feasName = `${feasName}_${minMaxKey}_weight`;
      this.weights[feasName] = this.parseSliderValueToWeight(newVal);
    },

    parseSliderValueToWeight(sliderVal) {
      const weight = sliderValToWeight[sliderVal];
      return weight;
    },

    parseWeightToSliderValue(weight) {
      // eslint-disable-next-line no-unused-vars
      const valueMatch = Object.entries(sliderValToWeight).find(([_, feasValue]) => feasValue == weight);
      return valueMatch ? Number(valueMatch[0]) : null;
    },

    close() {
      this.$emit('close');
    },

    submit() {
      if (this.processChainId != '') {
        this.axiosInstance
          .put(`api/v1/feasibility-weights/${this.processChainId}/`, this.weights)
          .then(response => {
            this.updateSingleProcessChain(response.data);
          })
          .catch(error => {
            this.$root.notifyError('Configurating Feasibilities', error);
          });

        this.$emit('close');
      }
    },
  },
};
</script>
