import posthog from 'posthog-js';

import { parseQuery, stringifyQuery } from '@/plugins/query';

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init('phc_PRQrybTVLEKPYE2Up8SLJUnQlCDwhC4T4XV1ttYPCkP', {
      api_host: 'https://eu.i.posthog.com',
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: '*',
      },
      mask_ip: true,
      autocapture: true,

      // https://posthog.com/tutorials/cookieless-tracking
      persistence: 'memory',
      bootstrap: {
        // optional
        distinctID: 'user distinct id',
        featureFlags: {
          'feature-flag-1': true,
          'feature-flag-2': false,
        },
      },

      sanitize_properties: (properties, event_name) => {
        if (properties.$current_url) {
          properties.$current_url = cleanUrl(properties.$current_url);
        }
        if (properties.$pathname) {
          properties.$pathname = cleanUrl(properties.$pathname);
        }
        return properties;
      },
    });
  },
};

function cleanUrl(url) {
  const parsedUrl = new URL(url, window.location.origin);
  const path = parsedUrl.pathname;
  const queryParams = parseQuery(parsedUrl.search);

  // Remove uid from query parameters
  delete queryParams.uid;

  // Remove uid from path segments
  const cleanPath = path
    .split('/')
    .filter(segment => !segment.match(/[a-zA-Z0-9]{24}/))
    .join('/');

  // Reconstruct the URL without uid
  const cleanedUrl = `${cleanPath}${stringifyQuery(queryParams) ? `?${stringifyQuery(queryParams)}` : ''}`;

  return cleanedUrl;
}
