const ResizableTableDirective = {
  mounted(el, binding) {
    resize(el, binding);
  },
};

export default app => {
  app.directive('resizable', ResizableTableDirective);
};

function resize(el, binding) {
  // Set Saved Column Widths
  // Translate column into String for CSS Grid
  el.style.gridTemplateColumns = binding.value.width.join(' ');

  const columns = [];
  let headerBeingResized;

  const onMouseMove = e =>
    requestAnimationFrame(() => {
      // Calculate the desired width
      let horizontalScrollOffset = el.scrollLeft;
      if (headerBeingResized != null) {
        const width = horizontalScrollOffset + e.clientX - headerBeingResized.offsetLeft;

        // Update the column object with the new size value
        const column = columns.find(({ header }) => header === headerBeingResized);

        column.size = Math.max(50, width) + 'px'; // Enforce our minimum
        // For the other headers which don't have a set width, fix it to their computed width
        columns.forEach(column => {
          if (column.size.startsWith('minmax')) {
            // isn't fixed yet (it would be a pixel value otherwise)
            column.size = parseInt(column.header.clientWidth, 10) + 'px';
          }
        });
        el.style.gridTemplateColumns = columns.map(({ header, size }) => size).join(' ');
      }
    });
  // Clean up event listeners, classes, etc.
  const onMouseUp = () => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    headerBeingResized.classList.remove('header--being-resized');
    headerBeingResized.removeEventListener('mousedown', initResize);
    headerBeingResized = null;

    // Trigger Change Event
    const event = new CustomEvent('resized', { detail: columns, bubbles: true });
    el.dispatchEvent(event);
  };
  // Get ready, they're about to resize
  const initResize = ({ target }) => {
    headerBeingResized = target.parentNode;
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    headerBeingResized.classList.add('header--being-resized');
  };
  // Let's populate that columns array and add listeners to the resize handles
  document.querySelectorAll('th').forEach(header => {
    columns.push({ header, size: `minmax(10px, 1fr)`, name: header.textContent });
    header.querySelector('.resize-handle').addEventListener('mousedown', initResize);
  });
}
