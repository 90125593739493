<template>
  <div>
    <DisplayOrientationOptimizationSetting
      :title="title"
      :disabled-setting="disabledSetting"
      :chain="chain"
      @setting-selected="saveProcessChain($event)"
    />

    <CalculationStyleSetting
      :title="title"
      :disabled-setting="disabledSetting"
      :chain="chain"
      @setting-selected="saveProcessChain($event)"
    />
    <SharedBuildJobSetting
      :title="title"
      :disabled-setting="disabledSetting"
      :chain="chain"
      @setting-selected="saveProcessChain($event)"
    />
    <NestingDimensionSetting
      :disabled-setting="disabledSetting"
      :title="title"
      :chain="chain"
      @setting-selected="saveProcessChain($event)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import CalculationStyleSetting from './CalculationStyleSetting.vue';
import DisplayOrientationOptimizationSetting from './DisplayOrientationOptimizationSetting.vue';
import NestingDimensionSetting from './NestingDimensionSetting.vue';
import SharedBuildJobSetting from './SharedBuildJobSetting.vue';

import { computationRunning } from '@/composables/processChainHandling.js';

export default {
  name: 'FeasibilitySettings',

  components: {
    DisplayOrientationOptimizationSetting,
    CalculationStyleSetting,
    NestingDimensionSetting,
    SharedBuildJobSetting,
  },

  props: { chain: Object, disabledSetting: Boolean, title: String },

  data() {
    return {
      boolProhibitRapidSettingsChange: false,
    };
  },

  computed: {
    ...mapState(['activeId']),
    ...mapState('application', ['axiosInstance']),
    ...mapGetters(['lockedForUser']),

    partAlreadyExisting() {
      if (this.activeId == '' || this.activeId == '0' || this.activeId == null) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    ...mapMutations(['updateSingleProcessChain']),
    saveProcessChain(formData) {
      this.prohibitionHandler();
      this.boolProhibitRapidSettingsChange = true;
      if (this.chain && this.chain.process_chain_id) {
        this.axiosInstance
          .put(`api/v1/process-chain/${this.chain.process_chain_id}/`, formData)
          .then(response => {
            this.updateSingleProcessChain(response.data);
            this.boolProhibitRapidSettingsChange = false;
          })
          .catch(error => {
            console.log('Save Process Chain Error', JSON.stringify(error));
          });
      }
    },

    prohibitionHandler() {
      if (!this.partAlreadyExisting) {
        return;
      }
      if (this.lockedForUser) {
        return;
      }
      if (computationRunning(this.chain.process_chain_id)) {
        this.$root.notify(
          'warning',
          'Analysis running',
          'You may select a new material once the analysis is finished.',
          4000
        );
        return;
      } else if (this.boolProhibitRapidSettingsChange) {
        this.$root.notify(
          'warning',
          'Material currently processing',
          'Please wait a few seconds before changing the material again.',
          4000
        );
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h5 {
  font-size: var(--14px);
}

textarea {
  font-size: 0.85em;
  width: 100%;
  padding: 1px;
  padding-left: 10px;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 4px;

  &:hover {
    border: 2px solid rgb(196, 196, 196);
    padding: 0px;
    padding-left: 9px;
    border-radius: 4px;
  }
}

.btn-group button {
  width: 80px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 0px 0px 0px 0px;

  &:disabled {
    background-color: var(--light-color);
  }
  &:hover {
    background-color: var(--primary-color);
    transition: all 0.3s;
  }

  &:first-child {
    margin-left: 20px;
    margin-right: 0px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &:last-child {
    margin-left: 0px;
    margin-right: 20px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.toggle-btn-inactive {
  background-color: var(--primary-color-light);
  transition: all 0.3s;

  &:hover {
    background-color: var(--primary-color-dark);
    transition: all 0.3s;
  }
}

input[type='range'] {
  z-index: 5;
  position: relative;
  background-color: #bdc3c7;
  width: 150px;
  height: 5px;
  border-radius: 5px;
  margin: 0 auto;
  outline: 0;
  opacity: 0.15;

  &:hover {
    height: 5px;
    opacity: 1;
  }

  &:before {
    opacity: 0;
  }
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: var(--spark-darkgrey);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:active {
    transform: scale(1.15);
  }
}

​ input[type='range']::-webkit-slider-thumb:hover {
  background-color: rgb(255, 0, 0);
  border: 2px solid #e74c3c;
}
</style>
./DisplayOrientationOptimizationSetting.vue
