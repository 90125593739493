<template>
  <div class="flex w-fit m-4" :disabled="computationRunning">
    <ChainMenu
      :chain="chain"
      class="border border-gray-200 rounded-8 z-10"
      :class="checkRunning ? 'cursor-wait' : ''"
    />
    <div class="bg-gray-800 rounded-8 ml-4 p-12">
      <SparkButtonIcon
        v-if="analysisFinished"
        custom="text-white p-12"
        icon="fas fa-arrow-right"
        @click="goToOverview()"
      />
      <SparkButtonIcon
        v-else-if="!analysisFinished && !checkRunning"
        icon="fas fa-redo p-12"
        custom=" text-white"
        @click="analyzeSingleChain()"
      />
      <SparkButtonIcon
        v-else-if="!analysisFinished && checkRunning"
        icon="fa-solid fa-sync fa-spin"
        custom="text-white"
        :class="checkRunning ? 'cursor-wait' : ''"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButtonIcon from '../../../../SparkComponents/SparkButtonIcon.vue';

import ChainMenu from './ChainMenu.vue';

import { analysisFinished, computationRunning } from '@/composables/processChainHandling.js';

export default {
  name: 'Checkout',

  components: {
    SparkButtonIcon,
    ChainMenu,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      setRunning: false,
    };
  },

  computed: {
    ...mapState(['part', 'user', 'popup']),
    ...mapState('application', ['axiosInstance']),

    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },

    computationRunning() {
      return computationRunning(this.chain.process_chain_id);
    },

    checkRunning() {
      if (this.setRunning || this.computationRunning) {
        return true;
      }
      return false;
    },

    formData() {
      return {
        0: {
          label: 'Select a Benchmark Process Chain',
          field: 'select',
          options: this.getProcessChains(this.chain.process_chain_id),
          required: false,
          value: this.part.benchmark_process_chain_id,
        },

        1: {
          label: 'Do not show again',
          field: 'checkbox',
          required: false,
          value: false,
        },
      };
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.$router.push({
            name: 'Overview',
            params: { part_id: this.part.part_id, process_chain_id: popup.additionalData },
          });
          this.changeInvestigationDetails({ uid: popup.additionalData, content: '' });
          this.setMainAndBenchmark(popup.additionalData, popup.formData[0].value);
          localStorage.setItem('checkout-hide-popup', popup.formData[1].value);
          this.triggerPopup('');
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  methods: {
    ...mapMutations([
      'changeInvestigationDetails',
      'updateSingleProcessChain',
      'triggerPopup',
      'changeProcessChainUids',
      'updatePart',
    ]),

    getProcessChains(main_process_chain_id) {
      let processChainList = []; // Use camelCase for local variable names
      Object.keys(this.part.process_chains).forEach(key => {
        if (key !== main_process_chain_id) {
          processChainList.push({
            text: this.part.process_chains[key].name,
            value: key,
            uid: key,
          });
        }
      });
      return processChainList;
    },

    goToOverview() {
      let hidePopup = localStorage.getItem('checkout-hide-popup');

      if (hidePopup == null) {
        localStorage.setItem('checkout-hide-popup', false);
      }

      if (this.chain.process_chain_id != 0 && hidePopup === 'false') {
        this.triggerPopup({
          key: this.$options.name,
          show: true,
          title: 'Checkout',
          message:
            'With checking out you will set ' +
            this.chain.name +
            ' as main process chain. You can also select a benchmark process chain for comparison.',
          type: 'generalform',
          formType: 'edit',
          formData: this.formData,
          additionalData: this.chain.process_chain_id,
          buttons: true,
          buttonContent: [
            { text: 'Cancel', type: 'outlined', value: 'cancel' },
            { text: 'Ok', type: 'secondary', value: 'ok' },
          ],
        });
      } else if (this.part.primary_process_chain_id == this.chain.process_chain_id || hidePopup === 'true') {
        this.$router.push({
          name: 'Overview',
          params: { part_id: this.part.part_id, process_chain_id: this.chain.process_chain_id },
        });
        this.changeInvestigationDetails({ uid: this.chain.process_chain_id, content: '' });
        this.setMainAndBenchmark(this.chain.process_chain_id, this.part.benchmark_process_chain_id);
      }
    },

    setMainAndBenchmark(main_id, benchmark_id) {
      if (main_id === this.part.benchmark_process_chain_id && benchmark_id === this.part.benchmark_process_chain_id) {
        benchmark_id = null;
      }

      let formData = { primary_process_chain_id: main_id, benchmark_process_chain_id: benchmark_id };
      this.changeProcessChainUids(formData);
      this.axiosInstance.put(`api/v1/part/${this.part.part_id}/`, formData).then(response => {
        this.$root.notify(
          'success',
          'Checkout succesful',
          'Main and benchmark process chains were set succesfully.',
          6000
        );

        this.updatePart(response.data.part);
      });
    },

    analyzeSingleChain() {
      if (this.setRunning != true && !this.computationRunning) {
        this.setRunning = true;
        if (!this.computationRunning) {
          this.axiosInstance.post(`api/v1/analysis/process-chain/${this.chain.process_chain_id}/`).then(() => {
            this.$root.notify('success', 'Analysis started', 'Single process chain will be analyzed', 6000);
          });
        }
        this.setRunning = false;
      }
    },
  },
};
</script>
