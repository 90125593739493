<template>
  <div class="flex flex-col mt-24">
    <div class="flex mx-16 gap-8 bg-white p-4 rounded-8 border border-gray-50">
      <SparkInput :placeholder="calibrationResult?.name" disabled label="Name" name="material" />
      <SparkInput
        :placeholder="calibrationResult?.material_name"
        disabled
        label="Calibration Material"
        name="material"
      />

      <SparkInput label="Calibration Machine" name="machine" disabled :placeholder="calibrationResult?.machine_name" />

      <SparkInput
        label="Calibration Process Chain"
        name="process_chain"
        disabled
        :placeholder="calibrationResult?.process_chain_template_name"
      />
    </div>
    <div class="flex bg-white mx-16 mt-16 rounded-8 border border-gray-50 justify-start align-start">
      <div class="flex w-2/3">
        <table class="text-13">
          <tr class="border border-gray-50 h-48">
            <th class="pl-16 text-gray-500 text-13 font-normal border border-gray-50">Name</th>
            <th class="p-16 text-gray-500 text-13 font-normal border border-gray-50">Lot Size</th>
            <th class="p-16 text-gray-500 text-13 font-normal border border-gray-50">
              <div v-if="selectedMode === 'machine'">Build Time (hh:mm:ss)</div>
              <div v-if="selectedMode === 'price'">Price</div>
            </th>
            <th class="p-16 text-gray-500 text-13 font-normal border border-gray-50 bg-primary-100">Prediction</th>
            <th class="p-16 text-gray-500 text-13 font-normal border border-gray-50 bg-primary-100">Deviation in %</th>
          </tr>
          <tr v-for="(part, key) in orderedCalibrationInstancesResult" :key="key" class="h-48">
            <td class="pl-16 p-4 border border-gray-50">{{ part.part_name }}</td>
            <td class="pl-16 p-4 border border-gray-50 text-right pr-4">{{ part.lot_size }}</td>
            <td class="pl-16 p-4 border border-gray-50 text-right">
              <FeedbackValueTimespan v-if="selectedMode === 'machine'" :editable="false" :feedback-value="part.value" />
              <div v-if="selectedMode === 'price'">{{ $formatTwoDecimalPlaces(part.value) }} €</div>
            </td>
            <td v-if="part.deviation != null" class="pl-16 p-4 border border-gray-50 bg-primary-100 text-right pr-4">
              <FeedbackValueTimespan
                v-if="selectedMode === 'machine'"
                :editable="false"
                :feedback-value="part.value + part.deviation"
              />
              <div v-if="selectedMode === 'price'">{{ $formatTwoDecimalPlaces(part.value + part.deviation) }} €</div>
            </td>
            <td v-else class="pl-16 p-4 border border-gray-50 bg-primary-100 text-right">
              <SparkBadge variant="outlined">Not included</SparkBadge>
            </td>
            <td v-if="part.deviation != null" class="bg-primary-100 text-right pr-4 border border-gray-50">
              {{ deviationPercentage(part) }} %
            </td>
            <td v-else class="bg-primary-100 border border-gray-50 text-right pr-4">-</td>
          </tr>
          <tr class="border-none">
            &nbsp;
          </tr>
        </table>
      </div>
      <DeviationChart class="w-1/3" />
    </div>
    <div v-if="selectedMode === 'machine'" class="bg-white m-16 rounded-8 p-8">
      <details>
        <summary class="text-15">Optimized Machine Parameters</summary>
        <div>
          <table>
            <tr v-for="(param, key) in calibrationResult.result?.optimized_parameters" :key="param" class="text-13">
              <th class="pr-8">{{ key }}</th>
              <td class="text-right">{{ $formatTwoDecimalPlaces(param) }}</td>
            </tr>
          </table>
        </div>
      </details>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeedbackValueTimespan from '../../../components/Reusable/FeedbackValues/FeedbackValueTimespan.vue';

import DeviationChart from './DeviationChart.vue';

import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'CalibrationResults',

  components: { SparkInput, FeedbackValueTimespan, DeviationChart, SparkBadge },

  data() {
    return {
      orderedCalibrationInstancesResult: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    ...mapState('calibration', [
      'selectedProcessChain',
      'selectedMode',
      'calibrationInstancesResult',
      'calibrationResult',
      'calibrationConfigUid',
    ]),
  },

  mounted() {
    this.getCalibrationInstanceList();
  },

  methods: {
    ...mapMutations('calibration', [
      'setCalibrationResult',
      'setCalibrationInstancesResult',
      'setTransformedPartsData',
      'setSelectedParameters',
      'setSelectedProcessChain',
    ]),

    deviationPercentage(part) {
      let percentage = (1 - (part.value + part.deviation) / part.value) * 100;
      percentage = Math.abs(percentage).toFixed(2);
      return percentage;
    },

    async getCalibrationInstanceList() {
      await this.axiosInstance
        .get(`api/v1/calibration-list/${this.calibrationConfigUid}`)
        .then(response => {
          this.setCalibrationInstancesResult(response.data['calibration_instances']);
          this.setCalibrationResult(response.data['calibration_config']);
          this.setTransformedPartsData(response.data['calibration_instances']);
          this.setSelectedParameters(response.data['calibration_config']['parameters']);
          let process_chain_template = {};
          process_chain_template['name'] = response.data['calibration_config']['process_chain_template_name'];
          process_chain_template['material_name'] = response.data['calibration_config']['material_name'];
          process_chain_template['machine'] = response.data['calibration_config']['machine_name'];
          process_chain_template['prc_name'] = response.data['calibration_config']['process_name'];
          this.setSelectedProcessChain(process_chain_template);
          this.orderCalibrationInstancesResult();
        })
        .catch(error => {
          console.error(error);
        });
    },

    orderCalibrationInstancesResult() {
      this.orderedCalibrationInstancesResult = this.calibrationInstancesResult.sort((a, b) => {
        return this.$dayjs(a.created_date).diff(this.$dayjs(b.created_date));
      });
    },
  },
};
</script>
