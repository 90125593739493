<template>
  <SparkButtonIcon custom="hover:!bg-red-50 mr-10" small icon="" title="Delete Part" @click="confirmDelete">
    <img src="@/assets/icons/delete.svg" />
  </SparkButtonIcon>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButtonIcon from '../SparkComponents/SparkButtonIcon.vue';
export default {
  name: 'DeletePart',

  components: { SparkButtonIcon },

  props: { partId: { type: String, required: true }, partName: { type: String, required: true } },

  emits: ['deleted', 'deleting'],

  computed: {
    ...mapState(['popup']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    popup: {
      handler(popup) {
        if (popup?.key == this.$options.name && popup?.formData == this.partId) {
          if (popup?.clicked == 'ok') {
            this.triggerPopup('');
            this.deletePart();
          } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
            this.triggerPopup('');
          }
        }
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    confirmDelete() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Delete Part',
        message: 'The part ' + this.partName + ' will be deleted. Are you sure?',
        buttons: true,
        formData: this.partId,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Delete', type: 'secondary', value: 'ok' },
        ],
      });
    },

    async deletePart() {
      this.$emit('deleting', this.partId);
      await this.axiosInstance
        .delete(`/api/v1/part/${this.partId}/`)
        .then(() => {
          this.$emit('deleted');
        })
        .catch(error => {
          this.$root.notify('error', 'Delete part error', error, 6000);
        });
    },
  },
};
</script>
