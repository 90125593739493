<template>
  <div v-if="maxPageNumber > 1" class="flex items-center justify-center gap-4">
    <SparkButton
      name="Previous"
      custom="!bg-transparent mx-8"
      variant="plain"
      :disabled="currentPage === 1"
      @click="updatePage(currentPage - 1)"
    >
      <span><i class="fas fa-arrow-left" /></span>
    </SparkButton>

    <SparkButton
      v-for="page in visiblePages"
      :key="page"
      name="page"
      variant="plain"
      custom="!bg-transparent !text-gray-400 !text-13 !p-4"
      :class="{ '!font-bold !text-gray-900': page === currentPage }"
      @click="updatePage(page)"
    >
      <span v-text="page" />
    </SparkButton>
    <SparkButton
      v-if="maxPageNumber > visiblePages[visiblePages.length - 1] && maxPageNumber > 5 && maxPageNumber !== 6"
      name="more"
      variant="plain"
      custom="!bg-transparent cursor-default"
    >
      ...
    </SparkButton>
    <SparkButton
      v-if="maxPageNumber > visiblePages[visiblePages.length - 1]"
      name="lastPage"
      variant="plain"
      custom="!bg-transparent !text-gray-400 !text-13 !p-4"
      :class="{ '!font-bold !text-gray-900': maxPageNumber === currentPage }"
      @click="updatePage(maxPageNumber)"
    >
      <span v-text="maxPageNumber" />
    </SparkButton>
    <SparkButton
      name="next"
      variant="plain"
      custom="!bg-transparent !p-4 ml-8 mr-12"
      :disabled="currentPage === maxPageNumber"
      @click="updatePage(currentPage + 1)"
    >
      <span><i class="fas fa-arrow-right" /></span>
    </SparkButton>
    <div v-if="showPageNavigator" class="flex items-center gap-4">
      <span class="text-13 text-gray-400" v-text="'Go to page'" />
      <SparkInput
        v-model.number="jumpedPage"
        small
        class="!my-0 !py-0"
        custom="!w-60 "
        name="jumped-page"
        @change="navigateToPage"
      />
    </div>
  </div>
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'SparkTablePagination',

  components: { SparkButton, SparkInput },

  props: {
    maxPageNumber: { type: Number, default: 1 },
    currentPage: { type: Number, default: 1 },
  },

  emits: ['update-current-page'],

  data() {
    return {
      showPageInput: false,
      jumpedPage: null,
      visiblePageCount: 5,
    };
  },

  computed: {
    visiblePages() {
      let start = Math.max(1, this.currentPage - Math.floor(this.visiblePageCount / 2));
      let end = Math.min(this.maxPageNumber - 1, start + this.visiblePageCount - 1);

      if (end - start + 1 < this.visiblePageCount) {
        start = Math.max(1, end - this.visiblePageCount + 1);
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },

    showPageNavigator() {
      return (
        this.maxPageNumber > this.visiblePages[this.visiblePages.length - 1] &&
        this.maxPageNumber > 5 &&
        this.maxPageNumber !== 6
      );
    },
  },

  methods: {
    updatePage(page) {
      if (page >= 1 && page <= this.maxPageNumber) {
        this.$emit('update-current-page', page);
      }
    },

    navigateToPage() {
      if (this.jumpedPage === this.currentPage) {
        return;
      }
      if (this.jumpedPage && this.jumpedPage >= 1 && this.jumpedPage <= this.maxPageNumber) {
        this.updatePage(this.jumpedPage);
        this.showPageInput = false;
        this.jumpedPage = null;
      }
    },
  },
};
</script>
