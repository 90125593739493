<template>
  <div class="progress-bar" role="progressbar" :aria-valuenow="value" :aria-valuemin="min" :aria-valuemax="max">
    <div v-if="track" class="progress-bar__track" :style="{ left: `${track.position}%` }">
      <div class="track-container">
        <div class="track-line" />
        <div class="track-text">{{ track.text }}</div>
      </div>
    </div>
    <div
      class="progress-bar__transition"
      :style="{
        width: `${percentage > 100 ? 100 : percentage}%`,
        backgroundColor: `${backgroundColor}`,
        borderTopRightRadius: `${percentage >= 100 ? '10px' : '0px'}`,
        borderBottomRightRadius: `${percentage >= 100 ? '10px' : '0px'}`,
        justifyContent: `${percentage < 1 ? 'flex-start' : 'center'}`,
      }"
    >
      <slot name="progress" v-bind="{ percentage }" />
    </div>
    <div class="progress__slot">
      <slot v-bind="{ percentage, value, min, max }" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ProgressBar',

  props: {
    value: { type: Number, required: true },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    backgroundColor: { type: String, default: '#97ab13' },
    track: { type: Object, default: null },
  },

  computed: {
    percentage() {
      const progressPercentage = Math.floor(
        Math.max(Math.min(((this.value - this.min) / (this.max - this.min)) * 100, 100), 0)
      );
      const trackPercentage = Math.max(Math.floor(((this.value - this.min) / (this.max - this.min)) * 100), 0);
      return this.track ? trackPercentage : progressPercentage;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  position: relative;
  border-radius: 10px;
  background-color: #d9d9d9;
  width: 100%;
  height: 32px;

  &__transition {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 100%;
  }

  &__slot {
    position: absolute;
    inset: 0;
  }

  &__track {
    position: absolute;
    height: 120%;
    width: 2px;
    background-color: #7a7979;
  }
}

.track-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.track-line {
  width: 2px;
  background-color: #7a7979;
  height: 40px;
}

.track-text {
  color: #7a7979;
  text-wrap: nowrap;
  font-size: 10px;
}
</style>
