<template>
  <div class="filter-element">
    <div class="filter-label">Part Name</div>
    <div class="filtering">
      <input id="partname" v-model.lazy="partName" name="partname" />
    </div>
    <div :style="{ visibility: partName ? 'visible' : 'hidden' }" class="clear-filtering" @click="clear()">Clear</div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'PartNameFilter',

  props: {
    reset: { type: Number, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      partName: '',
    };
  },

  computed: {
    ...mapState(['partLibraryFilters', '']),
  },

  watch: {
    partName(newVal) {
      this.$emit('selected', { name: newVal });
    },

    reset: {
      handler() {
        if (this.reset) {
          this.clear();
        }
      },
    },
  },

  mounted() {
    const filter = JSON.parse(sessionStorage.getItem('libraryFilter'));
    const name = filter?.name;
    if (name) {
      this.partName = name;
    }
  },

  methods: {
    ...mapMutations(['resetFilter']),

    clear() {
      this.partName = '';
      this.resetFilter({ name: 'name' });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-element {
  width: 100%;
}
</style>
