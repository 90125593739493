<template>
  <div v-if="!hide" class="inline-block pl-4">
    <div v-if="!enabled">
      <div :class="{ enabledsort: enabled, disabledsort: !enabled }">
        <i class="fas fa-sort" />
      </div>
    </div>
    <div v-if="enabled && ascending" :class="{ enabledsort: enabled }">
      <i class="fas fa-sort-up" />
    </div>
    <div v-if="enabled && !ascending" :class="{ enabledsort: enabled }">
      <i class="fas fa-sort-down" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sorting',

  props: {
    enabled: { type: Boolean },
    ascending: { type: Boolean },
    hide: { type: Boolean },
  },

  data() {
    return {};
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
// if activated black
// if disabled grey
i {
  cursor: pointer;
}

.enabledsort {
  color: black;
}
.disabledsort {
  color: grey;
}
</style>
