<template>
  <nav class="flex p-20 border-b border-gray-50">
    <div class="flex items-center gap-8">
      <button
        v-for="(item, index) in navigationItems"
        :key="index"
        type="button"
        class="flex items-center px-16 py-8 rounded-8 text-14 font-medium transition-colors duration-200 text-13"
        :class="[activeTab === item.id ? 'bg-gray-50 text-primary-1000 shadow-sm' : 'text-gray-600 hover:bg-gray-200']"
        @click="setActiveTab(item.id)"
      >
        {{ item.label }}
        <!--
 <SparkBadge v-if="item.actionRequired" class="ml-8 text-11" variant="success" size="sm">
          Action required
        </SparkBadge> 
-->
      </button>
    </div>
  </nav>
</template>

<script>
import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';

export default {
  name: 'SettingsNavigation',

  components: {
    SparkBadge,
  },

  emits: ['set-active-tab'],

  data() {
    return {
      activeTab: 'cost',
      navigationItems: [
        { id: 'cost', label: 'Cost Settings', actionRequired: true },
        { id: 'category', label: 'Category Settings' },
        { id: 'documents', label: 'Custom Documents' },
        { id: 'other', label: 'Other Settings' },
      ],
    };
  },

  methods: {
    setActiveTab(tabId) {
      this.activeTab = tabId;
      this.$emit('set-active-tab', tabId);
    },
  },
};
</script>
