<template>
  <div>
    <div v-for="item in items" :key="item.value" @click="() => onClick(item)">
      <slot :item="item" :is-active="item.value === modelValue" :name="item.value">
        <slot :item="item" :is-active="item.value === modelValue">
          {{ item.label }}
        </slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SparkTabs',

  props: {
    items: { type: Array, required: true },
    modelValue: { type: String, default: '' },
  },

  emits: ['update:modelValue'],

  methods: {
    onClick(item) {
      this.$emit('update:modelValue', item.value);
    },
  },
};
</script>
