<template>
  <div v-if="limitIsSet" class="mb-8">
    <WeightSlider :initial-value="initialValue" @slider-change="sliderChange($event)" />
  </div>
  <div v-else class="text-11 text-gray-400 mb-16 mt-8">
    Check not active for used material. Go to Library > Materials and edit limit to activate check.
  </div>
</template>

<script>
import WeightSlider from '@/components/PartWorkflow/Feasibility/WeightSlider.vue';

export default {
  name: 'FeasibilityWeightsSliderWrapper',

  components: {
    WeightSlider,
  },

  props: {
    limitIsSet: { type: Boolean },
    initialValue: { type: Number, default: 0 },
  },

  emits: ['slider-change-handler'],

  methods: {
    sliderChange(event) {
      this.$emit('slider-change-handler', event);
    },
  },
};
</script>
