<template>
  <div
    v-if="!isExternal"
    class="bg-white p-12 top-56 flex border border-solid border-b-gray-200 fixed w-full z-40 flex-wrap"
  >
    <NavigationItem
      label="1. Input > "
      :active="currentRouteName === 'Input'"
      route-to="Input"
      :disabled="false"
      :params="params"
    />
    <NavigationItem
      label="2. Analysis > "
      :active="currentRouteName === 'Comparison' || currentRouteName === 'Supplier'"
      :disabled="productionDisabled"
      :params="params"
      route-to="Comparison"
    />
    <NavigationItem
      label="3. Checkout"
      :active="currentRouteName === 'Overview'"
      :disabled="checkoutDisabled"
      :params="paramsOverview"
      route-to="Overview"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import NavigationItem from './NavigationItem.vue';
export default {
  name: 'PartNavigation',

  components: {
    NavigationItem,
  },

  computed: {
    ...mapState(['user', 'activeId', 'part']),

    currentRouteName() {
      return this.$route.name;
    },

    isExternal() {
      return this.user.is_external;
    },

    productionDisabled() {
      if (this.part.part_id == 0) {
        return true;
      } else {
        return false;
      }
    },

    checkoutDisabled() {
      if (this.part.primary_process_chain_id == '' || this.part.primary_process_chain_id == null) {
        return true;
      } else {
        return false;
      }
    },

    params() {
      return { part_id: this.part.part_id };
    },

    paramsOverview() {
      return { part_id: this.part.part_id, process_chain_id: this.part.primary_process_chain_id };
    },
  },

  methods: {
    // highlightProcessChain() {
    //   this.changeInvestigationDetails({ uid: this.$route.params?.process_chain_id, content: '' });
    // },
  },
};
</script>
