<template>
  <div class="flex flex-col">
    <div class="flex align-baseline">
      <p class="font-bold text-15 mr-8">Manufacturing Cost per Part</p>
      <SparkButton v-if="toggle" variant="outlined" @click="toggleTableChart()"
        ><div class="text-11" v-text="'Show as Chart'"
      /></SparkButton>
      <SparkButton v-else variant="outlined" @click="toggleTableChart()"
        ><div class="text-11" v-text="'Show as Table'"
      /></SparkButton>
    </div>
    <div v-if="activeProcessChainIsAM">
      <CostTable v-if="toggle" :chain="activeProcessChain" :label="label" />
      <CostChart v-else :chain="activeProcessChain" :label="label" />
    </div>

    <p class="font-bold text-15 mt-20">Details</p>

    <table class="spark-table">
      <tbody>
        <tr>
          <td colspan="4">Maximum Parts per Job</td>
          <td class="right-aligned">
            {{ amPartsPerJob }}
          </td>
        </tr>
        <tr>
          <td colspan="4">Number of Build Jobs</td>
          <td class="right-aligned">
            {{ numberOfJobs }}
          </td>
        </tr>
        <tr>
          <td colspan="4">Part price per kg</td>
          <td class="right-aligned">{{ $formatPrice(pricePerKg) }}/kg</td>
        </tr>
        <tr>
          <td colspan="4">Part price per cm³</td>
          <td class="right-aligned">{{ $formatPrice(pricePerVol) }}/cm³</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import SparkButton from '../../SparkComponents/SparkButton.vue';

import CostChart from '@/components/PartWorkflow/Cost/CostChart.vue';
import CostTable from '@/components/PartWorkflow/Cost/CostTable.vue';
import { computationRunning } from '@/composables/processChainHandling.js';

export default {
  name: 'AMManufacturingCost',

  components: {
    CostChart,
    CostTable,
    SparkButton,
  },

  data() {
    return {
      currentMargin: 20,
      boolShowEntry: {
        pre_process: false,
        in_process: false,
        post_process: false,
        material: false,
      },

      toggle: false,
      label: {
        labor: 'Labor',
        machine: 'Machine',
        other: 'Other',
        build_material: 'Build Material',
        support_material: 'Support Material',
        waste_material: 'Waste Material',
      },
    };
  },

  computed: {
    ...mapState(['part', 'investigationDetails']),
    ...mapGetters(['processChainsInitializationFinished']),

    pricePerKg() {
      return this.activeProcessChain.cost.cost_pp / (this.activeProcessChain.part_mass / 1000);
    },

    pricePerVol() {
      return this.activeProcessChain.cost.cost_pp / this.part.part_vol;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },

    activeProcessChainIsAM() {
      return this.activeProcessChain?.tech == 'additive_manufacturing';
    },

    computationRunning() {
      return computationRunning(this.activeInvestigationDetails.uid);
    },

    boolCostAm() {
      return this.processChainsInitializationFinished;
    },

    boolProcessChainExists() {
      if (this.activeProcessChain == null) {
        return false;
      }
      if (this.activeProcessChain.cost != null) {
        return true;
      } else {
        return false;
      }
    },

    amCostPerPart() {
      if (this.boolProcessChainExists) {
        return this.activeProcessChain.cost.cost_pp;
      }
    },

    amCostPerLot() {
      if (this.boolProcessChainExists) {
        return this.activeProcessChain.cost.cost_pl;
      }
    },

    amCostPerJob() {
      if (this.boolProcessChainExists) {
        return this.activeProcessChain.cost.cost_pj;
      }
    },

    amPartsPerJob() {
      if (this.boolProcessChainExists && this.activeProcessChainIsAM) {
        return this.activeProcessChain.parts_pj;
      }
    },

    numberOfJobs() {
      if (this.amPartsPerJob != 0) {
        return Math.ceil(this.activeProcessChain.lot_size / this.amPartsPerJob);
      } else {
        return 'n/a';
      }
    },

    sumPartCost() {
      let part = this.part;
      return part.am_mat_cost + part.am_pre_prc_cost + part.am_post_prc_cost + part.am_in_prc_cost, 2;
    },

    amCostBreakdown() {
      if (this.boolProcessChainExists) {
        let obj = this.activeProcessChain.cost_breakdown;
        let result = {};
        for (const [key, value] of Object.entries(obj)) {
          result[key] = 0;
          for (const [subkey, subvalue] of Object.entries(obj[key])) {
            result[key] += obj[key][subkey];
          }
        }
        return result;
      }
    },
  },

  methods: {
    showCostBreakdown(step) {
      this.boolShowEntry[step] = !this.boolShowEntry[step];
    },

    toggleTableChart() {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-table {
  margin: unset;
}
.price-header {
  font-weight: bold;
}

.price-breakdown {
  font-size: var(--12px);
}

.right-aligned {
  text-align: right;
}
</style>
