<template>
  <footer v-if="showFooter" class="bg-[--primary-color] text-white w-full py-4 fixed bottom-0 h-56">
    <div class="container mx-auto text-center text-13">
      <p class="mb-4">&copy; {{ currentYear }} 3DSpark. All rights reserved.</p>
      <nav>
        <ul class="flex justify-center space-x-24">
          <li><a href="https://www.3dspark.de/imprint" class="hover:underline">Imprint</a></li>
          <li><a href="https://www.3dspark.de/privacy-policy" class="hover:underline">Privacy Policy</a></li>
          <li>
            <a href="https://www.3dspark.de/general-terms-and-conditions" class="hover:underline"
              >General Terms & Conditions</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'GtcFooter',

  computed: {
    showFooter() {
      return this.$route.meta.showGtcFooter;
    },

    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
