<template>
  <div>
    <div class="chart-container">
      <div style="display: flex; justify-content: space-between; margin: 5px; align-items: center">
        <div class="spark-new-input-group">
          <select v-model="selectedChartType" class="spark-new-select-input">
            <option v-for="(chartType, key) in chartTypes" :key="key" :value="key">
              {{ chartType.label }}
            </option>
          </select>
          <label class="spark-new-select-label">Trend</label>
        </div>
      </div>

      <div style="margin: 20px">
        <div v-if="loading" class="loading-container">
          <Loading />
        </div>
        <div v-else>
          <apexchart ref="shortChart" type="line" :options="chartOptions" :series="series" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/Reusable/Loading.vue';

export default {
  name: 'PartsUploaded',

  components: {
    Loading,
  },

  props: {
    filtering: Number,
  },

  data: function () {
    return {
      loading: true,
      totalUploads: {},
      validatedUploads: {},
      notValidatedUploads: {},
      unsetUploads: {},
      formData: {},
      colors: [],
      originalColors: {
        grey: '#383e46',
        green: '#97ab13',
        red: '#ff0000',
      },

      series: [],
      selectedChartType: 'parts_produced',
      chartTypes: {
        parts_uploaded: { label: 'Number of References', unit: '' },
        cost_saved: { label: 'Cost Saved', unit: '€' },
        lead_time_saved: { label: 'Lead Time Saved', unit: 'days' },
        parts_produced: { label: 'Parts Produced', unit: 'units' },
        // { value: 'co2_saved', label: 'CO2 Saved' },
      },
    };
  },

  computed: {
    ...mapState(['dashboardFilters']),
    ...mapState('application', ['axiosInstance']),

    filterSettings() {
      return this.dashboardFilters;
    },

    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          fontFamily: 'Poppins, Arial, sans-serif',
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>',
            },
          },
        },

        colors: this.colors,
        dataLabels: {
          enabled: true,
        },

        stroke: {
          width: [3, 3, 3, 3],
          curve: 'smooth',
        },

        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },

        markers: {
          size: 1,
        },

        xaxis: {
          categories: this.months,
        },

        yaxis: {
          title: {
            text: this.yAxisTitle,
          },

          labels: {
            formatter: val => {
              let unit = this.chartTypes[this.selectedChartType]?.unit;
              return this.formattedValue(val) + ' ' + unit;
            },
          },
        },

        legend: {
          show: true,
          position: 'top',
        },
      };
    },

    months() {
      // get the months, order them correctly and trim to 3 chars for display
      if (!this.totalUploads) return [];

      let arr = Object.values(this.totalUploads).map(entry => {
        return entry.month_verbose.substring(0, 3) + "'" + entry.year.toString().slice(-2);
      });
      return arr.reverse();
    },

    yAxisTitle() {
      let selected = undefined;
      if (this.selectedChartType != undefined) {
        selected = this.chartTypes[this.selectedChartType];
      }
      let unit = selected?.unit;
      if (unit != undefined && unit != '') {
        unit = ' in ' + selected.unit;
      }
      return selected?.label + unit;
    },
  },

  watch: {
    selectedChartType() {
      this.loading = true;
      this.getData();
    },

    filtering() {
      this.loading = true;
      this.getData();
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    formattedValue(value) {
      if (value >= 100000) {
        return String(this.$formatZeroDecimalPlaces(value / 1000)).concat(' k');
      } else {
        return this.$formatZeroDecimalPlaces(value);
      }
    },

    setHighlight() {
      let partStat = this.filterSettings.part_stat;
      if (partStat === 'all') {
        if (this.notValidatedUploads && this.validatedUploads && this.unsetUploads && this.totalUploads) {
          this.colors = ['#f8a6a9', '#a5c796', '#F5DD90', '#383e46'];
          this.series = [
            {
              name: 'Not Validated Uploads',
              data: this.transformData(this.notValidatedUploads),
            },

            {
              name: 'Validated Uploads',
              data: this.transformData(this.validatedUploads),
            },
            {
              name: 'Unset Uploads',
              data: this.transformData(this.unsetUploads),
            },
            {
              name: 'All Uploads',
              data: this.transformData(this.totalUploads),
            },
          ];
        }
      }
      if (partStat === 'validated') {
        this.series = [
          {
            name: 'Not Validated Uploads',
            data: this.transformData(this.notValidatedUploads),
          },
          {
            name: 'All Uploads',
            data: this.transformData(this.totalUploads),
          },
          {
            name: 'Unset Uploads',
            data: this.transformData(this.unsetUploads),
          },
          {
            name: 'Validated Uploads',
            data: this.transformData(this.validatedUploads),
          },
        ];
        this.colors = ['#f8a6a9', '#818a94', '#F5DD90', '#06ab02'];
      }
      if (partStat === 'not_validated') {
        this.series = [
          {
            name: 'All Uploads',
            data: this.transformData(this.totalUploads),
          },
          {
            name: 'Validated Uploads',
            data: this.transformData(this.validatedUploads),
          },
          {
            name: 'Unset Uploads',
            data: this.transformData(this.unsetUploads),
          },
          {
            name: 'Not Validated Uploads',
            data: this.transformData(this.notValidatedUploads),
          },
        ];
        this.colors = ['#818a94', '#93c3b3', '#F5DD90', '#de1029'];
      }
      if (partStat === 'unset') {
        this.series = [
          {
            name: 'All Uploads',
            data: this.transformData(this.totalUploads),
          },
          {
            name: 'Validated Uploads',
            data: this.transformData(this.validatedUploads),
          },
          {
            name: 'Not Validated Uploads',
            data: this.transformData(this.notValidatedUploads),
          },
          {
            name: 'Unset Uploads',
            data: this.transformData(this.unsetUploads),
          },
        ];
        this.colors = ['#818a94', '#93c3b3', '#de1029', '#F5DD90'];
      }
    },

    transformData(partObject) {
      if (!partObject) return [];

      let arr = Object.values(partObject).map(entry => {
        return entry.amount;
      });
      return arr.reverse();
    },

    async getData() {
      this.filterSettings.chart_type = this.selectedChartType;
      this.axiosInstance.post('/api/v1/dashboard/parts-uploaded/', this.filterSettings).then(response => {
        this.totalUploads = response.data.monthly_total_parts;
        this.validatedUploads = response.data.monthly_validated_parts;
        this.notValidatedUploads = response.data.monthly_not_validated_parts;
        this.unsetUploads = response.data.monthly_unset_parts;
        this.setHighlight();
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-30 {
  margin-top: 30px;
}
.chart-container {
  position: relative;
}
.loading-container {
  position: relative;
  top: -100px;
  left: 0;
  right: 0;
  align-self: start;
}
.dropdown-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  label {
    margin-right: 10px;
  }
}

.selected-type {
  color: var(--accent-color);
  text-decoration: underline;
}

.unselected-type {
  color: var(light-grey);

  &:hover {
    color: var(--accent-color);
  }
}
</style>
