<template>
  <SparkButtonIcon
    custom="hover:!bg-primary-100 mr-10"
    small
    icon=""
    title="Duplicate Part"
    @click="confirmDuplication"
  >
    <img src="@/assets/icons/duplicate.svg" />
  </SparkButtonIcon>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButtonIcon from '../SparkComponents/SparkButtonIcon.vue';
export default {
  name: 'DuplicatePart',

  components: { SparkButtonIcon },

  props: { partId: { type: String, required: true }, partName: { type: String, required: true } },

  emits: ['duplicated', 'duplicating'],

  computed: {
    ...mapState(['popup']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    popup: {
      handler(popup) {
        if (popup?.key === this.$options.name && popup?.formData === this.partId) {
          if (popup?.clicked === 'ok') {
            this.triggerPopup('');
            this.duplicatePart();
          } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
            this.triggerPopup('');
          }
        }
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    confirmDuplication() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Duplicate Part',
        message: "Do you really want to duplicate the part '" + this.partName + "' and all related files and objects?",
        buttons: true,
        formData: this.partId,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Duplicate', type: 'secondary', value: 'ok' },
        ],
      });
    },

    async duplicatePart() {
      this.$emit('duplicating', this.partId);
      try {
        await this.axiosInstance.post(`api/v1/part-duplication/${this.partId}/`, {});
        this.$emit('duplicated');
        this.$root.notify('success', 'Part Duplicated', 3000);
      } catch (error) {
        console.error('Error duplicating the part:', error);
        this.$root.notify('error', 'Error duplicating the part', 3000);
      }
    },
  },
};
</script>
