import Admin from '../../views/Admin/Admin.vue';
import AuditLog from '../../views/Admin/AuditLog/AuditLog.vue';
import ErrorLog from '../../views/Admin/ErrorLog/ErrorLog.vue';
import UserActivity from '../../views/Admin/UserActivity/UserActivity.vue';

const getSubNavigations = (isStaff) => ({
  admin: [
    {
      label: 'Audit Log',
      value: 'audit-log',
      to: { name: `audit-log` },
    },
    ...(isStaff ? [
      {
        label: 'User Activity',
        value: 'user-activity',
        to: { name: `user-activity` },
      },
      {
        label: 'Error Log',
        value: 'error-log',
        to: { name: `error-log` },
      },
    ] : []),
  ],
});

const routes = [
  {
    path: '/admin-view',
    redirect: '/admin-view/audit-log',
    component: Admin,
    meta: {
      title: 'Audit Log',
      showSubNavigation: true,
      subNavigationHeading: 'Audit Log',
      requireLogin: true,
      requireStaff: false,
      requireInternal: false,
      requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
      getSubNavigation: getSubNavigations,
    },
    children: [
      {
        path: 'audit-log',
        name: 'audit-log',
        component: AuditLog,
        props: true,
        meta: {
          title: 'Audit Log | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager'],
          subNavigation: getSubNavigations(false),
        },
      },
      {
        path: 'error-log',
        name: 'error-log',
        component: ErrorLog,
        props: true,
        meta: {
          title: 'Audit Log | 3D Spark',
          requireLogin: true,
          requireStaff: true,
          requireInternal: false,
          requiredRole: ['sparkmanager'],
          subNavigation: getSubNavigations(false),
        },
      },
      {
        path: 'user-activity',
        name: 'user-activity',
        component: UserActivity,
        props: true,
        meta: {
          title: 'User Activity | 3D Spark',
          requireLogin: true,
          requireStaff: true,
          requireInternal: true,
          requiredRole: ['sparkmanager'],
          subNavigation: getSubNavigations(false),
        },
      },
    ],
  },
];

export default routes;
