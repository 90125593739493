<template>
  <ErrorPage
    title="401 - Unauthorized"
    description="Sorry - It seems like you are not authorized to see this page."
    back-to="/start"
    back-to-title="Back home"
    :image-name="errorImage"
    image-description="401 - Unauthorized"
  />
</template>

<script>
import ErrorImage from '@/assets/img/error_pages/401.png';
import ErrorPage from '@/components/Reusable/ErrorPage.vue';

export default {
  name: 'NotFound',

  components: { ErrorPage },

  computed: {
    errorImage() {
      return ErrorImage;
    },
  },
};
</script>
