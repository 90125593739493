<template>
  <div class="filter-element">
    <div class="filter-label">Part Owner</div>
    <div class="filtering">
      <input id="owner" v-model.lazy="owner" name="owner" />
    </div>
    <div :style="{ visibility: owner ? 'visible' : 'hidden' }" class="clear-filtering optional" @click="clear()">
      Clear
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'PartOwnerFilter',

  props: {
    reset: { type: Number, required: true },
    component: { type: String, required: true },
    label: { type: String, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      owner: '',
    };
  },

  computed: {
    ...mapState(['partLibraryFilters']),
  },

  watch: {
    owner(newVal) {
      this.$emit('selected', { owner: newVal });
    },

    reset: {
      handler() {
        if (this.reset) {
          this.remove();
        }
      },
    },
  },

  mounted() {
    const filter = JSON.parse(sessionStorage.getItem('libraryFilter'));
    const ownParts = filter?.owner;
    if (ownParts) {
      this.owner = ownParts;
    }
  },

  methods: {
    ...mapMutations(['resetFilter', 'disablePartLibraryFilter']),

    clear() {
      this.owner = '';
      this.resetFilter({ owner: 'owner' });
    },

    remove() {
      this.disablePartLibraryFilter({
        [this.label]: this.component,
      });
      this.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-element {
  width: 100%;
}
</style>
