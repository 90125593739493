<template>
  <div v-if="show" class="confirm">
    <div class="spark-window !max-w-[50%] pop-up">
      <div class="border-b my-20 items-center flex px-20 sticky top-0 bg-white z-40 justify-between h-48">
        <span class="spark-window-title"> {{ title }}</span>
        <a
          v-if="link"
          class="icon-button"
          :href="'https://blog.3dspark.de/members-only/faq/' + link"
          target="_blank"
          rel="noopener noreferrer"
          ><i class="fas fa-question help" title="FAQ"
        /></a>
        <SparkButtonIcon icon="fas fa-times" @click="closeClicked()" />
      </div>
      <div v-if="message != '' && message != undefined" class="confirm-content text-left" v-text="message" />
      <div>
        <PopupSelectOption
          v-if="type == 'single'"
          :popup-select-label="selectLabel"
          :popup-select-options="popupSelectOptions"
        />
        <PopupCheckBox
          v-if="type == 'checkBox'"
          :popup-select-label="selectLabel"
          :form-data="formData"
          :message="message"
        />
        <PopupMultiSelectOption
          v-if="type == 'multi'"
          style="margin: 20px"
          :popup-select-options="popupSelectOptions"
        />
        <PopupTable v-if="type == 'table'" :table-input="tableInput" />
        <PopupForm
          v-if="type == 'form'"
          :form-data="formData"
          :data="data"
          :type="formType"
          :link="link"
          :additional-data="additionalData"
          :description="description"
        />
        <PopupGeneralForm
          v-if="type == 'generalform'"
          :form-data="formData"
          :data="data"
          :type="formType"
          :link="link"
          :additional-data="additionalData"
        />
        <PopupGeneralComponent v-if="type == 'component'" :data="data" :additional-data="additionalData" />
        <PopupButtons
          v-if="buttons"
          class="sticky bottom-0 bg-white"
          :button-content="buttonContent"
          @button-clicked="somethingClicked($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButtonIcon from '../SparkComponents/SparkButtonIcon.vue';

import PopupButtons from './PopupButtons.vue';
import PopupForm from './PopupForm.vue';
import PopupGeneralComponent from './PopupGeneralComponent.vue';
import PopupGeneralForm from './PopupGeneralForm.vue';
import PopupMultiSelectOption from './PopupMultiSelectOption.vue';
import PopupSelectOption from './PopupSelectOption.vue';
import PopupTable from './PopupTable.vue';

import PopupCheckBox from '@/components/Reusable/PopupCheckBox.vue';

export default {
  name: 'Popup',

  components: {
    PopupButtons,
    PopupSelectOption,
    PopupMultiSelectOption,
    PopupTable,
    PopupForm,
    PopupCheckBox,
    PopupGeneralForm,
    PopupGeneralComponent,
    SparkButtonIcon,
  },

  data() {
    return {
      title: '',
      message: '',
      show: false,
      type: '',
      selectLabel: '',
      tableInput: [],
      formData: {},
      data: {},
      formType: '',
      link: {
        default: '',
        type: '',
      },

      buttons: false,
      buttonContent: [
        { text: 'Cancel', type: 'outlined', value: 'cancel' },
        { text: 'Ok', type: 'secondary', value: 'ok' },
      ],
    };
  },

  computed: {
    ...mapState(['popup']),
  },

  watch: {
    popup(newVal) {
      this.show = newVal?.show;
      this.type = newVal?.type;
      this.message = newVal?.message;
      this.title = newVal?.title;
      this.selectLabel = newVal?.selectLabel;
      this.popupSelectOptions = newVal?.selectOptions;
      this.tableInput = newVal?.tableInput;
      this.formData = newVal?.formData;
      this.data = newVal?.data;
      this.additionalData = newVal?.additionalData;
      this.formType = newVal?.formType;
      this.link = newVal?.link;
      this.buttons = newVal?.buttons;
      this.description = newVal?.description;
      if (newVal?.buttonContent != undefined) {
        this.buttonContent = newVal?.buttonContent;
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    somethingClicked(value) {
      this.triggerPopup({ clicked: value });
    },

    closeClicked() {
      this.triggerPopup({ clicked: 'close' });
    },
  },
};
</script>

<style scoped lang="scss">
.pop-up {
  margin-top: 10vh;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10vh;
}

.confirm {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: var(--12px);

  opacity: 0;
  animation-name: confirm-open;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;

  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-close {
  animation-name: confirm-close;
}

.spark-window {
  opacity: 0;
  transform: scale(0.75);
  animation-name: window-open;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}

// .confirm-titlebar,
// .confirm-content {
//   padding: 0px 20px;
// }

.confirm-close {
  background: none;
  outline: none;
  border: none;
  transform: scale(2.5);
  color: var(--light-color);
  transition: color 0.15s;
}

.confirm-close:hover {
  color: var(--accent-color);
  cursor: pointer;
}

.confirm-content {
  line-height: 1.8em;
  margin: 0px 20px;
  padding: 10px 0px;
}

@keyframes confirm-open {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes confirm-close {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes window-open {
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
