<template>
  <div class="flex-container row-direction">
    <img :src="iconPath" class="machine-icon" />
    <div>
      <div class="description">{{ description }}</div>
      <div class="value">{{ showValue }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconWithText',

  props: {
    description: String,
    value: { type: String, default: 'undefined' },
    icon: String,
  },

  data() {
    return {};
  },

  computed: {
    origin() {
      return !import.meta.env.PROD ? 'http://localhost:3030' : document.location.origin;
    },

    iconPath() {
      return new URL(this.icon, this.origin);
    },

    showValue() {
      if (typeof this.value === 'undefined' || this.value === null) {
        return '-';
      } else {
        return String(this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  font-size: var(--8px);
  color: grey;
}

.value {
  font-size: var(--12px);
}

.machine-icon {
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
  width: 30px;
  height: 30px;
}

.row-direction {
  flex-direction: row;
}
</style>
