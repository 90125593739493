<template>
  <div
    :class="{
      [`${custom}`]: !!custom,
    }"
    class="animate-spinner h-[3rem] w-[3rem] rounded-full border-[5px] border-primary-500 border-r-transparent"
  />
</template>

<script>
export default {
  name: 'SparkSpinner',

  props: {
    custom: {
      type: String,
      default: '',
    },
  },
};
</script>
