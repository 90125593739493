<template>
  <div v-if="title != ''" class="topic-field" :style="styles[selectedStyle]">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'Badge',

  props: {
    title: { type: String, default: '' },
    selectedStyle: { type: String, default: 'darkpurple' },
  },

  data() {
    return {
      styles: {
        mint: 'color: black; background-color: #8EE1C6',
        greenyellow: 'color: black; background-color: #E1EE7B',
        orange: 'color: black; background-color: #F3B61F',
        purple: 'color: white; background-color: #CB60AB',
        brownred: 'color: white; background-color: #AB6265',
        brown: 'color: white; background-color: #8B631E',
        darkred: 'color: white; background-color: #732727',
        darkpurple: 'color: white; background-color: #582F3B',
        darkblue: 'color: white; background-color: #3C374E',
        greyblue: 'color: white; background-color: #202061',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.topic-field {
  display: inline-block;
  width: fit-content;
  padding: 2px;
  padding-top: 3px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  font-size: var(--10px);
}
</style>
