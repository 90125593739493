<template>
  <div>
    <h4>PRP Category Settings</h4>
    <PRPComboCategories />
    <PRPTextCategories />
  </div>
</template>

<script>
import PRPComboCategories from './PRPComboCategories.vue';
import PRPTextCategories from './PRPTextCategories.vue';

export default {
  name: 'Categories',

  components: {
    PRPComboCategories,
    PRPTextCategories,
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: var(--grid-outer-whitespace);
}
</style>
