<template>
  <div class="file-drop bg-white rounded-8 border-dashed border-2 border-gray-100 hover:border-gray-500">
    <div class="div-dashed-upload">
      <div class="wrapper flex justify-center">
        <div
          class="drop"
          :class="cadComputationRunning || putAndWaitForResponse ? 'close-up !h-full' : 'show-up !h-full'"
        >
          <FileDropDisplay v-if="!lockedForUser" />
          <div v-else class="cont" :title="lockedForUser ? lockedTitle : ''">
            <i class="file-drop__lock fas fa-lock" :title="lockedTitle" />
          </div>

          <output id="list" />
          <input
            v-if="!lockedForUser"
            id="drag-and-drop-file-input"
            type="file"
            accept=".stl,.stp,.step,.obj,.3mf,.ply,.jpg,.jpeg,.png,.tif,.tiff,.pdf,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.ppt,.pptx,.doc,.docx,.mp4,.mpg,.mpeg"
            multiple
            @input="preUploadFiles"
            @click="resetFileUpload"
            @drop="resetFileUpload"
          />
        </div>
        <div v-if="cadComputationRunning || putAndWaitForResponse" class="flex flex-col gap-16 justify-center">
          <img src="@/assets/img/Logo_Loop_Transparent.gif" class="w-80 mx-auto" />
          <div v-if="showUploadProgress" class="status" v-text="uploadProcessStatus" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

import FileDropDisplay from '@/components/PartWorkflow/FileDropDisplay.vue';

export default {
  name: 'FileDrop',

  components: { FileDropDisplay },

  data() {
    return {
      eventFiles: {},
      cadCounter: 0,
      assetCounter: 0,
      storedFile: null,
      asseTypeOptions: [],
      assetType: null,
      uploadCounter: 0,
      putAndWaitForResponse: false,
      uploadProgressFrontendSide: 0,
      uploadStatus: '',
      currentPage: 1,
    };
  },

  computed: {
    ...mapState([
      'part',
      'prpPartAnalysisDone',
      'partLibraryFilters',
      'assetTypes',
      'popup',
      'unsavedChanges',
      'partUploadError',
    ]),

    ...mapState('prp', ['prpPartAnalysisDone', 'prpParts']),
    ...mapState('application', ['lockedTitle', 'axiosInstanceFileUpload']),
    ...mapGetters([
      'cadComputationRunning',
      'cadStatus',
      'fileConversionFinished',
      'assetAmount',
      'assetAnalysisFinished',
      'lockedForUser',
    ]),

    showUploadProgress() {
      return !this.uploadProcessStatus.includes('100 %');
    },

    overallCadUploadProgress() {
      return this.part.cad_upload_progress;
    },

    uploadProcessStatus() {
      if (this.uploadProgressFrontendSide >= 1 && this.uploadProgressFrontendSide < 100) {
        return `${this.uploadStatus} (${this.uploadProgressFrontendSide} %)`;
      } else if (this.overallCadUploadProgress != '' && this.overallCadUploadProgress != null) {
        return `${this.uploadStatus} (${this.overallCadUploadProgress})`;
      } else {
        return this.uploadStatus;
      }
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.okClicked(popup);
        } else if (popup?.clicked == 'cancel') {
          this.cancelClicked(popup);
        } else if (popup?.clicked == 'close') {
          this.closePopup(popup);
        }
      }
    },

    partUploadError(error) {
      if (error) {
        this.resetPartToDefault();
        this.putAndWaitForResponse = false;
      }
    },

    assetTypes: {
      handler() {
        Object.entries(this.assetTypes).map(type => {
          this.asseTypeOptions.push({ label: type[1].text, value: type[1].value });
        });
      },

      immediate: true,
    },

    cadStatus: {
      handler(status) {
        if (status) {
          this.uploadStatus = status;
        }
      },

      immediate: true,
    },

    prpPartAnalysisDone: {
      async handler(value) {
        if (value) {
          this.putAndWaitForResponse = false;
          let formData = JSON.parse(JSON.stringify(this.partLibraryFilters));
          formData = { ...formData, ...{ page: this.currentPage }, ...{ not_checked_out: true } };
          await this.fetchPrpPartLibraryData(formData);
          this.setPrpPartAnalysisDone(false);
          await this.fetchPrpOrderData();
        }
      },
    },
  },

  methods: {
    ...mapMutations([
      'updatePart',
      'setReloadPartLibrary',
      'updateActiveId',
      'triggerPopup',
      'updateUnsavedChanges',
      'setIsCadless',
      'setPrpPartAnalysisDone',
      'setPartUploadError',
    ]),

    ...mapMutations('prp', ['setPrpPartAnalysisDone']),
    ...mapMutations({
      resetPartToDefault: 'resetPart',
    }),

    ...mapActions('prp', ['fetchPrpPartLibraryData', 'fetchPrpOrderData']),

    resetFileUpload(event) {
      /* Reset the input field so that even multiple uploads of the same file in a row are detected as inputs
          and the upload event is handeled properly */
      event.target.value = null;
      this.resetPartToDefault();
    },

    preUploadFiles(event) {
      /*
      This function requires at least one file, that is uploaded.
      */
      this.cadCounter = 0;
      this.uploadCounter = 0;
      this.assetCounter = 0;
      this.uploadStatus = 'Uploading file(s)';

      this.eventFiles = event.target.files;
      if (this.eventFiles.length > 0) {
        // Check number of files, if cad file already exists and for number of cad files
        if (!this.checkFiles(event, this.eventFiles)) {
          this.resetFileUpload(event);
          return;
        }
        this.upload(this.eventFiles);
      } else {
        this.$root.notify('error', 'No file available', 'No file available for upload. Please try again.', 6000);
      }
    },

    upload() {
      /*
      Upload the eventfiles.
      */
      this.storedFile = this.eventFiles[this.uploadCounter];

      // only upload if filecheck is passed
      if (!this.checkFile(this.storedFile)) {
        // if filecheck is not passed, the counters have to be updated accordingly
        this.uploadCounter++;
        if (this.isCAD(this.storedFile.name)) {
          this.cadCounter -= 1;
        } else {
          this.assetCounter -= 1;
        }
        this.nextUpload();
        return;
      }
      // Upload CAD
      if (this.isCAD(this.storedFile.name)) {
        if (this.part.basename === '') {
          // if first CAD upload without questioning
          this.uploadCad(this.storedFile, this.part.part_id);
        } else {
          // if additional CAD ask what to do
          this.chooseCadOption(this.storedFile);
        }
      } else {
        // choose Asset Type and upload asset from response
        this.chooseAssetType(this.storedFile);
      }
    },

    nextUpload() {
      // trigger next upload as long as more files exist
      if (this.uploadCounter < this.eventFiles.length) {
        this.upload();
      }
    },

    checkFile(file) {
      // Check for file-format. Any supported file format should be listed here.
      let cadFileSizeLimit = 250000000; // 50 Mb
      let assetFileSizeLimit = 50000000; // 50 Mb
      let supported = this.isCAD(file.name) || this.isImage(file.name) || this.isSupportedFile(file.name);
      if (!supported) {
        this.$root.notify(
          'error',
          'Invalid format',
          'The file ' +
            file.name +
            ' has an invalid file format. Please upload supported file formats (' +
            'CAD: .stl, .stp, .step, .obj, .3mf and .ply, ' +
            'IMG: .jpeg, .jpg, .png, .tif, .tiff, ' +
            'PDF: .pdf, ' +
            'EXCEL: .xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, ' +
            'POWERPOINT: .ppt, .pptx, ' +
            'WORD: .doc, .docx, ' +
            'VIDEO: .mp4, .mpg, .mpeg',
          10000
        );
        return false;
      }
      // Check for file-size, currently limit by 50mb
      if ((this.isImage(file.name) || this.isSupportedFile(file.name)) && file.size > assetFileSizeLimit) {
        this.$root.notify(
          'error',
          'File too large',
          'The file ' + file.name + ' is too large. Maximum allowed file size is 250 Mb for assets.',
          6000
        );
        return false;
      }
      if (this.isCAD(file.name) && file.size > cadFileSizeLimit) {
        this.$root.notify(
          'error',
          'File too large',
          'The file ' + file.name + ' is too large. Maximum allowed file size is 250 Mb for CAD files.',
          6000
        );
        return false;
      }
      return true;
    },

    checkFiles(event, eventFiles) {
      /* This function checks for number of files uploaded (general) */
      let uploadBool = true;

      // Check for number of files
      if (eventFiles.length > 10) {
        this.$root.notify('error', 'Too many files', 'Only 10 files can be uploaded at a time.', 6000);
        /* Reset the input field so that even multiple uploads of the same file in a row are detected as inputs
          and the upload event is handeled properly */
        this.resetFileUpload(event);
        uploadBool = false;
      }

      // Check for number of cad files
      Array.from(eventFiles).forEach(file => {
        if (this.isCAD(file.name)) {
          this.cadCounter++;
        } else {
          this.assetCounter++;
        }
      });

      return uploadBool;
    },

    chooseCadOption(file) {
      // Popup with decision for more than one cad file: Overwrite or Attach
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Additional CAD file',
        message:
          'More than one CAD file detected. Please choose whether to overwrite ' +
          this.part.basename +
          ' with ' +
          file.name +
          ' or to attach as an asset.',
        buttons: true,
        buttonContent: [
          { text: 'Attach', type: 'outlined', value: 'cancel' },
          { text: 'Overwrite', type: 'secondary', value: 'ok' },
        ],
      });
    },

    chooseAssetType(file) {
      // Popup with asset type decision: technical drawing (drawing), Image (image), RFQ (rfq), Offer/Quote (offer), Invoice (invoice), Document (document), other (misc)
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Asset Type',
        message: `Please choose the type of the file "${file.name}"`,
        selectOptions: this.asseTypeOptions,
        type: 'single',
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    getExtension(filename) {
      let parts = filename.split('.');
      return parts[parts.length - 1];
    },

    isCAD(filename) {
      // check for supported file formats
      let ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'stl':
        case 'stp':
        case 'step':
        case 'obj':
        case '3mf':
        case 'ply':
          return true;
      }
      return false;
    },

    isImage(filename) {
      // check for most common file formats
      let ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'tiff':
        case 'tif':
          // case "psd":
          // case "eps":
          // case "ai":
          return true;
      }
      return false;
    },

    isSupportedFile(filename) {
      // check for supported file formats
      let ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'pdf':
        case 'xlsx':
        case 'xlsm':
        case 'xlsb':
        case 'xltx':
        case 'xltm':
        case 'xls':
        case 'ppt':
        case 'pptx':
        case 'doc':
        case 'docx':
        case 'mp4':
        case 'mpg':
        case 'mpeg':
          return true;
      }
      return false;
    },

    // selectedAssetType(event) {
    //   for (const [key, value] of Object.entries(this.assetTypes)) {
    //     if (value.value === event) {
    //       this.assetType = value.value;
    //       break;
    //     }
    //   }
    // },

    okClicked(popup) {
      // handle additional cad file
      // Overwrite
      if (popup?.title === 'Additional CAD file') {
        this.triggerPopup('');
        this.uploadCad(this.storedFile, this.part.part_id);
        this.setIsCadless(false);
      }
      // handle asset file
      // event true: Ok, false: Cancel
      else if (popup?.title === 'Asset Type') {
        // check if selection was made
        this.assetType = popup?.formData;
        if (this.assetType == null) {
          this.$root.notify('error', 'No selection', 'Please select an option or cancel.', 6000);
        } else {
          this.triggerPopup('');
          this.uploadAsset(this.storedFile, this.part.part_id);
        }
      }
    },

    cancelClicked(popup) {
      // Attach
      if (popup?.title === 'Additional CAD file') {
        this.triggerPopup('');
        this.assetType = 'cad';
        this.uploadAsset(this.storedFile, this.part.part_id);
      }

      // handle asset file
      // event true: Ok, false: Cancel
      else if (popup?.title === 'Asset Type') {
        this.closePopup();
      }
    },

    closePopup(popup) {
      this.triggerPopup('');
      if (popup?.title === 'Additional CAD file') {
        this.cancelCADUpload();
      } else if (popup?.title === 'Asset Type') {
        this.cancelAssetUpload();
      }
    },

    cancelCADUpload() {
      this.uploadCounter++;
      this.cadCounter -= 1;
      this.nextUpload();
    },

    cancelAssetUpload() {
      // If Asset upload was canceled it will not be uploaded and proceed to next file
      this.uploadCounter++;
      this.assetCounter -= 1;
      this.nextUpload();
    },

    async uploadCad(file, part_id) {
      this.uploadCounter++;
      let formData = new FormData();
      formData.append('file', file);
      formData.append('part_id', part_id);
      formData.append('lot_size', this.part.lot_size);
      formData.append('is_prp_part', true);

      // if additional CAD file is uploaded and the user chooses to overwrite the cad file,
      // overwrite has to be set in formdata
      if (this.part.basename !== '') {
        formData.append('overwrite_cad', true);
      }

      this.putAndWaitForResponse = true;
      await this.axiosInstanceFileUpload
        .post('/api/v1/part/', formData, {
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.uploadProgressFrontendSide = percentCompleted;
          },
        })
        .then(response => {
          this.updatePart(response.data);
          // this.putAndWaitForResponse = false;
          if (!part_id) {
            this.updateActiveId(response.data.part_id);
          }
          this.cadCounter -= 1;

          // check for unsaved changes
          if (this.unsavedChanges) {
            this.savePart(this.unsavedChanges);
            this.updateUnsavedChanges(undefined);
          }

          this.nextUpload();
        })
        .catch(error => {
          this.putAndWaitForResponse = false;
          this.$root.notify('error', error.response.data.error_message, 'Please upload file again', 6000);
        });
    },

    savePart(formData) {
      // This function will parse the current 'part' instance to the database.
      this.axiosInstanceFileUpload
        .put(`/api/v1/part/${this.part.part_id}/`, formData)
        .then(response => {
          this.updatePart(response.data);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },

    async uploadAsset(file, part_id) {
      this.uploadCounter++;

      // Throw warning when the file already exists - currently it will be overwritten
      if (this.assetAmount > 0) {
        let assets = this.part.assets;
        for (let key in assets) {
          // skip loop if the property is from prototype
          if (!Object.prototype.hasOwnProperty.call(assets, key)) continue;

          let obj = assets[key];

          if (file.name === obj.basename) {
            this.$root.notify('warning', 'Duplicate', 'The file ' + obj.basename + ' was overwritten.', 3000);
          }
        }
      }

      // decrease number of cad files, if a cad file is uploaded as an asset
      if (this.isCAD(file.name)) {
        this.cadCounter -= 1;
        // if a cad file is handeled as an asset, the asset counter has to be increased
        this.assetCounter += 1;
      }
      let formData = new FormData();
      formData.append('file', file);
      formData.append('asset_type', this.assetType);
      formData.append('overwrite', 'true'); // true: if there is a duplicate - overwrite the file

      // reset asset type, so that dropdown selection is clean for each new asset
      this.assetType = null;

      // make sure to have a valid url to post on
      if (part_id === '') {
        part_id = 0;
      }

      // upload any supported asset file
      await this.axiosInstanceFileUpload
        .post(`/api/v1/part/${part_id}/assets/`, formData)
        .then(response => {
          this.updatePart(response.data);
          this.updateActiveId(response.data.part_id);
          this.assetCounter -= 1;
          this.nextUpload();

          this.$root.notify('success', 'File uploaded', 'The file was successfully uploaded.', 3000);
        })
        .catch(() => {
          this.$root.notify('error', 'File upload failed', 'Please try again.', 6000);
        });
    },
  },
};
</script>

<style scoped lang="scss">
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.file-drop {
  padding-bottom: 5px;
}

.drop {
  width: 100%;
  min-width: 250px;
  height: 420px;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  background: transparent;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  height: 100%;
}

.uploading {
  width: 100%;
  height: 220px;
  overflow: hidden;
  text-align: center;
  background: transparent;
  position: relative;
  top: 30%;
}

.drop .cont {
  width: 100%;
  height: 170px;
  color: #8e99a5;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.file-drop__lock {
  color: var(--primary-color) !important;
  margin-top: 60px;
}

.drop .cont i {
  font-size: 40px;
  color: #787e85;
  position: relative;
}

.drop .cont .tit {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 900;
}

.drop .cont .desc {
  color: #787e85;
  font-size: 18px;
}

.drop .cont .browse {
  margin: 10px 25%;
  color: var(--light-color);
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--primary-color-light);
}

.drop input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: red;
  opacity: 0;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#list {
  width: 100%;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
}

.div-dashed-upload {
  height: 420px;
}

.show-up {
  position: relative;
  padding-top: 106px;
  height: 220px;
  overflow: hidden;
  text-align: center;
  display: block;
  opacity: 0;
  /* transition: all 0.5s ease-out; */
  animation-name: showing-up;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}

.close-up {
  position: relative;
  height: 220px;
  overflow: hidden;
  text-align: center;
  display: none;
  opacity: 1;
  /* transition: all 0.5s ease-out; */
  animation-name: closing-up;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}

@keyframes showing-up {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closing-up {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.status {
  font-size: var(--12px);
  text-align: center;
  position: relative;
}

.logo-gif {
  position: relative;
  width: 85px;
}
</style>
