<template>
  <div>
    <div class="spark-new-input-group">
      <select v-model="optimizationStyle" required class="spark-new-select-input" :disabled="disabledSetting">
        <option v-for="(label, key) in optimizationStyles" :key="key" :value="key">
          {{ label }}
        </option>
      </select>
      <label class="spark-new-select-label">Orientation Optimization Style</label>
    </div>
    <div class="ml-16 mb-16 grey-subinfo">
      {{ optimizationStyleInfo[optimizationStyle] }}
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'DisplayOrientationOptimizationSetting',

  props: {
    disabledSetting: Boolean,
    chain: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['setting-selected'],

  data() {
    return {
      optimizationStyleInfo: {
        alpha_beta_gamma: 'Optimize using all degrees of freedom.',
        gamma_only: 'Optimize by rotating part on build plate normal.',
        flat: 'Lay on outer flat surface and optimize by rotating in this plane.',
        fixed: 'Preserve original orientation.',
        custom: 'Pick a face or set angles manually (Euler XYZ). Go to canvas for that option.',
      },
    };
  },

  computed: {
    ...mapState(['possibleOptimizationStyles']),
    optimizationStyle: {
      get() {
        return this.chain?.opt_style;
      },

      set(value) {
        let data = { uid: this.chain.process_chain_id, setting: value };
        this.updateOptimizationStyle(data);
        let formData = { opt_style: value };
        this.$emit('setting-selected', formData);
      },
    },

    optimizationStyles() {
      return this.possibleOptimizationStyles;
    },
  },

  methods: {
    ...mapMutations(['updateOptimizationStyle']),
  },
};
</script>
