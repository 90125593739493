import store from '@/store/index.js';

export function processChainComputationRunning(uid) {
  if (uid != undefined || 0) {
    let chains = store.getters.processChains;
    let actualChain = chains[uid];
    if (actualChain != undefined) {
      if (actualChain.hasOwnProperty('status')) {
        return actualChain.status == 'running';
      } else {
        return false;
      }
    }
  }
}

export function computationRunning(uid) {
  /* A getter that returns a bool that indicates weather some kind of computation is currently running.
  This can be used to provide the user from making inputs during running computations. */

  // computation running can mean cad and / or process chain is processed
  return store.getters.cadComputationRunning || processChainComputationRunning(uid);
}

export function materialChangeEnabled() {
  return !computationRunning(uid) && store.getters.fileConversionFinished;
}

export function analysisFinished(uid) {
  if (uid != undefined || uid != 0) {
    let chains = store.getters.processChains;
    let actualChain = chains[uid];
    if (actualChain != undefined) {
      if (actualChain.hasOwnProperty('status')) {
        return actualChain.status == 'finished';
      } else {
        return false;
      }
    }
  }
}

export function processChainStatus(uid) {
  let chains = store.getters.processChains;
  let actualChain = chains[uid];
  return actualChain?.process_chain_status;
}

export function processChainInitializationFinished(uid) {
  let chains = store.getters.processChains;
  if (uid != undefined) {
    let actualChain = chains[uid];
    if (actualChain != undefined && actualChain.hasOwnProperty('status')) {
      return actualChain.status == 'initialized';
    } else {
      return false;
    }
  }
}

export function resultsOutdated(uid) {
  if (uid != undefined) {
    let chains = store.getters.processChains;
    let actualChain = chains[uid];
    if (actualChain.hasOwnProperty('outdated')) {
      return actualChain.outdated == true;
    } else {
      return false;
    }
  }
}

export function analysisInputsComplete(uid) {
  let chains = store.getters.processChains;
  let actualChain = chains[uid];

  if (
    (store.state.part.basename !== '' || store.state.part.is_cadless) &&
    actualChain?.mat_id !== '' &&
    actualChain?.mat_id != null
  ) {
    return true;
  } else {
    return false;
  }
}
