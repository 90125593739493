import Account from '../../views/Account/Account.vue';
import Team from '../../views/Account/team/Team.vue';
import UserAccount from '../../views/Account/useraccount/UserAccount.vue';

const subNavigations = {
  account: [
    {
      label: 'User Account',
      value: 'user-account',
      to: { name: `user-account` },
    },
    {
      label: 'Team',
      value: 'team',
      to: { name: `team` },
    },
  ],
};

const routes = [
  {
    path: '/account',
    redirect: '/account/user-account',
    component: Account,
    meta: {
      title: 'User Account',
      showSubNavigation: true,
      subNavigationHeading: 'User Account',
      requireLogin: true,
      requireStaff: false,
      requireInternal: false,
      requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
    },
    children: [
      {
        path: 'user-account',
        name: 'user-account',
        component: UserAccount,
        meta: {
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          title: 'User Account',
          requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
          subNavigation: subNavigations.account,
        },
      },
      {
        path: 'team',
        name: 'team',
        component: Team,
        meta: {
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          title: 'Team',
          requiredRole: ['sparkmanager'],
          subNavigation: subNavigations.account,
        },
      },
    ],
  },
];

export default routes;
