<template>
  <div class="flex flex-col bg-white;">
    <div class="mb-10">
      <PartNameFilter :reset="reset" @selected="addFilter($event)" />
    </div>
    <template v-if="!user.is_external">
      <div v-for="(component, label) in enabledPartLibraryFilters" :key="label" class="mb-10">
        <component
          :is="component"
          class="w-full"
          :component="component"
          :label="label"
          :reset="reset"
          parent="library"
          @selected="addFilter($event)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import PipelineStatusFilter from '../../../../components/Reusable/Filter/PipelineStatusFilter.vue';

import BatchFilter from '@/components/Reusable/Filter/BatchFilter.vue';
import ComboCategoryFilter from '@/components/Reusable/Filter/ComboCategoryFilter.vue';
import DateFilter from '@/components/Reusable/Filter/DateFilter.vue';
import MaterialFilter from '@/components/Reusable/Filter/MaterialFilter.vue';
import OwnFilter from '@/components/Reusable/Filter/OwnFilter.vue';
import PartNameFilter from '@/components/Reusable/Filter/PartNameFilter.vue';
import PartOwnerFilter from '@/components/Reusable/Filter/PartOwnerFilter.vue';
import PrpPartsFilter from '@/components/Reusable/Filter/PrpPartsFilter.vue';
import ProcessFilter from '@/components/Reusable/Filter/ProcessFilter.vue';
import Reset from '@/components/Reusable/Filter/Reset.vue';
import TextCategoryFilter from '@/components/Reusable/Filter/TextCategoryFilter.vue';
import ValidationFilter from '@/components/Reusable/Filter/ValidationFilter.vue';

export default {
  name: 'PartLibraryFilter',

  components: {
    PartNameFilter,
    MaterialFilter,
    DateFilter,
    ValidationFilter,
    OwnFilter,
    PrpPartsFilter,
    ComboCategoryFilter,
    TextCategoryFilter,
    PartOwnerFilter,
    BatchFilter,
    ProcessFilter,
    Reset,
    PipelineStatusFilter,
  },

  props: {
    reset: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      filterSettings: {},
    };
  },

  computed: {
    ...mapState(['possiblePartLibraryFilters', 'user', 'partLibraryFilters']),
    ...mapState('application', ['axiosInstance']),

    enabledPartLibraryFilters() {
      return { ...this.possiblePartLibraryFilters.enabled, ...this.possiblePartLibraryFilters.disabled };
    },
  },

  watch: {
    user: {
      handler() {
        this.getPossiblePartLibraryFilters();
      },
    },
  },

  created() {
    this.getPossiblePartLibraryFilters();
  },

  methods: {
    ...mapMutations(['updateFilterLibrary', 'setPossiblePartLibraryFilters']),

    addFilter(event) {
      if (
        Object.prototype.hasOwnProperty.call(event, 'combo_categories') &&
        Object.prototype.hasOwnProperty.call(this.filterSettings, 'combo_categories')
      ) {
        let combo_filter = { ...this.filterSettings?.combo_categories, ...event['combo_categories'] };
        this.filterSettings.combo_categories = combo_filter;
      } else if (
        Object.prototype.hasOwnProperty.call(event, 'text_categories') &&
        Object.prototype.hasOwnProperty.call(this.filterSettings, 'text_categories')
      ) {
        let text_filter = { ...this.filterSettings?.text_categories, ...event['text_categories'] };
        this.filterSettings.text_categories = text_filter;
      } else {
        this.filterSettings = { ...this.filterSettings, ...event };
      }
      this.updateFilterLibrary(this.filterSettings);
      sessionStorage.setItem('libraryFilter', JSON.stringify(this.partLibraryFilters));
    },

    getPossiblePartLibraryFilters() {
      let defaultPossibleFilters = {
        disabled: {
          'Own Parts': 'OwnFilter',
          Date: 'DateFilter',
          Owner: 'PartOwnerFilter',
        },

        enabled: {
          Material: 'MaterialFilter',
          ProcessFilter: 'ProcessFilter',
          PipelineStatus: 'PipelineStatusFilter',
          Validation: 'ValidationFilter',
          BatchFilter: 'BatchFilter',
          'PRP Parts': 'PrpPartsFilter',
        },
      };
      if (this.user.organization.combo_categories && !this.user.is_external) {
        Object.values(this.user.organization.combo_categories).forEach(cat => {
          defaultPossibleFilters.disabled = {
            ...defaultPossibleFilters.disabled,
            ...{ [cat.label]: 'ComboCategoryFilter' },
          };
        });
      }

      if (this.user.organization.text_categories && !this.user.is_external) {
        Object.values(this.user.organization.text_categories).forEach(cat => {
          defaultPossibleFilters.disabled = {
            ...defaultPossibleFilters.disabled,
            ...{ [cat.label]: 'TextCategoryFilter' },
          };
        });
      }
      this.setPossiblePartLibraryFilters(defaultPossibleFilters);
    },
  },
};
</script>
