<template>
  <div>
    <div class="text-20" v-text="'PRP Footnotes'" />
    <div v-if="showFootnotesInput" class="flex items-center gap-8">
      <textarea
        id="footnotes"
        v-model="footnotes"
        placeholder="Add or edit footnotes here"
        class="rounded-4"
        name="footnotes"
        cols="60"
        rows="5"
      />
      <i class="cursor-pointer fas fa-check" @click="saveFootnotes" />
      <i class="cursor-pointer fas fa-xmark" @click="cancelFootnotes" />
    </div>
    <div v-else class="max-w-[500px] flex gap-24 items-center">
      <p v-if="footnotes" class="italic bg-gray-100" v-text="footnotes" />
      <div v-else v-text="'No Footnotes provided. Click edit to add.'" />
      <span class="flex gap-8">
        <i class="cursor-pointer fas fa-edit" @click="addFootnotes" />
      </span>
    </div>

    <div class="text-20 mt-32" v-text="'PRP Checkout Info'" />
    <div v-if="showCheckoutInfoInput" class="flex items-center gap-8">
      <div class="flex flex-col">
        <textarea
          id="checkout_info"
          v-model="checkoutInfo"
          placeholder="Add or edit checkout information here"
          class="rounded-4"
          :class="{ 'border border-red-500': errors['checkout_info'] }"
          name="checkout_info"
          cols="60"
          rows="5"
        />
        <span v-if="errors['checkout_info']" class="text-red-500 text-11" v-text="errors['checkout_info']" />
      </div>
      <i class="cursor-pointer fas fa-check" @click="validateFields" />
      <i class="cursor-pointer fas fa-xmark" @click="cancelCheckoutInfo" />
    </div>
    <div v-else class="max-w-[500px] flex gap-24 items-center">
      <p v-if="checkoutInfo" class="italic bg-gray-100" v-text="checkoutInfo" />
      <span class="flex gap-8">
        <i class="cursor-pointer fas fa-edit" @click="addCheckoutInfo" />
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { z } from 'zod';

export default {
  name: 'FootnotesCheckoutInfo',

  components: {},

  data() {
    return {
      footnotes: '',
      checkoutInfo: '',
      showCheckoutInfoInput: false,
      showFootnotesInput: false,
      errors: {},
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),

    schema() {
      return z.object({
        checkout_info: z.string().min(5, 'Required'),
      });
    },

    form() {
      return {
        checkout_info: this.checkoutInfo,
      };
    },
  },

  mounted() {
    this.footnotes = this.user.organization.prp_footnotes;
    this.checkoutInfo = this.user.organization.prp_checkout_info;
  },

  methods: {
    addFootnotes() {
      this.showFootnotesInput = true;
    },

    cancelFootnotes() {
      this.footnotes = this.user.organization.prp_footnotes;
      this.showFootnotesInput = false;
    },

    cancelCheckoutInfo() {
      this.checkoutInfo = this.user.organization.prp_checkout_info;
      this.showCheckoutInfoInput = false;
    },

    addCheckoutInfo() {
      this.showCheckoutInfoInput = true;
    },

    async saveFootnotes() {
      await this.axiosInstance
        .put('api/v1/organization-settings/', { prp_footnotes: this.footnotes })
        .then(() => {
          this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
          this.$root.notify('success', 'Footnotes', 'Footnotes saved successfully.', 3000);
          this.showFootnotesInput = false;
        })
        .catch(error => {
          console.error('Error saving footnotes:', error);
          this.showFootnotesInput = false;
        });
    },

    async saveCheckoutInfo() {
      if (Object.keys(this.errors).length > 0) {
        return;
      }
      await this.axiosInstance
        .put('api/v1/organization-settings/', { prp_checkout_info: this.checkoutInfo })
        .then(() => {
          this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
          this.$root.notify('success', 'Checkout Info', 'Checkout information saved successfully.', 3000);
          this.showCheckoutInfoInput = false;
        })
        .catch(error => {
          console.error('Error saving checkout information:', error);
          this.showCheckoutInfoInput = false;
        });
    },

    validateFields() {
      this.errors = {};
      const result = this.schema.safeParse(this.form);
      const errors = result.error?.issues;
      if (!errors) {
        this.saveCheckoutInfo();
        return;
      }

      this.errors = errors.reduce((acc, issue) => {
        acc[issue.path[0]] = issue.message;
        return acc;
      }, {});
    },
  },
};
</script>
