<template>
  <table class="spark-table">
    <tr>
      <router-link to="/get-started"><i class="fas fa-arrow-right" />&nbsp;Get Started</router-link>
    </tr>
    <tr>
      <a href="https://blog.3dspark.de/members-only/faq/" target="_blank">
        <i class="fas fa-arrow-right" />&nbsp;Frequently Asked Questions
      </a>
    </tr>
    <tr>
      <a href="https://additivemanufacturing.com/basics/" target="_blank">
        <i class="fas fa-arrow-right" />&nbsp;Additive Manufacturing Basics
      </a>
    </tr>
    <tr>
      <a href="https://link.springer.com/chapter/10.1007/978-3-031-05863-9_1" target="_blank">
        <i class="fas fa-arrow-right" />&nbsp;Introduction to Additive Manufacturing
      </a>
    </tr>
    <tr>
      <a href="https://standardsdevelopment.bsigroup.com/committees/50226095#published" target="_blank">
        <i class="fas fa-arrow-right" />&nbsp;Standards from British Standards Institution (BSI)
      </a>
    </tr>
    <tr>
      <a target="_blank" href="https://blog.3dspark.de/members-only/release-notes"
        ><i class="fas fa-arrow-right" />&nbsp;Release Notes
      </a>
    </tr>
    <tr>
      <a target="_blank" href="https://www.3dspark.de/general-terms-and-conditions"
        ><i class="fas fa-arrow-right" />&nbsp;General Terms and Conditions</a
      >
    </tr>
  </table>
</template>

<script>
export default {
  name: 'References',

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
* {
  transition: all 0.3s ease-in;
}

a {
  text-decoration: none;
  color: black;

  i {
    transform: translateX(-3px);
    opacity: 0;
  }
}

a:hover i {
  opacity: 1;
  transform: translateX(0px);
}
</style>
