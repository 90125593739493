<template>
  <div class="mb-150">
    <MaterialTable />
  </div>
</template>

<script>
import MaterialTable from './components/MaterialTable.vue';

export default {
  name: 'Materials',

  components: { MaterialTable },
};
</script>
