import { MeshBasicMaterial, Mesh, Vector3, ArrowHelper } from 'three';

import { FontLoader } from '@/assets/js/3d_viewer/FontLoader.js';
import { TextGeometry } from '@/assets/js/3d_viewer/TextGeometry.js';

export class CoordSystem {
  constructor(camera, scene, control) {
    this.camera = camera;
    this.scene = scene;
    this.control = control;

    this.createArrows();
    this.createLabelsCoordinateSystem(this.camera, this.scene);

    this.addListener();
  }

  createArrows() {
    var arrowOrigin = new Vector3(0, 0, 0);
    let arrowLength = 60;
    let arrowHeadLength = 20;
    let arrowHeadWidth = 10;

    const makeArrow = (vec, color) =>
      new ArrowHelper(
        new Vector3(vec[0], vec[1], vec[2]),
        arrowOrigin,
        arrowLength,
        color,
        arrowHeadLength,
        arrowHeadWidth
      );

    this.scene.add(makeArrow([1, 0, 0], 0x7f2020));
    this.scene.add(makeArrow([0, 1, 0], 0x207f20));
    this.scene.add(makeArrow([0, 0, 1], 0x20207f));
  }

  createLabelsCoordinateSystem(camera, scene) {
    // camera & scene must be passed explicitly. Using this.camera does not work somehow.

    const fontLoader = new FontLoader();
    fontLoader.load('/assets/three/helvetiker_regular.typeface.json', function (font) {
      for (const coordinate of ['X', 'Y', 'Z']) {
        const textCoordinates = new TextGeometry(coordinate, {
          font: font,
          size: 15,
          height: 0,
        });

        let textMaterial = new MeshBasicMaterial({ color: 'black' });
        let textMesh = new Mesh(textCoordinates, textMaterial);
        textMesh.name = 'coordinate_label_' + coordinate;

        textMesh.position[coordinate.toLowerCase()] = 60;
        const rot = camera.rotation;
        textMesh.rotation.set(rot.x, rot.y, rot.z);

        scene.add(textMesh);
      }
    });
  }

  addListener() {
    this.control.addEventListener('change', this.handleSceneRotationPerMouse);
  }

  removeListener() {
    this.control.removeEventListener('change', this.handleSceneRotationPerMouse);
  }

  handleSceneRotationPerMouse() {
    const rot = this.camera.rotation;
    this.scene.children.forEach(element => {
      if (element.name.startsWith('coordinate_label_')) {
        element.rotation.set(rot.x, rot.y, rot.z);
      }
    });
  }
}
