<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="flex flex-col">
        <div>Delete Part</div>
      </div>
    </template>
    <template #default>
      <div>
        Do you want to delete the part <b>{{ partToDelete.part_name }}</b> from the calibration list?
      </div>
      <div class="flex gap-8 my-8 justify-end">
        <SparkButton variant="outlined" @click="close()"><div v-text="'Cancel'" /></SparkButton>
        <SparkButton variant="secondary" @click="deletePart()"><div v-text="'Delete'" /></SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'DeletePartModal',

  components: {
    SparkModal,
    SparkButton,
  },

  props: {
    show: { type: Boolean },
    partToDelete: {
      type: Object,
      default() {
        return {};
      },
    },

    indexToDelete: { type: Number, default: 0 },
  },

  emits: ['close', 'select'],

  computed: {
    ...mapState('calibration', ['transformedPartsData']),
  },

  methods: {
    ...mapMutations('calibration', ['deletePartFromTransformedPartData']),

    close() {
      this.$emit('close');
    },

    async deletePart() {
      this.deletePartFromTransformedPartData(this.indexToDelete);
      this.$emit('close');
    },
  },
};
</script>
