<template>
  <div
    v-if="!editStep"
    class="flex flex-col overflow-hidden rounded-8 p-8 gap-8 border border-gray-100 hover:bg-gray-50 hover:cursor-pointer group grow"
    @click="openEditStep()"
  >
    <div :title="step.name" class="flex justify-between items-center">
      <p class="font-semibold truncate max-w-200" :title="step.name">{{ step.name }}</p>
      <div v-if="property !== 'co2'" class="text-white group-hover:text-primary-500 px-8">
        <i class="fas fa-edit" />
      </div>
      <div v-if="property === 'cost'" class="flex">
        <p class="">{{ cost }}</p>
        <p class="text-gray-400 pl-4">€/{{ formattedLevel() }}</p>
      </div>
      <div v-else-if="property === 'leadtime'" class="flex">
        <p class="">{{ leadtime }}</p>
        <p class="text-gray-400 pl-4">d</p>
      </div>
      <div v-else-if="property === 'co2'" class="flex">
        <p class="">{{ co2 }}</p>
        <p class="text-gray-400 pl-4">kg CO²-eq</p>
      </div>
    </div>
    <div v-if="property !== 'co2'" class="p-3 text-11 flex gap-8 items-center">
      <div class="flex justify-start items-center mb-2 gap-8">
        <span class="text-gray-600">Labor</span>
        <span>{{ formatTime(laborTime) }}</span>
      </div>
      <div class="flex w-[3px] h-[3px] bg-gray-400 rounded-full" />
      <div class="flex justify-start items-center mb-2 gap-8">
        <span class="text-gray-600">Machine</span>
        <span>{{ formatTime(machineTime) }}</span>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-start items-center mb-2 gap-8 text-11">
        <span class="text-gray-600">Machine Energy Consumption</span>
        <span v-if="step.co2[co2EcLevel]">{{ $formatTwoDecimalPlaces(co2 - step.co2[co2EcLevel]) }}</span>
        <span v-else>-</span>
      </div>
      <div class="flex w-[3px] h-[3px] bg-gray-400 rounded-full" />
      <div class="flex justify-start items-center mb-2 gap-8 text-11">
        <span class="text-gray-600">Embodied Carbon</span>
        <span v-if="step.co2[co2EcLevel]">{{ $formatTwoDecimalPlaces(step.co2[co2EcLevel]) }}</span>
        <span v-else>-</span>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col overflow-hidden rounded-8 p-8 gap-8 border border-gray-100 group grow">
    <div>
      <div>
        <div class="flex justify-between items-center gap-8 mb-4">
          <div class="flex gap-8 items-center">
            <div class="pl-8 font-semibold">{{ step.name }}</div>

            <div
              v-if="step.step_type != 'main'"
              title="Delete this step from process chain"
              @click="deleteProcessStep()"
            >
              <div class="text-gray-600 hover:text-primary-500 hover:cursor-pointer">
                <i class="fas fa-trash-alt" />
              </div>
            </div>
            <div v-if="userFeedback" title="Reset Manual Overwrites">
              <i
                class="fas fa-undo text-gray-600 hover:text-primary-500 hover:cursor-pointer"
                @click="resetFeedbackStep"
              />
            </div>
          </div>
          <div title="Close edit mode" @click="editStep = false">
            <div class="text-gray-600 hover:text-primary-500 hover:cursor-pointer"><i class="fas fa-times" /></div>
          </div>
        </div>
        <div class="flex justify-end items-center bg-gray-50 py-8">
          <div v-if="property === 'cost'" class="flex justify-start items-baseline">
            <FeedbackValue
              :feedback-value="cost"
              field-type="normal"
              field-style="font-size: 13px; text-align: right; font-weight: bold;"
              :indent-type="0"
              :colspan="2"
              @feedback-value-changed="setCost($event)"
            />
            <div>€/{{ formattedLevel() }}</div>
          </div>
          <div v-if="property === 'leadtime'" class="flex items-center">
            <FeedbackValue
              :feedback-value="leadtime"
              field-name=""
              field-unit="d"
              field-type="normal"
              field-style="text-align: right; font-weight: bold;"
              unit-field-style="margin-right:8px;"
              :indent-type="0"
              :colspan="2"
              @feedback-value-changed="setLeadtime($event)"
            />
          </div>
        </div>
        <table class="w-full text-13 mt-16">
          <tbody>
            <tr>
              <td class="w-1/3">
                <div>
                  <div class="ml-8 text-11">Labor</div>
                  <div class="text-gray-500 text-9 ml-8">hh:mm:ss</div>
                </div>
              </td>
              <td class="w-2/3 pr-4">
                <FeedbackValueTimespan
                  :feedback-value="laborTime"
                  editable
                  @feedback-value-changed="setTime($event, 'labor')"
                  @input-focus-active="checkValidity($event)"
                />
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <div class="ml-8 text-11">Machine</div>
                  <div class="text-gray-500 text-9 ml-8">hh:mm:ss</div>
                </div>
              </td>
              <td class="pr-4">
                <FeedbackValueTimespan
                  :feedback-value="machineTime"
                  editable
                  title="Includes setup and unloading."
                  @feedback-value-changed="setTime($event, 'mac_t')"
                  @input-focus-active="checkValidity($event)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeedbackValueTimespan from '../../Reusable/FeedbackValues/FeedbackValueTimespan.vue';

import FeedbackValue from '@/components/Reusable/FeedbackValues/FeedbackValue.vue';
import { floatFormat } from '@/helpers.js';

export default {
  name: 'ProcessStep',

  components: { FeedbackValue, FeedbackValueTimespan },

  props: {
    step: {
      type: Object,
      default() {
        return {};
      },
    },

    level: {
      type: Object,
      default() {
        return {};
      },
    },

    property: {
      type: String,
      default: 'cost',
    },
  },

  data() {
    return {
      cost: 0,
      co2: 0,
      showWarning: false,
      machineTime: 0,
      laborTime: 0,
      leadtime: 0,
      editStep: false,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    co2EcLevel() {
      return 'co2_ec' + [Object.keys(this.level)[0]];
    },

    userFeedback() {
      if (this.step?.overwrites != undefined) {
        return Object.keys(this.step.overwrites) != 0;
      } else {
        return false;
      }
    },

    processStepUid() {
      return this.step.process_step_id;
    },
  },

  watch: {
    step() {
      this.calculate();
    },

    level() {
      this.calculate();
    },
  },

  mounted() {
    this.calculate();
  },

  methods: {
    ...mapMutations(['updatePart']),

    openEditStep() {
      if (this.property !== 'co2') {
        this.editStep = true;
      }
    },

    formatTime(timespan) {
      const hours = Math.floor(timespan);
      const minutes = Math.floor((timespan - hours) * 60);
      const seconds = Math.round(((timespan - hours) * 60 - minutes) * 60);
      return `${hours}h ${minutes}m ${seconds}s`;
    },

    roundValue(number, digit = 2) {
      return parseFloat(floatFormat(number, digit));
    },

    checkValidity(event) {
      this.showWarning = event;
    },

    calculate() {
      this.cost = this.roundValue(this.step.cost['cost' + Object.keys(this.level)[0]]).toFixed(2);
      this.laborTime = this.step.labor['labor' + Object.keys(this.level)[0]];
      this.machineTime = this.step.mac_t['mac_t' + Object.keys(this.level)[0]];
      this.leadtime = this.step.leadtime;
      this.co2 = this.roundValue(this.step.co2['co2' + Object.keys(this.level)[0]]).toFixed(2);
    },

    async setCost(value) {
      let formData = {
        ['cost' + Object.keys(this.level)[0]]: value,
      };
      this.updateProcessStepFeedback(formData);
    },

    async setTime(value, timefield) {
      // [timefield + this.selection] --> 'labor_pl', 'labor_pj'...
      let formData = {
        [timefield + Object.keys(this.level)[0]]: value,
      };
      this.updateProcessStepFeedback(formData);
    },

    async setLeadtime(value) {
      let formData = { leadtime: value };
      this.updateProcessStepFeedback(formData);
    },

    async resetFeedbackStep() {
      let formData = new FormData();
      formData.append('reset_feedback_step', true);
      this.updateProcessStepFeedback(formData);
    },

    async updateProcessStepFeedback(formData) {
      this.axiosInstance.put(`/api/v1/process-step-feedback/${this.processStepUid}/`, formData).catch(error => {
        this.$root.notifyError('Editing Process Step', error);
      });
    },

    formattedLevel() {
      let levelString = Object.keys(this.level)[0];
      if (levelString === '_pl') {
        return 'Lot';
      } else if (levelString === '_pj') {
        return 'Job';
      } else if (levelString === '_pp') {
        return 'Part';
      }
    },

    async deleteProcessStep() {
      if (this.step.step_type != 'main') {
        let formData = new FormData();
        formData.append('process_step_id', this.processStepUid);

        this.axiosInstance
          .delete(`/api/v1/process-step/`, { data: formData })
          .then(response => {
            this.updatePart(response.data);
          })
          .catch(error => {
            this.$root.notifyError('Deleting Process Step', error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}

.warning {
  margin: 0px 3px;
  font-size: var(--9px);
  height: 30px;
  background-color: var(--spark-delete);
  color: black;
  height: 30px;
  margin-bottom: 5px;
  border-radius: 2px;
}

.info {
  margin: 0px 3px;
  font-size: var(--9px);
  height: 30px;
  margin-bottom: 5px;
  padding: 0px 5px;
}
</style>
