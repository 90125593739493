<template>
  <div class="w-full max-w-[600px] text-center">
    <input :value="initialValue" type="range" min="0" max="4" step="1" class="slider" @input="passValueToParent" />
    <div class="flex justify-between text-[11px] text-gray-400">
      <span>{{ parseWeightToString(0) }}</span>
      <span class="ml-[-20px]">{{ parseWeightToString(0.5) }}</span>
      <span>{{ parseWeightToString(1) }}</span>
      <span>{{ parseWeightToString(10) }}</span>
      <span>{{ parseWeightToString(1000) }}</span>
    </div>
  </div>
</template>

<script>
import { getWeightString } from '@/components/PartWorkflow/Feasibility/FeasibilityParsing';

export default {
  name: 'WeightSlider',

  props: {
    initialValue: { type: Number, default: 0 },
  },

  emits: ['slider-change'],

  methods: {
    passValueToParent(event) {
      this.$emit('slider-change', event.target.value);
    },

    parseWeightToString(weight) {
      return getWeightString(weight);
    },
  },
};
</script>

<style scoped>
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #e0e0e0;
  outline: none;
  border-radius: 5px;
  margin-bottom: 8px;
  position: relative;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background-color: #d3e649;
  border: 4px solid white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.slider::-moz-range-thumb {
  width: 22px;
  height: 22px;
  background-color: #d3e649;
  border: 4px solid white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.slider-marks {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  padding-bottom: 0px;
  top: -12px;
}

.slider-marks span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}
</style>
