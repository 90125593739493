<template>
  <div class="text-center flex pb-4 pl-10">
    <table class="spark-table ml-10">
      <tbody>
        <template v-for="(option, key) in formData" :key="key">
          <tr>
            <td><input v-model="option.value" class="w-1/2" type="checkbox" name="checkbox" /></td>
            <td class="text-left">{{ option.label }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'PopupCheckBox',

  props: {
    formData: Object,
    type: String,
  },

  watch: {
    formData: {
      handler(newVal) {
        this.triggerPopup({ formData: newVal });
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),
  },
};
</script>
