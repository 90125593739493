<template>
  <div class="bg-white rounded-8 border border-gray-50 p-20">
    <div>
      <OrganizationFilter @selected="onOrganizationSelected($event)" />
    </div>
    <div class="flex">
      <TotalNewUsers
        class="w-1/2 pr-48"
        :organizations="selectedOrganizations"
        @update-total-new-users-series="handleTotalNewUsersSeries"
      />
      <TotalUsers
        class="w-1/2 pl-48"
        :organizations="selectedOrganizations"
        @update-total-users-series="handleTotalUsersSeries"
        @update-months="handleMonthsUpdate"
      />
    </div>
    <div class="flex">
      <ActiveUsers
        :organizations="selectedOrganizations"
        class="w-1/2 pr-48"
        @update-active-users-series="handleActiveUsersSeries"
      />
      <TotalAndActiveUsers
        class="w-1/2 pl-48"
        :organizations="selectedOrganizations"
        :total-active-users="totalActiveUsers"
        :total-new-users="totalNewUsers"
        :total-users="totalUsers"
        :months="monthsArray"
      />
    </div>
  </div>
</template>

<script>
import ActiveUsers from './components/ActiveUsers.vue';
import TotalAndActiveUsers from './components/TotalAndActiveUsers.vue';
import TotalNewUsers from './components/TotalNewUsers.vue';
import TotalUsers from './components/TotalUsers.vue';

import OrganizationFilter from '@/components/Reusable/Filter/OrganizationFilter.vue';

export default {
  name: 'Dashboard',

  components: {
    TotalAndActiveUsers,
    OrganizationFilter,
    ActiveUsers,
    TotalUsers,
    TotalNewUsers,
  },

  data() {
    return {
      selectedOrganizations: [],
      totalActiveUsers: {},
      totalNewUsers: {},
      totalUsers: {},
      monthsArray: [],
    };
  },

  methods: {
    onOrganizationSelected(event) {
      if (event.organizations != undefined) {
        this.selectedOrganizations = event.organizations;
      } else {
        this.selectedOrganizations = event;
      }
    },

    handleTotalUsersSeries(totalUsersSeries) {
      this.totalUsers = totalUsersSeries;
    },

    handleActiveUsersSeries(totalActiveUsersSeries) {
      this.totalActiveUsers = totalActiveUsersSeries;
    },

    handleTotalNewUsersSeries(totalNewUsersSeries) {
      this.totalNewUsers = totalNewUsersSeries;
    },

    handleMonthsUpdate(months) {
      this.monthsArray = months.map(month => {
        const [year, monthNum] = month.split('-');
        return `${monthNum.length === 1 ? '0' + monthNum : monthNum}/${year}`;
      });
    },
  },
};
</script>
