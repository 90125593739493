<template>
  <SparkInput
    v-model="filterValue"
    name="filter"
    label=""
    placeholder="Search in all columns"
    @blur="searchMaterials"
    @keyup.enter="searchMaterials"
  />
</template>

<script>
import SparkInput from '../../../components/SparkComponents/SparkInput.vue';

export default {
  name: 'LibraryFilter',

  components: {
    SparkInput,
  },

  emits: ['filter'],

  data() {
    return {
      filterValue: '',
    };
  },

  watch: {
    filterValue(newVal) {
      if (!newVal) this.$emit('filter', newVal);
    },
  },

  methods: {
    searchMaterials() {
      this.$emit('filter', this.filterValue);
    },
  },
};
</script>
