<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold" v-text="header" />
    </template>

    <template #default>
      <div class="flex items-center justify-between">
        <div class="font-bold text-15" v-text="'Name*'" />
        <SparkInput v-model="name" custom="!w-[300px] !mr-150 my-8" name="name" />
      </div>

      <div class="flex items-center justify-between mb-48">
        <div class="font-bold text-15" v-text="'Restriction*'" />
        <div class="flex flex-col gap-4">
          <SparkSelect
            v-model="currentOption"
            custom="!w-[300px] !mr-150 my-8"
            :options="profile[1].options"
            name="role"
          />
          <span v-text="warningText" />
        </div>
      </div>
      <div class="flex justify-end gap-16">
        <SparkButton variant="outlined" @click="close">
          <div v-text="'Cancel'" />
        </SparkButton>
        <SparkButton custom="!min-w-[80px]" variant="secondary" @click="save">
          <div v-text="mode === 'edit' ? 'Change' : 'Save'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'AddEditProfileModal',

  components: { SparkModal, SparkSelect, SparkButton, SparkInput },

  props: {
    show: { type: Boolean },
    profile: { type: Object, default: () => ({}) },
    mode: { type: String, default: 'add' },
    currentUid: { type: String, default: '' },
    externalProfile: { type: [Object, String], default: () => ({}) },
  },

  emits: ['close', 'save'],

  data() {
    return {
      currentOption: '',
      name: '',
      warningText: '',
    };
  },

  computed: {
    header() {
      return this.mode === 'add' ? 'Add Profile' : 'Edit Profile';
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.currentOption = this.profile[1].value;
        this.name = this.profile[0].value;
      }
    },

    currentOption: {
      handler() {
        this.validityCheckExternalProfileText(this.currentUid);
      },

      deep: true,
    },
  },

  methods: {
    close() {
      this.warningText = '';
      this.$emit('close');
    },

    save() {
      this.$emit('save', {
        0: { ...this.profile[0], value: this.name },
        1: { ...this.profile[1], value: this.currentOption },
      });
      this.warningText = '';
      this.close();
    },

    validityCheckExternalProfileText(profileUidToEdit) {
      this.warningText = '';
      if (
        Object.keys(this.externalProfile).length !== 0 &&
        profileUidToEdit !== this.externalProfile.uid &&
        this.currentOption === 'external'
      ) {
        this.warningText = "⚠️ Current PRP profile '" + this.externalProfile.name + "' will be unset.";
      }
    },
  },
};
</script>
