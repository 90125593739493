<template>
  <div v-if="user.email" class="settings">
    <div class="settings__navigation">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const user = computed(() => store.state.user);
</script>

<style lang="scss" scoped>
.settings {
  height: 100%;
  position: relative;
}
.settings__navigation {
  margin: 16px;
  height: 100%;
}
</style>
