<template>
  <dialog ref="modal" class="p-0 outline-0 backdrop:bg-black backdrop:opacity-50">
    <div ref="modalContent">
      <slot />
    </div>
  </dialog>
</template>

<script setup>
import { onClickOutside, useEventListener } from '@vueuse/core';
import { ref, watch } from 'vue';

const props = defineProps({
  modelValue: Boolean,
  dismissable: Boolean,
});

const emits = defineEmits(['update:modelValue']);

const modal = ref();
const modalContent = ref();

const closeModal = () => emits('update:modelValue', false);
const cancelModal = e => {
  if (!props.dismissable) e.preventDefault();
};

useEventListener(modal, 'cancel', cancelModal);
useEventListener(modal, 'close', () => props.modelValue && closeModal());

let stopOnClickOutside = null;

watch(
  () => props.modelValue,
  value => {
    if (value) {
      modal.value?.showModal();

      stopOnClickOutside = onClickOutside(modalContent, () => {
        closeModal();
      });
    } else {
      modal.value?.close();

      if (stopOnClickOutside) stopOnClickOutside();
    }
  }
);
</script>
