<template>
  <div class="text-center pb-4">
    <SparkSelect
      v-model="activeSelection"
      custom="!w-200 !mx-auto my-16"
      :options="popupSelectOptions"
      :label="popupSelectLabel"
      name="selection"
      @change="updateSelection()"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import SparkSelect from '../SparkComponents/SparkSelect.vue';

export default {
  name: 'PopupSelectOption',

  components: { SparkSelect },

  props: {
    popupSelectLabel: { type: String, default: 'Selection' },
    popupSelectOptions: {
      type: Array,
      default: () => [
        { label: 'Option 0', value: 'option0' },
        { label: 'Option 1', value: 'option1' },
      ],
    },
  },

  data() {
    return {
      activeSelection: '',
    };
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    updateSelection() {
      this.triggerPopup({ formData: this.activeSelection });
    },
  },
};
</script>
