<template>
  <div v-if="!isExternal" class="ml-12">
    <InvestigationTable />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import InvestigationTable from './components/InvestigationTable.vue';

export default {
  name: 'ComparisonFrame',

  components: {
    InvestigationTable,
  },

  computed: {
    ...mapState(['user']),
    isExternal() {
      return this.user.is_external;
    },
  },
};
</script>
