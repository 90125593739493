<template>
  <div>
    <div class="mb-20" v-text="'Toggle Features Visibility'" />
    <div class="flex gap-20 items-center">
      <SparkCheckbox v-for="(property, key) in formData" :key="key" v-model="property.value" :name="key">
        <div class="text-13">{{ property.label }}</div>
      </SparkCheckbox>
      <SparkButton small variant="secondary" @click="saveFeatureVisibility">
        <div v-text="'Save'" />
      </SparkButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
export default {
  name: 'FeatureVisibility',

  components: { SparkCheckbox, SparkButton },

  data() {
    return {
      formData: {
        prp_show_price: { label: 'Price', value: false },
        prp_show_feasibility: { label: 'Feasibility', value: false },
      },
    };
  },

  computed: {
    ...mapState(['user', 'organization']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    organization: {
      handler() {
        this.formData.prp_show_price.value = this.organization?.prp_show_price;
        this.formData.prp_show_feasibility.value = this.organization?.prp_show_feasibility;
      },

      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.fetchOrganization();
  },

  methods: {
    ...mapActions(['fetchOrganization']),
    async saveFeatureVisibility() {
      try {
        const data = {
          prp_show_price: this.formData.prp_show_price.value,
          prp_show_feasibility: this.formData.prp_show_feasibility.value,
        };
        await this.axiosInstance.put('api/v1/organization-settings/', data);
        await this.fetchOrganization();
        this.$root.notify('success', 'Feature visibility saved successfully', '', 6000);
      } catch (error) {
        console.error(error, 'error');
        this.$root.notify('error', 'Feature visibility save error', error, 6000);
      }
    },
  },
};
</script>
