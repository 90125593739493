<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div v-if="message_title" class="bg-gray-200 rounded-2xl p-12 shadow-lg text-center max-w-md mx-4">
      <span class="font-bold">{{ message_title }}</span> <br/> {{ message }}
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState } from 'vuex';

export default {
  name: 'DownloadLink',

  data() {
    return {
      message_title: null,
      message: null,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    downloadLinkUid() {
      return this.$route.params.download_link_uid;
    },
  },

  mounted() {
    this.$root.notify('success', '', 'Download started', 3000);
    this.axiosInstance
      .get(`/api/v1/download-link/${this.downloadLinkUid}/`, {
        responseType: 'blob',
      })
      .then(response => {
        this.message_title = "✅ Thank you for using 3D Spark";
        this.message = "Your download will start soon.";
        const fileName = `data_for_RFQ_${this.downloadLinkUid}.zip`;
        saveAs(response.data, fileName);
      })
      .catch(error => {
        this.$root.notifyError('Downloading ZIP for RFQ', error);
        this.message_title = "❌ Permission Denied";
        this.message = "You do not have the required access rights to download this file. Contact support@3dspark.de if you require any assistance.";
      });
  },
};
</script>
