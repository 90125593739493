<template>
  <div class="main-frame">
    <h5>Debugging</h5>
    <hr />

    <div>
     <button type="button" @click="sendDensityMaterialMapping">Send Density Material Mapping</button>
    </div>

    <div>
      <h2>Test Request Price API</h2>

      <div class="mt-10">
        <label for="partDropdown">Select a Part:</label>
        <select id="partDropdown" v-model="selectedPartId" class="h-40 focus:h-40 text-13 rounded-4">
          <option v-for="part in partList" :key="part.part_id" :value="part.part_id">{{ part.name }}</option>
        </select>
      </div>

      <div>
        <select v-if="materialOptions.length" v-model="selectedMaterial" class="h-40 focus:h-40 text-13 rounded-4">
          <option v-for="option in materialOptions" :key="option.mat_id" :value="option.mat_id">
            {{ option.name }}
          </option>
        </select>
        <p v-else>Loading materials...</p>

        <select v-if="prcAcronymOptions.length" v-model="selectedPrcAcronym" class="h-40 focus:h-40 text-13 rounded-4">
          <option v-for="option in prcAcronymOptions" :key="option.uid" :value="option.prc_acronym">
            {{ option.prc_name + ' (' + option.prc_synonym_acronym + ', ' + option.prc_synonym_name + ')' }}
          </option>
        </select>
        <p v-else>Loading PRC acronyms...</p>
      </div>
      <button type="button" @click="sendData">Send Data</button>
    </div>

    <div class="version">
      <div>Version: {{ version }}</div>
    </div>
    <hr />
    <div>
      <h2>Settings</h2>
      <div>
        <label for="supplier">Is Supplier:</label>
        <input id="supplier" v-model="isSupplierValue" type="checkbox" />
      </div>

      <div>
        <label for="salesRep">Sales Representative:</label>
        <select id="salesRep" v-model="selectedSalesRepValue" class="single-select-box">
          <option v-for="user in users" :key="user.user_id" :value="user">
            {{ user.first_name }} {{ user.last_name }}
          </option>
        </select>
      </div>

      <button type="button" @click="updateOrganization">Update</button>
    </div>

    <div>
      <h2>Suppliers</h2>
      <div>
        <label for="publicSuppliers">Public Suppliers</label>
        <select id="publicSuppliers" v-model="selectedSuppliersValue" class="multi-select-box" multiple>
          <option v-for="supplier in publicSuppliers" :key="supplier.name" :value="supplier">
            {{ supplier.name }}
          </option>
        </select>
        <button type="button" @click="updateSuppliers">Set Supplier List</button>
      </div>
    </div>

    <div>
      <label for="supplier_name">Name:</label>
      <input id="supplier_name" v-model="supplier_name" type="text" />
      <label for="supplier_email">Email:</label>
      <input id="supplier_email" v-model="supplier_email" type="text" />
      <label for="file_input">Logo</label>
      <input id="file_input" type="file" @change="handleFileUpload" />
      <button type="button" @click="createSupplier">Create Email Supplier</button>
    </div>
  </div>

  <div class="flex">
    <button type="button" @click="createCalibrationConfig">Create Calibration Config</button>
    <button type="button" @click="createCalibrationInstance">Create Calibration Instance</button>
  </div>
  <div class="flex">
    <button type="button" @click="editCalibrationConfig">Edit Calibration Config</button>
    <button type="button" @click="editCalibrationInstance">Edit Calibration Instance</button>
  </div>
  <div class="flex">
    <button type="button" @click="getCalibrationConfigList">Get Calibration Config List</button>
    <button type="button" @click="getCalibrationInstanceList">Get Calibration List</button>
  </div>
  <div class="flex">
    <button type="button" @click="getCalibrationConfiguration">Get Calibration Config</button>
    <button type="button" @click="calibrateConfiguration">Calibrate Config</button>
  </div>
  <div class="flex">
    <button type="button" @click="createCalibrationResult">Create Calibration Result</button>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import packageJson from '../../../../package.json';

export default {
  name: 'Debugging',

  data() {
    return {
      supplier_name: '',
      supplier_email: '',
      logoFile: null,
      materialOptions: [],
      selectedMaterial: null,
      prcAcronymOptions: [],
      selectedPrcAcronym: null,
      partList: null,
      selectedPartId: null,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'axiosInstanceFileUpload']),
    ...mapState('supplier', ['users', 'selectedSuppliers', 'publicSuppliers', 'selectedSalesRep', 'isSupplier']),

    version() {
      return packageJson.version;
    },

    isSupplierValue: {
      get() {
        return this.isSupplier;
      },

      set(value) {
        this.setIsSupplier(value);
      },
    },

    selectedSuppliersValue: {
      get() {
        return this.selectedSuppliers;
      },

      set(value) {
        this.setSelectedSuppliers(value);
      },
    },

    selectedSalesRepValue: {
      get() {
        return this.selectedSalesRep;
      },

      set(value) {
        this.setSelectedSalesRep(value);
      },
    },
  },

  created() {
    this.fetchMaterials();
    this.fetchPrcAcronyms();
    this.getPartList();
  },

  async mounted() {
    await this.fetchUsers();
    await this.fetchSelectedSuppliers(); // Get all supplier
    await this.fetchPublicSuppliers(); // Get the selected suppliers from backend
  },

  methods: {
    ...mapActions('supplier', [
      'createEmailSupplier',
      'updateSuppliers',
      'fetchSelectedSuppliers',
      'fetchPublicSuppliers',
      'fetchUsers',
    ]),

    ...mapMutations('supplier', ['setSelectedSalesRep', 'setIsSupplier', 'setSelectedSuppliers', 'setPublicSuppliers']),

    async updateOrganization() {
      let formData = new FormData();
      formData.append('is_supplier', this.isSupplier);
      formData.append('sales_rep_id', this.selectedSalesRep?.user_id);
      if (this.name) {
        formData.append('name', this.name);
      }
      await this.axiosInstance.put('/api/v1/organization-settings/', formData).then(response => {
        console.log(response.data);
      });
    },

    async createSupplier() {
      let formData = new FormData();

      if (this.supplier_name) {
        formData.append('name', this.supplier_name);
      }

      if (this.supplier_email) {
        formData.append('email', this.supplier_email);
      }

      if (this.logoFile) {
        formData.append('file', this.logoFile);
      }
      this.createEmailSupplier(formData);
    },

    handleFileUpload(event) {
      this.logoFile = event.target.files[0];
    },

    async fetchMaterials() {
      await this.axiosInstance
        .get(`api/v1/default-materials/`)
        .then(response => {
          this.materialOptions = response.data.map(entry => ({
            mat_id: entry.mat_id,
            name: entry.mat_name,
          }));
          this.materialOptions.push({ name: 'No Material', mat_id: null });
        })
        .catch(error => {
          console.log(error);
        });
    },

    async fetchPrcAcronyms() {
      await this.axiosInstance
        .get(`api/v1/prc-acronyms/`)
        .then(response => {
          this.prcAcronymOptions = response.data.map(entry => ({
            prc_uid: entry.uid,
            prc_name: entry.prc_name,
            prc_acronym: entry.prc_acronym,
            prc_synonym_acronym: entry.prc_synonym.prc_acronym,
            prc_synonym_name: entry.prc_synonym.prc_name,
          }));
          this.prcAcronymOptions.push({
            prc_name: 'No Process',
            prc_uid: null,
            prc_acronym: null,
            prc_synonym_acronym: null,
            prc_synonym_name: null,
          });
        })
        .catch(error => {
          console.log(error);
        });
    },

    async createCalibrationConfig() {
      const data = {
        calibration_config_name: 'Test Calibration Config',
        calibration_config_type: 'price',
        parameters: [
          {
            parameter_name: 'Test Parameter',
            parameter_type: 'test',
            parameter_value: 'test',
          },
        ],
      };

      await this.axiosInstance
        .post(`api/v1/calibration-config-list/`, data)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async createCalibrationInstance() {
      const data = {
        part_ids: [
          'd3c7f57eaac8484191dd002c2cf71b7d',
          'e68481c12a014a9ab74f714ae35e5052',
          'e68481c12a014a9ab74f714ae35e5052',
        ],

        values: ['15.0', '5.0', '6.0'],
        lot_sizes: ['500', '50', '60'],
      };

      this.calibrationConfigUid = '640172268f304ba884f7934513bd67b3	';

      await this.axiosInstance
        .post(`api/v1/calibration-list/${this.calibrationConfigUid}`, data)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async editCalibrationConfig() {
      const data = {
        // 'calibration_config_name': 'Test Calibration Config',
        process_chain_template_uid: 'f8e63de06f57484c8e5668a71aae5053',
        // 'type': 'machine',
        // 'draft': 'true',
        // 'parameters': {
        //     'layer_height': {
        //         'min': 0.1,
        //         'max': 0.3,
        //     },

        //     'wall_thickness': {
        //         'min': 1.0,
        //         'max': 2.0,
        //     },

        //     'infill_phi': {
        //         'min': 0.5,
        //         'max': 0.6,
        //     },

        //     'support_density_ratio': {
        //         'min': 0.6,
        //         'max': 0.7,
        //     },

        //     'volumetric_build_rate_infill': {
        //         'min': 50.0,
        //         'max': 60.0,
        //     },

        //     'volumetric_build_rate_contour': {
        //         'min': 20.0,
        //         'max': 30.0,
        //     },

        //     'time_per_layer': {
        //         'min': 5.0,
        //         'max': 10.0,
        //     },

        //     'fixed_machine_time': {
        //         'min': 0.5,
        //         'max': 5.0,
        //     },
        // }
      };

      this.calibrationConfigUid = 'd97f673c4ecc41a3b572b9ebabad10d1	';

      await this.axiosInstance
        .put(`api/v1/calibration-config-list/${this.calibrationConfigUid}`, data)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async editCalibrationInstance() {
      const data = {
        value: '4.0',
        lot_size: '20',
      };

      this.calibrationInstanceUid = '0c53acf40e5c4fb69a7a5058f92919ad';

      await this.axiosInstance
        .put(`api/v1/calibration-list/${this.calibrationInstanceUid}`, data)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async getCalibrationInstanceList() {
      this.calibrationConfigUid = 'ee6664d0f955408aab49e70a2f0575b0';
      await this.axiosInstance
        .get(`api/v1/calibration-list/${this.calibrationConfigUid}`)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async getCalibrationConfigList() {
      await this.axiosInstance
        .get(`api/v1/calibration-config-list/`)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async getCalibrationConfiguration() {
      this.calibrationConfigUid = 'ee6664d0f955408aab49e70a2f0575b0';

      await this.axiosInstance
        .get(`api/v1/calibration/${this.calibrationConfigUid}`)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async calibrateConfiguration() {
      this.calibrationConfigUid = '640172268f304ba884f7934513bd67b3';

      await this.axiosInstance
        .post(`api/v1/calibration/${this.calibrationConfigUid}`)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async createCalibrationResult() {
      this.calibrationConfigUid = '640172268f304ba884f7934513bd67b3';

      await this.axiosInstance
        .post(`api/v1/calibration-result/${this.calibrationConfigUid}`)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async getPartList() {
      await this.axiosInstance.get('api/v1/part-list/').then(response => {
        this.partList = response.data;
      });
    },

    async sendData() {
      const data = {
        mat_id: this.selectedMaterial,
        prc_acronym: this.selectedPrcAcronym,
      };

      await this.axiosInstance
        .post(`api/v1/request-price/${this.selectedPartId}/`, data)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async sendDensityMaterialMapping() {
      this.partId = '39a7243f927947758bbfc816d5231886';
      const data = {
        volume: 20.2,
        mass: 157.8,
      };
      await this.axiosInstance
        .post(`api/v1/density-mapping/${this.partId}/`, data)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  text-align: left;
  margin-top: 20px;
}
button {
  width: 300px;
}

.main-frame {
  margin: 10px;
  width: 95%;
}

.version {
  margin-bottom: 10px;
}

.multi-select-box {
  height: 15em;
}

.single-select-box {
  height: 3em;
}
</style>
