<template>
  <SparkButton variant="secondary" @click="openAddUserPopup()"><i class="fas fa-plus mr-8" />Invite User</SparkButton>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '../../../components/SparkComponents/SparkButton.vue';

export default {
  name: 'InviteUser',

  components: {
    SparkButton,
  },

  props: {
    group: { type: String, default: null },
  },

  computed: {
    ...mapState('application', ['websocketDisconnect', 'axiosInstance']),

    ...mapState(['popup', 'roles']),

    formattedRoles() {
      let formattedArray = [];
      Object.keys(this.roles).forEach(key => {
        formattedArray.push({ value: key, text: this.roles[key] });
      });
      return formattedArray;
    },

    defaultFormData() {
      return {
        0: {
          label: 'E-Mail',
          field: 'input-text',
          options: '',
          required: true,
          value: '',
        },

        1: {
          label: 'Role',
          field: 'select',
          options: this.formattedRoles,
          required: true,
          value: '',
        },
      };
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.$store.commit('triggerPopup', '');
          this.addUser(popup.formData);
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.$store.commit('triggerPopup', '');
        }
      }
    },
  },

  methods: {
    openAddUserPopup() {
      this.$store.commit('triggerPopup', {
        key: this.$options.name,
        show: true,
        title: 'Add User',
        type: 'generalform',
        buttons: true,
        formData: this.defaultFormData,
        formType: 'edit',
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Add', type: 'secondary', value: 'ok' },
        ],
      });
    },

    addUser(data) {
      let formData = {
        email: data[0]?.value,
        role: data[1]?.value,
      };
      if (this.group != null) {
        formData['group'] = this.group;
      }
      this.axiosInstance.post('api/v1/user-management/create-user/', formData).then(response => {
        this.$root.notify('success', response.data.message, 5000);
      })
      .catch(response => {
        this.$root.notify('error', 'Error', response.response.data.error, 6000);
      });
    },
  },
};
</script>
