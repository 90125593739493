import { Vector2, Raycaster, Vector3 } from 'three';

class IntersectionPicker {
  constructor(camera, mesh) {
    this.camera = camera;
    this.mesh = mesh;
  }

  _getIntersection(event, viewScaleFactor) {
    const pointer = this._getNormalizedMousePos(event);
    const intersects = this._castRay(pointer);
    return this._readOutIntersectFaceAndPos(intersects, viewScaleFactor);
  }

  _getNormalizedMousePos(event) {
    const pointer = new Vector2();
    pointer.x = (event.offsetX / event.target.offsetWidth) * 2 - 1;
    pointer.y = -(event.offsetY / event.target.offsetHeight) * 2 + 1;
    return pointer;
  }

  _castRay(pointer) {
    const raycaster = new Raycaster();

    raycaster.setFromCamera(pointer, this.camera);
    const direction = raycaster.ray.direction;
    let newOrigin = raycaster.ray.origin.clone();
    newOrigin.addScaledVector(direction, -1000); // 1000 seems to be a sweetspot independent of part size
    raycaster.set(newOrigin, direction);

    const intersects = raycaster.intersectObject(this.mesh);

    return intersects;
  }

  _readOutIntersectFaceAndPos(intersects, viewScaleFactor) {
    var face = null;
    var faceNormal = null;
    var point = null;

    if (intersects.length > 0) {
      const intersect = intersects[0]; // The first intersection object is the closest one

      face = new Vector3(intersect.face.a, intersect.face.b, intersect.face.c);
      faceNormal = intersect.face.normal.clone();
      point = intersect.point.clone();
      point.divideScalar(viewScaleFactor);
    }
    return [face, faceNormal, point];
  }
}

export class FacePickerOnHull extends IntersectionPicker {
  constructor(camera, mesh, scene) {
    super(camera, mesh);
    this.scene = scene;
  }

  pickFace(event, viewScaleFactor) {
    const [intersectFace, intersectFaceNormal] = this._getIntersection(event, viewScaleFactor);
    return [intersectFace, intersectFaceNormal];
  }
}
