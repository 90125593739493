l
<template>
  <div class="box">
    <div class="content">
      <div class="content-icon">
        <i class="fas fa-cubes fa-2x" />
      </div>
      <div class="content-text">
        <div class="content-description">Validated References</div>
        <div class="content-value">
          {{ validatedUploadedReferences }}
        </div>
        <div>{{ numberOfTotalParts }} Parts</div>
      </div>
    </div>
    <div class="content">
      <div class="content-icon">
        <div class="result-icon result-icon-cost" />
      </div>
      <div class="content-text">
        <div class="content-description">Validated Cost Savings</div>
        <div class="content-value">{{ costSaved }}&nbsp;€</div>
      </div>
    </div>
    <div class="content">
      <div class="content-icon">
        <div class="result-icon result-icon-leadtime" />
      </div>
      <div class="content-text">
        <div class="content-description">Validated Lead Time Savings</div>
        <div class="content-value">{{ formattedLeadTimeSavings[0] }}</div>
        <div>{{ formattedLeadTimeSavings[1] }}</div>
      </div>
    </div>
    <div class="content">
      <div class="content-icon">
        <div class="result-icon result-icon-carbon" />
      </div>
      <div class="content-text">
        <div class="content-description">CO2 Savings</div>
        <div class="content-value">{{ co2Saved }}&nbsp;kg CO₂-eq</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'DashboardSummary',

  data() {
    return {
      leadTimeSaved: null,
      costSaved: null,
      validatedUploadedReferences: null,
      numberOfTotalParts: null,
      co2Saved: null,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    formattedLeadTimeSavings() {
      if (this.leadTimeSaved >= 14) {
        return [
          this.$formatZeroDecimalPlaces(this.leadTimeSaved / 7) + ' weeks',
          this.$formatZeroDecimalPlaces(this.leadTimeSaved) + ' days',
        ];
      } else {
        return [this.$formatZeroDecimalPlaces(this.leadTimeSaved) + ' days', ''];
      }
    },
  },

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      let params = {
        query_type: 'organization',
        part_stat: 'validated',
      };
      this.axiosInstance
        .post('/api/v1/dashboard/summary/', params)
        .then(response => {
          this.leadTimeSaved = response.data.leadtime_saved;
          this.costSaved = this.formattedCostSaved(response.data.cost_saved);
          this.validatedUploadedReferences = response.data.validated_uploaded_references;
          this.numberOfTotalParts = response.data.number_of_total_parts;
          this.co2Saved = this.formattedCo2Saved(response.data.co2_saved);
        })
        .catch(error => {
          // 404 - specified organization not found
          console.log(JSON.stringify(error));
        });
    },

    formattedCostSaved(value) {
      if (value >= 100000) {
        return String(this.$formatZeroDecimalPlaces(value / 1000)).concat(' k');
      } else {
        return this.$formatZeroDecimalPlaces(value);
      }
    },

    formattedCo2Saved(value) {
      if (value >= 1000) {
        return String(this.$formatZeroDecimalPlaces(value / 1000)).concat(' k');
      } else {
        return this.$formatZeroDecimalPlaces(value);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: var(--border-radius);
  background-color: white;
}
.content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  color: var(--primary-color);
  justify-content: center;
}

@media only screen and (max-width: 981px) {
  .box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.content-icon {
  display: flex;
  flex-direction: row;
  color: #035e73;
  background-color: rgb(3, 94, 115, 0.5);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
}
.content-text {
  display: flex;
  margin: 5px;
}
.content div {
  flex-direction: column;
}
.content-description {
  color: gray;
  font-size: 0.8em;
  white-space: nowrap;
}
.content-value {
  font-size: 1.2em;
}

.result-icon-cost {
  background: url('@/assets/icons/comparison/cost.svg');
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
}
.result-icon-leadtime {
  background: url('@/assets/icons/comparison/leadtime.svg');
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
}
.result-icon-carbon {
  background: url('@/assets/icons/comparison/carbon.svg');
  float: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
}

.result-icon {
  margin: 0 !important;
}
</style>
